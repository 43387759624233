import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MeshNode } from '../models/mesh-models';
import { ToprenaultService } from '../services/toprenault.service';

@Injectable({
    providedIn: 'root'
})
export class CarFavorisResolve implements Resolve<any> {

    constructor(private toprenaultService: ToprenaultService) { }
    resolve(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> {
        let userObj = {
            user_id: '',
            car_id: sessionStorage.getItem('id') !== null ? sessionStorage.getItem('id') : null
        }
        if (sessionStorage.getItem('user_id') !== null) {
            userObj.user_id = sessionStorage.getItem('user_id');
        } else if (localStorage.getItem('user_id') !== null) {
            userObj.user_id = localStorage.getItem('user_id');
        }

        return this.toprenaultService.checkIsFavorite(userObj).toPromise()
            .catch(errorResponse => this.toprenaultService.handleError(next, errorResponse));
    }
}