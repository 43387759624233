import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ToprenaultService } from '../services/toprenault.service';
import { CockpitCmsService } from '../services/cockpitCms.service';
import { CarList } from '../models/car-list';


@Injectable({
    providedIn: 'root'
})
export class HomeResolve implements Resolve<any> {


    constructor(private toprenaultService: ToprenaultService, private cockpitCmsService: CockpitCmsService, private router: Router) { }

    resolve(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<any> | Promise<any> {

        return this.getdata().catch(errorResponse => this.toprenaultService.handleError(next, errorResponse));
    }

    async getdata() {
        let dataHome = [];
        let dataTOR = await this.toprenaultService.getListHome().toPromise() as CarList[];
        let dataEdito = await this.cockpitCmsService.gethome().toPromise() as any;
        let dataVignette = await this.cockpitCmsService.getVignetteHomePage().toPromise() as any;
        let dataEditoOffres = await this.cockpitCmsService.getOffresTabs().toPromise() as any;
        dataHome['dataTOR'] = dataTOR;
        dataHome['dataEditos'] = dataEdito;
        dataHome['vignette'] = dataVignette;
        dataHome['dataOffres'] = dataEditoOffres;

        return dataHome;
    }
}