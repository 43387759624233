import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Location } from "@angular/common";
import { NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { ToprenaultService } from './services/toprenault.service';
import { Subscription } from 'rxjs';

export let browserRefresh = false;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TopRenault';
  loading: boolean = false;
  ie: boolean = false;
  subscription: Subscription;

  constructor(private location: Location, private router: Router, private topRenaultservice: ToprenaultService) {
    console.log("app component :")
    this.subscription = router.events.subscribe((event) => {


      if (sessionStorage.getItem('previousUrl') != undefined &&
        sessionStorage.getItem('url') != undefined
        && sessionStorage.getItem('previousUrl') == sessionStorage.getItem('url')) {
        browserRefresh = true;
        console.log('browserRefresh :', JSON.stringify(browserRefresh))
      } else {
        browserRefresh = false;
      }

      if (event instanceof NavigationEnd) {
        var url = event.url;
        var url_string = url.split(';');
        sessionStorage.setItem('previousUrl', url_string[0]);
        console.log('test1')
      }

      if (event instanceof NavigationStart) {
        var url = location.path();
        var url_string = url.split(';');
        sessionStorage.setItem('url', url_string[0]);
        console.log('test2')
        // console.log("history :", location.back())
      }
    });
  }



  ngOnInit() {
    this.loading = true;
    this.getBrowser();
    if ('favoritesList' in localStorage) {
      let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
      this.topRenaultservice.setFavorisCount(favoritesInLocalStorage.length);
    }
    this.loading = false;
  }
  getBrowser() {
    if (window.navigator.userAgent.indexOf("MSIE") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1) {
      this.ie = true;
    }
  }
}
