import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { ToprenaultService } from '../services/toprenault.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {

  public today;
  public favoritesData;
  public carsList = [];
  public userObj;
  public isLoading: boolean = false;
  public carListAfterFiltering = [];
  public errosIdsList = []
  public loading: boolean;
  constructor(private toprenaultService: ToprenaultService, private _location: Location, private activeRoute: ActivatedRoute, private toastr: ToastrService, private router: Router, private topRenaultservice: ToprenaultService) {
    this.today = new Date().toISOString().split("T")[0];
    sessionStorage.setItem('onglet', "favorites");
  }

  ngOnInit() {
    registerLocaleData(fr);

    if ('favoritesList' in localStorage) {
      this.isLoading = true;
      let favoritesListInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
      this.toprenaultService.getAllFavorites({ favortitesList: favoritesListInLocalStorage }).subscribe(data => {
        console.log('favorites data', data);
        for (let car of data['carsList']) {
          if (!car.hasOwnProperty('isError')) {
            this.carListAfterFiltering.push(car);
          } else {
            this.errosIdsList.push(car.productId);
          }
        }

        if (this.errosIdsList.length > 0 && 'favoritesList' in localStorage) {
          for (let errorId of this.errosIdsList) {
            favoritesListInLocalStorage = favoritesListInLocalStorage.filter(id => id !== errorId);
          }
          localStorage.setItem('favoritesList', JSON.stringify(favoritesListInLocalStorage));

        }
        this.carsList = data['carsList'];
        this.isLoading = false;
      })
    }

    if (JSON.parse(sessionStorage.getItem('checked-filerts-storage')) !== undefined && JSON.parse(sessionStorage.getItem('checked-filerts-storage')) !== null && JSON.parse(sessionStorage.getItem('checked-filerts-storage')).length > 0) {
      this.topRenaultservice.setSelectedFilters(JSON.parse(sessionStorage.getItem('checked-filerts-storage')));

    }

  }

  removeFromFavorite(id) {
    let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
    favoritesInLocalStorage = favoritesInLocalStorage.filter(idInLocalStorage => id !== idInLocalStorage);
    localStorage.setItem('favoritesList', JSON.stringify(favoritesInLocalStorage));
    this.carsList = this.carsList.filter(car => car.productId !== id);
    this.topRenaultservice.setFavorisCount(favoritesInLocalStorage.length);
  }

  backClicked() {
    // this._location.back();
    this.router.navigate([sessionStorage.getItem('url')]);
  }
  selectCar(id) {
    sessionStorage.setItem("id", id);
    sessionStorage.getItem("id");
    sessionStorage.setItem('flagDetailsGtm', 'true')
    this.router.navigate(['/details']);
  }
}
