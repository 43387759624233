<div class="auto-container" style="max-width:1198px !important;">
    <div class="bg-showcase">
        <div class="container pl-30 pr-30">
            <div class="row justify-content-center">
                <div class="col-md-5 col-lg-5 col-xs-12 col-sm-12 bg-white h-100 d-inline-block ml-0 mr-0" id="main-wrapper">
                    <ul class="nav nav-tabs nav-fill" id="user-connect-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="sign-in-link" (click)="onChangeTab(1)" data-toggle="tab"
                                href="#sign-in" role="tab" aria-controls="sign-in" aria-selected="true">Se connecter</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="sign-up-link" data-toggle="tab" (click)="onChangeTab(2)"
                                href="#sign-up" role="tab" aria-controls="sign-up" aria-selected="false">Créer un
                                compte</a>
                        </li>

                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane show active" id="sign-in" role="tabpanel" aria-labelledby="sign-in">
                            <div class="container mt-5">
                                <form class="text-center" [formGroup]="logInForm">
                                      <p *ngIf="isEmailOrPasswordError || isNoAccountFound" class="text-danger error-msg text-left ml-3">{{errorMsg}}</p>
                                    <div class="form-group m-3">
                                        <input type="email" name="email" formControlName="email" required (keyup)="resetErrors()"
                                            [ngClass]="{ 'error':fields_login_form.email.touched && fields_login_form.email.invalid ,'success':fields_login_form.email.touched && fields_login_form.email.valid }"
                                            required autocomplete="nope" />
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label for="email">Email <sup>*</sup></label>
                                        <div class="text-left error-container">
                                            <div *ngIf="fields_signUp_form.email.errors">
                                                <p class="text-left mb-0">
                                                    <span
                                                        *ngIf="fields_login_form.email.touched && fields_login_form.email.errors?.required"
                                                        class="error-msg">Email
                                                        obligatoire</span>
                                                    <span
                                                        *ngIf="(fields_login_form.touched && fields_login_form.email.invalid) || (fields_login_form.email.touched && fields_login_form.email.errors?.pattern) "
                                                        class="error-msg">
                                                        Entrer un email valide
                                                    </span>
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group m-3">
                                     
                                        <i id="eye-show" *ngIf="!show_button" class="far fa-eye" (click)="showPassword()"></i>
                                        <i id="eye-hide" *ngIf="show_button"  class="far fa-eye-slash" (click)="showPassword()"></i>
                                        <input [type]="show_button ? 'text' : 'password'" name="password" formControlName="password" (keyup)="resetErrors()"
                                            [ngClass]="{ 'error':fields_login_form.password.touched && fields_login_form.password.invalid ,'success':fields_login_form.password.touched && fields_login_form.password.valid }"
                                            required autocomplete="nope" />
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label for="password">Mot de passe <sup>*</sup></label>
                                        <div class="text-left error-container" *ngIf="fields_login_form.password.errors">
                                            <small *ngIf="fields_login_form.password.touched && fields_login_form.password.invalid" class="error-msg">
                                                <span *ngIf="fields_login_form.password.errors?.required">Mot de passe
                                                    obligatoire</span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="m-3">

                                   
                                    <label class="chk-container mb-4"> <span
                                            class="text-left d-block stay-connected">Restez connecté </span>
                                        <input type="checkbox" (change)='onChange($event)'>
                                        <span class="checkmark"></span>
                                    </label> 
                                    </div>
                                    <div class="text-center captcha-container"  *ngIf="isCaptha1">
                                        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                                        </ngx-recaptcha2>
                                    </div>

                                    <div class="mt-5 mb-3">
                                        <button id="login-btn"  [disabled]="logInForm.invalid" (click)="logIn()">   <span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span>Se connecter</button>
                                    </div>
                                  
                                    <div class="pass-forgot-container">
                                        <a (click)="gotToForgotPass()" class="pass-forgot-link">Mot de passe oublié ?</a>
                                    </div>
                                </form>

                            </div>

                        </div>
                        <div class="tab-pane" id="sign-up" role="tabpanel" aria-labelledby="sign-up">

                            <form class="text-center sign-up-form" [formGroup]="signUpForm">
                                <div class="mb-2 text-left">
                                    <select id="civility" class="m-3" formControlName="civility"
                                        [(ngModel)]="defaultValue" (change)="onCivilitychange($event)"
                                        [ngClass]="{ 'error': (!isValidGender && fields_signUp_form.civility.touched) || (fields_signUp_form.civility.touched && defaultValue==='Sélectionner'),'success':fields_signUp_form.civility.dirty && isValidGender}">
                                        <option *ngFor="let gender of genders"
                                            [selected]="gender.label === 'Sélectionner'" [value]="gender.value">
                                            {{ gender.label }}
                                        </option>
                                    </select>
                                    <div class="error-container">
                                        <small
                                            *ngIf="fields_signUp_form.civility.touched && defaultValue==='Sélectionner'"
                                            class="error-msg text-center ml-3">
                                            Civilité obligatoire
                                        </small>
                                        <small
                                            *ngIf="!isValidGender && fields_signUp_form.civility.touched && defaultValue==='Sélectionner'"
                                            class="error-msg text-center">
                                            veuillez renseigner votre civilité
                                        </small>
                                    </div>

                                </div>
                                <div class="form-group m-3">
                                    <input type="text" name="lastName" formControlName="nom"
                                        [ngClass]="{ 'error': fields_signUp_form.nom.touched && fields_signUp_form.nom.invalid,'success':fields_signUp_form.nom.touched && fields_signUp_form.nom.valid }"
                                        required autocomplete="disabled" spellcheck="false" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="lastName">Nom <sup>*</sup></label>
                                    <div class="text-left error-container">
                                        <small *ngIf="fields_signUp_form.nom.touched && fields_signUp_form.nom.invalid"
                                            class="error-msg">
                                            <span *ngIf="fields_signUp_form.nom.errors.required">Nom obligatoire</span>
                                        </small>
                                        <small
                                            *ngIf="fields_signUp_form.nom.errors?.minlength && fields_signUp_form.nom.touched"
                                            class="error-msg">
                                            <span>Nom doit être de 2 caractères minimum</span>
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group m-3">
                                    <input type="text" name="prenom" id="prenom" formControlName="prenom" required
                                        autocomplete="disabled"
                                        [ngClass]="{ 'error': fields_signUp_form.prenom.touched && fields_signUp_form.prenom.invalid,'success':fields_signUp_form.prenom.touched && fields_signUp_form.prenom.valid }" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="prenom">Prénom <sup>*</sup></label>
                                    <div class="text-left error-container">
                                        <small
                                            *ngIf="fields_signUp_form.prenom.touched && fields_signUp_form.prenom.invalid"
                                            class="error-msg">
                                            <span *ngIf="fields_signUp_form.prenom.errors?.required">Prénom
                                                obligatoire</span>
                                        </small>
                                        <small
                                            *ngIf="fields_signUp_form.prenom.errors?.minlength && fields_signUp_form.prenom.touched"
                                            class="error-msg">
                                            <span>Prénom doit être de 2 caractères minimum</span>
                                        </small>

                                    </div>
                                </div>
                                <div class="form-group m-3">
                                    <input type="tel" formControlName="mobilePhone" maxlength="10" (keypress)="validateNumber($event)"
                                        [ngClass]="{ 'error': (fields_signUp_form.mobilePhone.touched && fields_signUp_form.mobilePhone.invalid) || (fields_signUp_form.mobilePhone.errors?.minlength && fields_signUp_form.mobilePhone.touched ) || (fields_signUp_form.mobilePhone.errors?.pattern && fields_signUp_form.mobilePhone.touched ) ,'success':fields_signUp_form.mobilePhone.touched && fields_signUp_form.mobilePhone.valid}"
                                        name="telephone" required autocomplete="nope">
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="prenom">Téléphone Mobile <sup>*</sup></label>
                                    <div class="text-left error-container">
                                        <small *ngIf="fields_signUp_form.mobilePhone.errors?.minlength && fields_signUp_form.mobilePhone.touched"
                                            class="error-msg text-left">
                                            <span>Numéro de téléphone doit être de 10 chiffres minimum</span>
                                        </small>
                                        <small *ngIf="fields_signUp_form.mobilePhone.errors?.maxlength && fields_signUp_form.mobilePhone.touched"
                                            class="error-msg text-left">
                                            <span>Numéro de téléphone doit de 10 chiffres maximum</span>
                                        </small>
                                        <small *ngIf="fields_signUp_form.mobilePhone.touched && fields_signUp_form.mobilePhone.invalid"
                                            class="error-msg text-left">
                                            <span *ngIf="fields_signUp_form.mobilePhone.errors.required">Numéro de téléphone
                                                mobile obligatoire</span>
                                        </small>
                                        <small
                                            *ngIf="fields_signUp_form.mobilePhone.touched && fields_signUp_form.mobilePhone.errors?.pattern && !fields_signUp_form.mobilePhone.errors?.minlength"
                                            class="error-msg text-left">
                                            <span>Numéro de téléphone mobile doit commencer par 06 ou 07</span>
                                        </small>
                                    </div>
                                </div>
                                      
                               
                                <div class="form-group m-3">
                                    <input type="email" name="email" formControlName="email" required
                                        [ngClass]="{ 'error':fields_signUp_form.email.touched && fields_signUp_form.email.invalid ,'success':fields_signUp_form.email.touched && fields_signUp_form.email.valid }"
                                        required autocomplete="nope" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="email">Email <sup>*</sup></label>
                                    <div class="text-left error-container">
                                        <div *ngIf="fields_signUp_form.email.errors">
                                            <p class="text-left mb-0">
                                                <span
                                                    *ngIf="fields_signUp_form.email.touched && fields_signUp_form.email.errors?.required"
                                                    class="error-msg">Email
                                                    obligatoire
                                                </span>
                                                    <span *ngIf="fields_signUp_form.email.touched && signUpForm.get('email').hasError('emailExists')" class="error-msg">
                                                        Un compte existe avec cet e-mail
                                                    </span>
                                                <span
                                                    *ngIf="(fields_signUp_form.email.touched && fields_signUp_form.email.invalid) && !signUpForm.get('email').hasError('emailExists') || (fields_signUp_form.email.touched && fields_signUp_form.email.errors?.pattern) "
                                                    class="error-msg">
                                                    Entrer un email valide
                                                </span>
                                            </p>

                                        </div>
                                    </div>
                                </div>   
                                <ng-template #popTemplate>
                                    <div class="text-left" *ngIf="fields_signUp_form.password.errors || disPlayErrors">
                                        <span>
                                           <strong class="text-header">Votre mot de passe doit comporter :</strong> 
                                        </span>
                                        <span
                                            *ngIf="(fields_signUp_form.password.touched && fields_signUp_form.password.invalid) || (fields_signUp_form.password.touched && fields_signUp_form.password.errors?.pattern) || disPlayErrors "
                                            class="error-msg">
                                           
                                            <br>
                                    
                                            <span
                                                [ngClass]="signUpForm.get('password').hasError('hasCapitalCase') || signUpForm.get('password').hasError('required') ? 'text-dark' : 'text-success'">
                                                <i class="far"
                                                    [ngClass]="{'fa-times-circle': signUpForm.get('password').hasError('hasCapitalCase') || signUpForm.get('password').hasError('required'),'fa-check-circle':!signUpForm.get('password').hasError('hasCapitalCase')}"></i> 1
                                                Majuscule
                                            </span>
                                            <br>
                                            <span
                                                [ngClass]="signUpForm.get('password').hasError('hasSmallCase') || signUpForm.get('password').hasError('required') ? 'text-dark' : 'text-success'">
                                                <i class="far"
                                                    [ngClass]="{'fa-times-circle': signUpForm.get('password').hasError('hasSmallCase') || signUpForm.get('password').hasError('required') ,'fa-check-circle':!signUpForm.get('password').hasError('hasSmallCase')}"></i>
                                                1 Miniscule
                                            </span>
                                            <br>
                                            <span
                                                [ngClass]="signUpForm.get('password').hasError('hasNumber') || signUpForm.get('password').hasError('required') ? 'text-dark' : 'text-success'">
                                                <i class="far"
                                                    [ngClass]="{'fa-times-circle': signUpForm.get('password').hasError('hasNumber') || signUpForm.get('password').hasError('required'),'fa-check-circle':!signUpForm.get('password').hasError('hasNumber')}"></i>
                                                1 Chiffre
                                            </span>
                                            <br>
                                            <span
                                                [ngClass]="signUpForm.get('password').hasError('hasSpecialCharacters') || signUpForm.get('password').hasError('required') ? 'text-dark' : 'text-success'">
                                                <i class="far"
                                                    [ngClass]="{'fa-times-circle': signUpForm.get('password').hasError('hasSpecialCharacters') || signUpForm.get('password').hasError('required'),'fa-check-circle':!signUpForm.get('password').hasError('hasSpecialCharacters')}"></i>
                                                1 Caractère spécial
                                            </span>
                                            <br>
                                            <span
                                                [ngClass]="signUpForm.get('password').hasError('minlength') || signUpForm.get('password').hasError('required') ? 'text-dark' : 'text-success'">
                                                <i class="far"
                                                    [ngClass]="{'fa-times-circle': signUpForm.get('password').hasError('minlength') || signUpForm.get('password').hasError('required'),'fa-check-circle':!signUpForm.get('password').hasError('minlength')}"></i>
                                                 Minimum de 8 caractères
                                            </span>
                                    
                                        </span>
                                    
                                    </div>
                                </ng-template>
                               
                             <div class="form-group m-3">
                                    <i id="eye-show" *ngIf="!show_button" class="far fa-eye" (click)="showPassword()"></i>
                                    <i id="eye-hide" *ngIf="show_button" class="far fa-eye-slash" (click)="showPassword()"></i>
                                    <input [type]="show_button ? 'text' : 'password'" name="password" formControlName="password" [popover]="popTemplate"  triggers="focus" placement="top"
                                        (change)="onPasswordChange($event)" (focus)="disPlayErrors=true" (focusout)="disPlayErrors=false" 
                                        [ngClass]="{ 'error':fields_signUp_form.password.touched && fields_signUp_form.password.invalid ,'success':fields_signUp_form.password.touched && fields_signUp_form.password.valid }"
                                        required autocomplete="nope" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="password">Mot de passe <sup>*</sup></label>
                                    <div class="text-left error-container" *ngIf="fields_signUp_form.password.errors || disPlayErrors">
                                    <small *ngIf="fields_signUp_form.password.touched && fields_signUp_form.password.invalid" class="error-msg">
                                        <span *ngIf="fields_signUp_form.password.errors?.required">Mot de passe
                                            obligatoire</span>
                                    </small>
                                    <small class="error-msg">
                                        <span
                                            *ngIf="(signUpForm.get('password').hasError('hasCapitalCase') || signUpForm.get('password').hasError('hasSmallCase') || signUpForm.get('password').hasError('hasNumber') || signUpForm.get('password').hasError('hasSpecialCharacters') || signUpForm.get('password').hasError('minlength')) && fields_signUp_form.password.touched">
                                            Le mot de passe ne respecte pas les conditions de sécurité
                                        </span>
                                    </small>
                                    
                                    </div>
                                   
                                </div>
                                   

                                <div class="general-conditions">
                                    Je consens à l’utilisation de mes données personnelles pour recevoir des
                                    offres personnalisées de la part de Renault SAS
                                    et des membres de son réseau commercial :
                                    <div class="question">
                                        <span>Par email <span class="star">*</span></span>
                                        <span>
                                            <span class="radio-item" id="emaily">
                                                <input type="radio" id="isEmailY" formControlName="isEmail" class="mr-1"
                                                    value="Y" name="isEmail">
                                                <label for="isEmailY">oui</label>
                                            </span>
                                            <span class="radio-item" id="emailn">
                                                <input type="radio" id="isEmailN" formControlName="isEmail" class="mr-1"
                                                    value="N" name="isEmail">
                                                <label for="isEmailN">non</label>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="question mt-1 mb-1">
                                        <span>Par SMS <span class="star">*</span></span>
                                        <span>
                                            <span class="radio-item" id="telephoney">
                                                <input type="radio" class="mr-1" id="isTelephoneY"
                                                    formControlName="isTelephone" value="Y" name="isTelephone">
                                                <label for="isTelephoneY">oui</label>
                                            </span>
                                            <span class="radio-item" id="telephonen">
                                                <input type="radio" id="isTelephoneN" class="mr-1"
                                                    formControlName="isTelephone" value="N" name="isTelephone">
                                                <label for="isTelephoneN">non</label>
                                            </span>
                                        </span>
                                    </div>
                                    <!-- <small *ngIf="fields.isTelephone.touched && fields.isTelephone.invalid" class="error-msg text-left">
                                                <span *ngIf="fields.isTelephone.errors.required">Vuillez choisir un</span>
                                            </small> -->
                                </div>
                                <div class="cgu">
                                    <label class="chk-container mb-2">
                                        <span class="text-left d-block sign-up-legals">En créant un compte, j'accepte
                                            <a class="sign-up-legals-link" target="_blank" rel="noopener noreferrer"
                                                href="https://myr.renault.fr/conditions-generales-utilisation-de-my-renault.html">les
                                                conditions d'utilisation</a></span>
                                        <input type="checkbox" formControlName="isCguAccepted">
                                        <span class="checkmark"></span>
                                    </label>
                                    <div class="text-left error-container">
                                        <span
                                            *ngIf="fields_signUp_form.isCguAccepted.touched && signUpForm.get('isCguAccepted').hasError('required')"
                                            class="error-msg">
                                            Vous devez accepter les conditions d'utilisations pour bénéficier des
                                            services Top Renault
                                        </span>
                                    </div>
                                </div>
                                <div class="text-center captcha-container" *ngIf="isCaptha2">
                                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                                    </ngx-recaptcha2>
                                </div>

                                <div class="mt-4 mb-2">
                                    <button id="sign-up-btn" type="button"
                                        [disabled]="signUpForm.invalid || !isGenderSelected" (click)="signUp()"> <span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span>Créer un
                                        compte </button>
                                </div>
                             

                                <div class="legals">
                                    <p class="obligation-text">* <i>Champs obligatoires</i></p>
                                    <p class="legals-heading">Pour Renault, la protection de vos données personnelles
                                        est importante.</p>
                                    <p class="legals-text">
                                        Vos données personnelles sont traitées par Renault SAS et les membres de son
                                        réseau commercial, en tant que
                                        responsables
                                        conjoints de traitement. Vos données sont utilisées pour la gestion de la
                                        relation commerciale notamment la
                                        gestion de
                                        votre demande et l’envoi d’enquête de satisfaction, le reporting sur leurs
                                        activités, la conduite d'activités de
                                        recherche et développement, l’amélioration des produits et services notamment en
                                        recourant à de l’analyse
                                        avancée, la
                                        gestion de votre participation à des études produits / tendances et l'envoi
                                        d'offres commerciales personnalisées
                                        susceptibles de vous intéresser. Vous disposez d’un droit d’accès à vos données
                                        personnelles. Vous pouvez en
                                        outre
                                        solliciter la rectification ou l’effacement de vos données personnelles. Vous
                                        bénéficiez également du droit de
                                        vous
                                        opposer au traitement de celles-ci, sans motif lorsqu’il s’agit de prospection
                                        commerciale (y compris par voie
                                        postale
                                        et téléphone), en justifiant d’une situation particulière dans les autres cas,
                                        ou de solliciter une limitation
                                        dudit
                                        traitement. Vous pouvez demander la communication de vos données personnelles
                                        dans un format structuré et
                                        standard. Vous
                                        disposez enfin d’un droit de définir des directives relatives au sort de vos
                                        données personnelles après votre
                                        décès.
                                        Vous pouvez exercer ces droits à tout moment en nous adressant votre demande via
                                        le formulaire
                                        <a style="color:#000 !important;text-decoration:underline;"
                                            href="https://www.renault.fr/vos-droits.html"
                                            target="_blank">https://www.renault.fr/vos-droits.html</a>
                                        ou un courrier postal à l’adresse : Renault SAS Direction juridique – Délégué à
                                        la protection des données 13/15 quai le Gallo 92100 Boulogne-Billancourt. Pour
                                        en savoir plus, suivez le lien
                                        <a style="color:#000 !important;text-decoration:underline;"
                                            href="https://www.renault.fr/donnees-personnelles.html" target="_blank">Vos
                                            données personnelles.</a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>