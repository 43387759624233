<div class="auto-container" style="max-width:1198px !important;">
    <div class="bg-showcase">
<!-- 
        <div class="row justify-content-center align-self-center" *ngIf='!isCguValidated'>
            <div class="col-md-5 col-sm-5 col-xs-5 col-lg-5 card m-0 p-0 mt-5 rounded-0 ">
              
               <strong class="cgu-heading p-2 ">Valider les cgu</strong> 
                <div class="thanks-text text-center mt-4">
                    Merci de valider les CGU pour continuer
                </div>
                <form [formGroup]="signUpForm" class="m-auto pt-3">
                <div class="cgu m-auto mt-5">
                    <label class="chk-container mb-2 ">
                        <span class="text-left d-block sign-up-legals pl-4">j'accepte
                            <a class="sign-up-legals-link" target="_blank" rel="noopener noreferrer"
                                href="https://myr.renault.fr/conditions-generales-utilisation-de-my-renault.html">les
                                conditions d'utilisation</a></span>
                        <input type="checkbox" formControlName="isCguAccepted">
                        <span class="checkmark"></span>
                    </label>
                    <div class="text-left error-container">
                        <span *ngIf="fields_signUp_form.isCguAccepted.touched && signUpForm.get('isCguAccepted').hasError('required')"
                            class="error-msg">
                            Vous devez accepter les conditions d'utilisations pour bénéficier des
                            services Top Renault
                        </span>
                    </div>
                </div>
                </form>
                <div class="mt-5 mb-3 pt-4 text-center">
                    <button id="sign-up-btn" type="button" [disabled]="signUpForm.invalid" (click)="submit()">Continuer
                        </button>
                </div>
               
            </div>
            
        </div> -->

        <div class="row justify-content-center align-self-center" *ngIf='isCguValidated'>
            <div class="col-md-5 col-sm-5 col-xs-5 col-lg-5 card m-0 p-0 mt-5 rounded-0 ">
            
                <strong class="cgu-heading p-2 ">Félicitations</strong>
                <div class="image-container text-center">
                    <img class="user-account-img mt-5" src="../../assets/images/background/comment.svg" alt="compte activation" width="100"
                        height="100">
                </div>
                <div class="thanks-text text-center pt-2  mt-3">
                   Bienvenue sur Top Renault le compagnon de vos trajets
                </div>
            
                <div class="mt-2 mb-3 pt-2 text-center">
                    <button id="entrer-btn" type="button" (click)="profile()" routerLink='/user-profile'>EnTRER
                    </button>
                </div>
        
            </div>
        
        </div>
    </div>
</div>
