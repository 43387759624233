import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToprenaultService } from '../services/toprenault.service';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidators } from './custom-validators';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public id;
  public passwordForm: FormGroup;
  public readonly siteKey = '6Ldor8cUAAAAAGF54mtqYILJHlf0CAobNHJaymS_';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'fr';
  public type: 'image' | 'audio';
  public show_button: boolean = false;
  public show_eye: boolean = false;
  public isPasswordUpdated: boolean = false;
  public disPlayErrors: boolean = false;
  public isLoading: boolean = false;

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private topRenaultservice: ToprenaultService, private cdr: ChangeDetectorRef) {
    this.route.params.subscribe(params => {
      if (params.id != undefined && params.id !== null) {
        this.id = params.id
      }
    })
  }

  ngOnInit(): void {

    this.passwordForm = this.formBuilder.group({
      password: [null, Validators.compose([Validators.required,
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
      Validators.minLength(8)])],
      recaptcha: [null, Validators.required]
    });

  }

  get fields_signUp_form() {
    return this.passwordForm.controls;
  }


  changePassword() {
    this.isLoading = true;
    let formDetails = this.passwordForm.getRawValue();
    Object.assign(formDetails, { id: this.id });
    this.topRenaultservice.changePassword(formDetails).subscribe(res => {
      if (res['success']) {
        this.isPasswordUpdated = true;
        this.reset();
        this.isLoading = false;
      }
    })
  }

  loginPage() {
    this.isLoading = true;
    this.router.navigate(['/user-authorise']);
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }


  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
    // this.captchaElem2.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
    // this.captchaElem2.resetCaptcha();
  }
}
