<div *ngIf="ie; else elseBlock">
        <div>
                <img class="outdatedBrowser__brand" src="../../assets/images/oldbrowser-renault.png" alt="brand logo" />
                <p class="outdatedBrowser__text" id="oldbrowser-label">Mettez à jour votre navigateur pour bénéficier d'une expérience de visionnage optimale</p>
                <div class="outdatedBrowser__container">
                  <ul class="outdatedBrowser__items">
                    <li class="outdatedBrowser__item">
                      <a href="https://www.google.com/chrome/" class="outdatedBrowser__link" target="_blank">
                        <img class="outdatedBrowser__logo" src="../../assets/images/oldbrowser-chrome.png" alt="logo chrome" />
                      </a>
                      <ul class="outdatedBrowser__underItems">
                        <li class="outdatedBrowser__underItem">
                          <img class="outdatedBrowser__availableLogo" src="../../assets/images/oldbrowser-windows.png" alt="logo windows" />
                        </li>
                        <li class="outdatedBrowser__underItem">
                          <img class="outdatedBrowser__availableLogo" src="../../assets/images/oldbrowser-apple.png" alt="logo apple" />
                        </li>
                        <li class="outdatedBrowser__underItem">
                          <img class="outdatedBrowser__availableLogo" src="../../assets/images/oldbrowser-linux.png" alt="logo linux" />
                        </li>
                      </ul>
                    </li>
                    <li class="outdatedBrowser__item">
                      <a href="https://www.mozilla.org/" class="outdatedBrowser__link" target="_blank">
                        <img class="outdatedBrowser__logo" src="../../assets/images/oldbrowser-firefox.png" alt="logo firefox" />
                      </a>
                      <ul class="outdatedBrowser__underItems">
                        <li class="outdatedBrowser__underItem">
                          <img class="outdatedBrowser__availableLogo" src="../../assets/images/oldbrowser-windows.png" alt="logo windows" />
                        </li>
                        <li class="outdatedBrowser__underItem">
                          <img class="outdatedBrowser__availableLogo" src="../../assets/images/oldbrowser-apple.png" alt="logo apple" />
                        </li>
                        <li class="outdatedBrowser__underItem">
                          <img class="outdatedBrowser__availableLogo" src="../../assets/images/oldbrowser-linux.png" alt="logo linux" />
                        </li>
                      </ul>
                    </li>
                    <li class="outdatedBrowser__item">
                      <a href="https://www.apple.com/" class="outdatedBrowser__link" target="_blank">
                        <img class="outdatedBrowser__logo" src="../../assets/images/oldbrowser-safari.png" alt="logo safari" />
                      </a>
                      <div class="outdatedBrowser__underItems">
                        <div class="outdatedBrowser__underItem">
                          <img class="outdatedBrowser__availableLogo" src="../../assets/images/oldbrowser-apple.png" alt="logo apple" />
                        </div>
                      </div>
                    </li>
                    <li class="outdatedBrowser__item">
                      <a href="https://www.microsoft.com/" class="outdatedBrowser__link" target="_blank">
                        <img class="outdatedBrowser__logo" src="../../assets/images/oldbrowser-edge.png" alt="logo edge" />
                      </a>
                      <div class="outdatedBrowser__underItems">
                        <div class="outdatedBrowser__underItem">
                          <img class="outdatedBrowser__availableLogo" src="../../assets/images/oldbrowser-windows.png" alt="logo windows" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            
</div>
<ng-template #elseBlock>
        <router-outlet> 
          
        </router-outlet>
</ng-template>


