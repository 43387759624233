import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { ToprenaultService } from '../services/toprenault.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public emailForm: FormGroup;
  public readonly siteKey = '6Ldor8cUAAAAAGF54mtqYILJHlf0CAobNHJaymS_';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'fr';
  public type: 'image' | 'audio';
  public isFormSubmitted: boolean = false;
  public emailAddress: string;
  public notValidEmail: boolean = false;
  public isLoading: boolean = false;
  public errorMsg: string;
  public userEmail: string = '';



  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private topRenaultservice: ToprenaultService, private router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email, Validators.pattern('^(?:\s)?[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,}(?:\s)?$')]],
      recaptcha: [null, Validators.required]
    });
  }


  gotToForgotPass() {
    this.router.navigate(['/user-authorise']);
  }

  get fields_login_form() {
    return this.emailForm.controls;
  }


  resetPassword() {
    let formDetails = this.emailForm.getRawValue();
    this.emailAddress = formDetails.email;
    this.isLoading = true;

    this.topRenaultservice.passwordSendEmail(formDetails).subscribe(res => {
      if (res['error'] && res['code'] == '1') {
        this.notValidEmail = true;
        this.isLoading = false;
        this.errorMsg = res['message'];
        this.emailForm.controls['email'].setErrors({ 'incorrect': true });
        this.emailForm.reset();
        this.reset();

      } else {
        this.isLoading = false;
        this.isFormSubmitted = true;
        this.reset();
      }
    })
  }


  resendEmail(email) {
    this.isLoading = true;
    this.topRenaultservice.resendChangePasswordEmail(email).subscribe(res => {
      console.log(res);
      if (res['success']) {
        this.isLoading = false;
        this.toastr.success(res['message']);
      } else {
        this.isLoading = false;
        this.toastr.error(res['message']);
      }

    });

  }
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
    // this.captchaElem2.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }



  reset(): void {
    this.captchaElem.resetCaptcha();
    // this.captchaElem2.resetCaptcha();
  }

}
