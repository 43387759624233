import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToprenaultService } from '../services/toprenault.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-account-confirm',
  templateUrl: './account-confirm.component.html',
  styleUrls: ['./account-confirm.component.css']
})
export class AccountConfirmComponent implements OnInit {
  public email;
  public user_id;
  public isLoading: boolean = false;
  constructor(private route: ActivatedRoute, private topRenaultservice: ToprenaultService, private toastr: ToastrService,) {
    if (this.route.snapshot.paramMap.get('email')) {
      this.email = this.route.snapshot.paramMap.get('email');
      this.user_id = this.route.snapshot.paramMap.get('id');
    }
  }

  ngOnInit(): void {
  }

  resendEmail() {
    this.isLoading = true;
    if (this.email !== undefined && this.user_id !== undefined) {
      let obj = { email: this.email, user_id: this.user_id };
      this.topRenaultservice.resendAccountActivationEmail(obj).subscribe(res => {
        if (res['success']) {
          this.isLoading = false;
          this.toastr.success(res['message']);
        } else {
          this.isLoading = false;
          this.toastr.error(res['message']);
        }
      })

    }
  }
}
