<div *ngIf='isLoading' style="height: 100vh;">
<app-reload-list></app-reload-list>
</div>

<section class="inventory-section" *ngIf='!isLoading'>
  <div class="container" >
    <div class="row" style="border-left: 1px solid #f0f0f0;border-right: 1px solid #f0f0f0;margin-left:0;margin-right: 0;">
      <!--Form Column-->
      <div id="filter-form" class="form-column col-lg-3 col-md-3 col-sm-12 col-xs-12" @show>
        <!--Select Car Tabs-->
        <h4 id="affinez-text">
          Affinez votre recherche
        </h4>


        <div class="select-cars-tabs">
          <!--Tabs Box-->
          <div class="prod-tabs tabs-box">
            <!--Tabs Container-->
            <div class="tabs-content">
              <!--Tab / Active Tab-->
              <div>
                <div class="content">
                  <!--Cars Form-->
                  <div class="cars-form">

                    <p class="filter-label-main">
                      marque(s)
                    </p>
                    <div class="form-group" *ngFor="let marque of marqueList ;let i=index">
                      <div class="checkbox custom_container">
                        <label>
                          <input type="checkbox" (change)="filterList($event,'marque')" value="{{marque.term}}">
                          <span class="filter-label">{{marque.term}}</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <p class="filter-label-main">
                      modèle(s)
                    </p>
                    <div class="form-group" *ngFor="let modele of genericModelsArray;let i=index;">
                      <div class="checkbox custom_container" *ngIf="i<=4">
                        <label>
                          <input type="checkbox" (change)="filterList($event,'model')" value="{{modele}}">
                          <span class="filter-label">{{modele | uppercase}}</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div id="seeMore" class="collapse" style="padding: 0 !important;" aria-expanded="true"
                      aria-controls="seeMoreText">
                      <div>
                        <div class="form-group" *ngFor="let modele of genericModelsArray;let i=index;">
                          <div class="checkbox custom_container" *ngIf="i>4">
                            <label>
                              <input type="checkbox" (change)="filterList($event,'model')" value="{{modele}}">
                              <span class="filter-label">{{modele | uppercase }}</span>
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a data-toggle="collapse" id="seeMoreText" data-target="#seeMore" aria-expanded="true"
                      aria-controls="seeMore" (click)="toggleModeles()">
                      <i class="show-more-icon"
                        [ngClass]="!modelIcon ? 'fas fa-chevron-down ' : 'fas fa-chevron-up'"></i><br />
                    </a>

                    <p class="filter-label-main">
                      version
                    </p>

                    <div class="input-group">
                      <form class="form-search form-inline">
                        <!-- (keydown.enter)="searchByVersion()"  -->
                        <input class="form-control" spellcheck="false" type="text" id="version-input-desktop"
                          style="width:226px !important;font-size:14px !important; color: #1f2532;font-family:'Read-Regular';"
                          (focus)="hideIconVersion('version-desktop')" (focusout)="showIconVersion('version-desktop')"
                          name="version" autocomplete="off" [(ngModel)]="versionValue"
                          placeholder="Ex:Intens ENERGY dCi 90" [typeahead]="versionList"
                          (typeaheadNoResults)="typeaheadNoResults($event,'version')"
                          (typeaheadOnSelect)="searchByVersion()" [typeaheadScrollable]="true"
                          [typeaheadOptionsInScrollableView]="9">
                        <span id="version-desktop-icon" *ngIf="!noVersion"><i style="font-size: 18px;color: #1f2532;"
                            class="icofont-search"></i></span>
                        <br />
                        <span id="model-text" class="version"
                          *ngIf="versionValue!==undefined && versionValue!='' && !noVersion && versionValue.length>=2"
                          style="background-color: #1f2532 !important;">{{versionValue}}
                          <span class="close-btn"
                            style="font-size:10px !important; padding-left:5px !important; cursor: pointer;"
                            (click)="unselectVersion('desktop')" class="icon flaticon-cancel">
                          </span>
                        </span>
                      </form>
                      <br />
                      <!-- class="alert alert-danger"  -->
                      <small style="color: #E91630;font-size: 14px" *ngIf="noVersion"> *La
                        version non trouvée</small>
                    </div>
                    <p class="filter-label-main">
                      prix
                    </p>
                    <div class="form-group">
                      <form [formGroup]="prixForm">
                        <ng5-slider formControlName="prixControl" [(value)]="minPrix"
                          (userChangeEnd)="priceChange($event,'prix')" [(highValue)]="maxPrix" [options]="optionsPrix">
                        </ng5-slider>
                      </form>
                    </div>
                    <!-- Prix à débattre -->
                    <div class="prix-debattre-container">
                      <label class="switch">
                        <input type="checkbox" (change)="filterList($event,'NegotiablePrice')" value="Prix à débattre">
                        <span class="slider round"></span>
                      </label>
                      <span class="prix-debattre-text">Prix à débattre</span>
                    </div>
                    <!-- Localisation -->
                    <p class="filter-label-main">
                      localisation
                    </p>
                    <div class="form-group">
                      <form id="loc-form" class="form-search form-inline">
                        <input class="form-control" [maxlength]="3" [disabled]="departementArray.length===5"
                          spellcheck="false" type="text" id="searchloc"
                          style="width:226px !important;font-size:14px !important; color: #1f2532;" autocorrect="off"
                          autocomplete="off" spellcheck="off" (focus)="hideIcon('location-desktop')"
                          (focusout)="showIcon('location-desktop')" (blur)="codeDep.length" name="Code-department"
                          [(ngModel)]="codeDep" placeholder="code département" [typeahead]="codeDepLists"
                          typeaheadOptionField="code" [typeaheadItemTemplate]="customItemTemplate"
                          (typeaheadNoResults)="typeaheadNoResults($event,'code')"
                          (typeaheadOnSelect)="searchByDepartment($event.value)" [typeaheadScrollable]="true">
                        <ng-template #customItemTemplate let-model="item">
                          <span style="font-size: 13px !important;font-family: 'Read-Regular';">
                            <strong>{{model.code}}</strong> - {{model.nom}}</span>
                        </ng-template>
                        <br />
                        <span id="loc-desktop-icon" [hidden]="departementArray.length===5"><i
                            style="font-size: 18px;color: #1f2532;" class="icofont-search"></i>
                        </span>
                        <small class="dept-message" *ngIf="departementArray.length===5">
                          <!-- <i class="fas fa-exclamation-triangle" style="padding-right: 2px;font-size :9px;"></i> -->
                          <img src="../../assets/images/icons/warning.svg" alt="" height="15"  width="15">
                          Impossible de sélectionner plus
                          de 5 départements</small>
                        <small class="nodept-message" *ngIf="noDepartment"> *
                          Code département non disponible
                        </small>
                        <ul *ngIf='departementArray.length' class="department-list">
                          <li *ngFor="let departement of departementArray" class="checked-filter department-list-item">
                            {{departement}}
                            <span class="close-btn"
                              style="font-size:10px;padding-left: 5px !important; cursor: pointer;"
                              (click)="unselectDepartment(departement)" class="icon flaticon-cancel">
                            </span>
                          </li>
                        </ul>

                      </form>
                    </div>

                    <p class="filter-label-main">
                      kilométrage
                    </p>

                    <div class="form-group">
                      <form [formGroup]="sliderForm">
                        <ng5-slider formControlName="sliderControl" [(value)]="minKm" [(highValue)]="maxKm"
                          [options]="optionsKm" (userChangeEnd)="priceChange($event,'km')"></ng5-slider>
                      </form>
                    </div>
                    <p class="filter-label-main">
                      energie
                    </p>

                    <div class="form-group">

                      <div class="checkbox custom_container" *ngFor="let energie of energieList;">
                        <label>
                          <input [checked]="isChecked" type="checkbox" (change)="filterList($event,'energie')"
                            value="{{energie.term}}">
                          <span class="filter-label">{{energie.term | lowercase }}</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <p class="filter-label-main">
                      couleurs
                    </p>
                    <div class="form-group"></div>
                    <div class="row ">
                      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngFor="let couleur of couleurList;">
                        <label class="color-container">
                          <input type="checkbox" (change)="filterList($event,'couleur')" value="{{couleur.term}}">
                          <span class="color-checkmark"  [ngStyle]="{'background': '#'+couleur.term}"></span>
                        </label>
                      </div>
                    </div>
                    <p class="filter-label-main" style="margin-top:0 !important;">
                      boite de vitesses
                    </p>

                    <div class="form-group">
                      <div class="checkbox custom_container" *ngFor="let boiteVitesse of genericBoitedeVitesseArray;">
                        <label>
                          <input type="checkbox" (change)="filterList($event,'boiteVitesse')"
                            value="{{boiteVitesse.term}}">
                          <span class="filter-label">{{boiteVitesse.term | popPipe | titlecase}}</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <!-- <p class="filter-label-main">
                      contrats de services
                    </p>
                    <div class="checkbox custom_container">
                      <label>
                        <input type="checkbox" (change)="filterList($event,'Label Top Renault')"
                          value="Label Top Renault">
                        <span class="filter-label">Label Top Renault</span>
                        <span class="checkmark"></span>
                      </label>
                    </div> -->

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--End Select Car Tabs-->

      </div>
      <!--Column-->

      <div id="mobile-filter-form" style="padding-left:0!important;padding-right:0!important;"
        class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
        <h4 class='vechicules-count'
          style="  padding: 15px 20px 15px 25px !important;background-color: #f3f5f6;text-align: center;">
          {{ carsTotalCount }} véhicules en vente
        </h4>
        <div class="tab" role="tabpanel">
          <!-- Nav tabs -->
          <ul id="mobile-nav-ul" class="nav nav-tabs" role="tablist">
            <li role="presentation" id="mobile-nav-tab1">
              <a id="mobile-nav-a1" href="#Section1" aria-controls="Section1" role="tab" data-toggle="tab"
                [ngClass]="{'active': toggle}">
                <strong>filtrer</strong>
                <span id="mobile-icon1" style="margin-left:20px !important;" class="fas fa-chevron-down"
                  aria-hidden="true"></span>
              </a>
            </li>
            <li role="presentation" id="mobile-nav-tab2">
              <a id="mobile-nav-a2" href="#Section2" aria-controls="Section2" role="tab" data-toggle="tab">
                <strong>trier</strong>
                <span id="mobile-icon2" style="margin-left:20px !important;" class="fas fa-chevron-down"
                  aria-hidden="true"></span>
              </a>
            </li>
          </ul>

          <div class="tab-content tabs">
            <!-- Marque(s)-->
            <div role="tabpanel" class="tab-pane fade" id="Section1">
              <div class="panel panel-default">
                <div [ngClass]="isCollapsed1 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-1">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('one')" role="button" data-toggle="collapse"
                      href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                      <h5>
                        <strong>
                          <span [ngClass]="isCollapsed1 ? 'isCollpsed-Color' : ''">
                            Marque(s)</span>

                          <span style="float: right;font-size:14px;text-align:center;margin-right: 10px;">
                            <span
                              [ngClass]="isCollapsed1 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></span>
                          </span>
                        </strong>
                      </h5>
                    </a>
                  </h4>
                </div>
                <div id="collapse-1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-1">
                  <div class="panel-body">
                    <div class="form-group" style="margin-bottom: 0 !important;">
                      <div class="form-group" *ngFor="let marque of marqueList;" style="margin-bottom: 0 !important;">
                        <div class="checkbox custom_container">
                          <label>
                            <input type="checkbox" (change)="filterList($event,'marque')" value="{{marque.term}}">
                            <span class="filter-label">{{marque.term}}</span>
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>
                    </div>

                  </div>
                </div>
              </div>
              <!--Modèle(s): -->
              <div class="panel panel-default">
                <div [ngClass]="isCollapsed2 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-2">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('two')" role="button" data-toggle="collapse"
                      href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                      <h5>
                        <strong>
                          <span [ngClass]="isCollapsed2 ? 'isCollpsed-Color' : ''">
                            <strong>Modèle(s) </strong>
                          </span>

                          <span style="float: right;font-size:14px;text-align:center;margin-right: 10px;">
                            <i
                              [ngClass]="isCollapsed2 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                          </span>
                        </strong>
                      </h5>
                    </a>
                  </h4>
                </div>
                <div id="collapse-2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-2">
                  <div class="panel-body">
                    <div class="form-group">
                      <div class="checkbox custom_container" *ngFor="let modele of genericModelsArray">
                        <label>
                          <input type="checkbox" (change)="filterList($event,'model')" value="{{modele}}">
                          <span class="filter-label">{{modele | uppercase}}</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>
                  </div>
                </div>
              </div>
              <!-- Localisation: -->
              <div class="panel panel-default">
                <div [ngClass]="isCollapsed3 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-3">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('three')" role="button" data-toggle="collapse"
                      href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                      <h5>
                        <strong>
                          <span [ngClass]="isCollapsed3 ? 'isCollpsed-Color' : ''">

                            <strong>Localisation</strong>
                          </span>
                          <span style="float: right;font-size:14px;text-align:center;margin-right: 10px;">
                            <i
                              [ngClass]="isCollapsed3 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                          </span>
                        </strong>
                      </h5>
                    </a>
                  </h4>
                </div>
                <div id="collapse-3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-3">
                  <div class="panel-body">
                    <div class="form-group">
                      <form class="form-search form-inline">
                        <!-- <input type="text" [maxlength]="5" class="form-control" (keyup.enter)="getCodePostal()" (focus)="hideIcon('location-mobile')"  (focusout)="showIcon('location-mobile')"  placeholder="Code postal.." autocorrect="off" autocapitalize="off" spellcheck="off"  id="searchlocmobile"
                                                [(ngModel)]="code_postale" name="code_postale"> -->
                        <input class="form-control" [maxlength]="3" [disabled]="departementArray.length===5"
                          spellcheck="false" autocomplete="off" type="text" id="searchlocmobile"
                          style="font-size:14px !important; color: #1f2532;border-radius: 4px !important;"
                          (focus)="hideIcon('location-mobile')" (focusout)="showIcon('location-mobile')"
                          placeholder="Code postal.." autocorrect="off" autocapitalize="off" spellcheck="off"
                          name="code-department" [(ngModel)]="codeDep" placeholder="Code département"
                          [typeahead]="codeDepLists" typeaheadOptionField="code"
                          [typeaheadItemTemplate]="customItemTemplate"
                          (typeaheadNoResults)="typeaheadNoResults($event,'code')"
                          (typeaheadOnSelect)="searchByDepartment($event.value)" [typeaheadScrollable]="true"
                          [typeaheadOptionsInScrollableView]="4">
                        <ng-template #customItemTemplate let-model="item">
                          <span style="font-size: 12px;font-family: 'Read-Regular';">
                            <span class="icon flaticon-location-pin"
                              style="font-size: 12px !important;font-weight: 700 !important;color: #777777c4 !important;padding-right:10px"
                              aria-hidden="true"></span>
                            <strong>{{model.code}}</strong> - {{model.nom}}</span>
                        </ng-template>
                        <br />
                        <span id="loc-mobile-icon" *ngIf="!codeDep"><i style="font-size: 18px;color: #1f2532;"
                            class="icofont-search"></i>
                        </span>
                        <small class="dept-message" *ngIf="departementArray.length===5">
                          <img src="../../assets/images/icons/warning.svg" alt="" height="15" width="15">
                          Impossible de sélectionner plus
                          de 5
                          départements</small>
                        <small class="nodept-message" *ngIf="noDepartment"> *
                          Code département non disponible
                        </small>
                        <br />
                        <ul *ngIf='departementArray.length' class="department-list">
                          <li *ngFor="let departement of departementArray" class="checked-filter department-list-item">
                            {{departement}}
                            <span class="close-btn"
                              style="font-size:10px;padding-left: 5px !important; cursor: pointer;"
                              (click)="unselectDepartment(departement)" class="icon flaticon-cancel">
                            </span>
                          </li>
                        </ul>
                      </form>
                    </div>
                    <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>
                  </div>
                </div>
              </div>
              <!-- Version: -->
              <div class="panel panel-default">
                <div [ngClass]="isCollapsed9 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-9">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('nine')" role="button" data-toggle="collapse"
                      href="#collapse-9" aria-expanded="false" aria-controls="collapse-9">
                      <h5>
                        <strong>
                          <span [ngClass]="isCollapsed9 ? 'isCollpsed-Color' : ''">

                            <strong>Version</strong>
                          </span>
                          <span style="float: right;font-size:14px;text-align:center;margin-right: 10px;">
                            <i
                              [ngClass]="isCollapsed9 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                          </span>
                        </strong>
                      </h5>
                    </a>
                  </h4>
                </div>
                <div id="collapse-9" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-9">
                  <div class="panel-body">
                    <div class="form-group">
                      <form class="form-search form-inline">
                        <input type="text" class="form-control" placeholder="Ex:Intens ENERGY dCi 90.."
                          id="version-input-mobile" (focus)="hideIconVersion('version-mobile')"
                          (focusout)="showIconVersion('version-mobile')" autocorrect="off" autocapitalize="off"
                          style="border-radius: 4px !important;font-size: 14px !important;color: #1f2532;"
                          spellcheck="off" id="searchVersionMobile" [(ngModel)]="versionValue" [typeahead]="versionList"
                          autocomplete="off" (typeaheadNoResults)="typeaheadNoResults($event,'version')"
                          (typeaheadOnSelect)="searchByVersion()" [typeaheadScrollable]="true"
                          [typeaheadOptionsInScrollableView]="5" name="version">
                        <span id="version-mobile-icon"><i style="font-size: 18px;color: #1f2532;"
                            class="icofont-search"></i></span>
                        <br />
                        <span id="model-text-mobile" class="version"
                          *ngIf="versionValue!==undefined && versionValue!=='' && !noVersion">{{versionValue}}
                          <span id="close-btn" style="font-size:10px !important;" (click)=" unselectVersion('mobile')"
                            class="icon flaticon-cancel">
                          </span> </span>
                      </form>
                      <div class="alert alert-danger" *ngIf="noVersion">La version non trouvée</div>
                    </div>
                    <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>
                  </div>
                </div>
              </div>
              <!-- Prix:  -->

              <div class="panel panel-default">
                <div [ngClass]="isCollapsed4 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-4">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('four')" role="button" data-toggle="collapse"
                      href="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                      <h5>
                        <strong>
                          <span [ngClass]="isCollapsed4 ? 'isCollpsed-Color' : ''">

                            <strong>Prix</strong>

                          </span>

                          <span style="float: right;font-size:14px;text-align:center;margin-right: 10px;">
                            <i
                              [ngClass]="isCollapsed4 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                          </span>
                        </strong>
                      </h5>
                    </a>
                  </h4>
                </div>
                <div id="collapse-4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-4">
                  <div class="panel-body">
                    <div class="form-group">
                      <form [formGroup]="prixForm">
                        <ng5-slider formControlName="prixControl" [(value)]="minPrix" [(highValue)]="maxPrix"
                          [options]="optionsPrix" (userChangeEnd)="priceChange($event,'prix')"></ng5-slider>
                      </form>

                    </div>
                    <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>
                  </div>
                </div>
              </div>



              <div class="panel panel-default">
                <div [ngClass]="isCollapsed11 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-11">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('eleven')" role="button" data-toggle="collapse"
                      href="#collapse-11" aria-expanded="false" aria-controls="collapse-11">
                      <h5>
                        <strong>
                          <span [ngClass]="isCollapsed11? 'isCollpsed-Color' : ''">
                            <strong>Prix à debattre</strong>
                          </span>
                          <span style="float: right;font-size:14px;text-align:center;margin-right: 10px;">
                            <i
                              [ngClass]="isCollapsed11? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                          </span>
                        </strong>
                      </h5>
                    </a>
                  </h4>
                </div>
                <div id="collapse-11" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-11">
                  <div class="panel-body">
                    <div class="form-group">
                      <div class="prix-debattre-container">
                        <label class="switch">
                          <input type="checkbox" (change)="filterList($event,'NegotiablePrice')"
                            value="Prix à débattre">
                          <span class="slider round"></span>
                        </label>
                        <span class="prix-debattre-text">Prix à débattre</span>
                      </div>
                    </div>
                    <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>
                  </div>
                </div>
              </div>
              <!-- kilometrage:  -->

              <div class="panel panel-default">
                <div [ngClass]="isCollapsed5 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-5">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('five')" role="button" data-toggle="collapse"
                      href="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                      <h5>
                        <strong>
                          <span [ngClass]="isCollapsed5 ? 'isCollpsed-Color' : ''">
                            <strong>Kilométrage</strong>
                          </span>
                          <span style="float: right;font-size:14px;text-align:center;margin-right: 10px;">
                            <i
                              [ngClass]="isCollapsed5 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                          </span>
                        </strong>
                      </h5>
                    </a>
                  </h4>
                </div>
                <div id="collapse-5" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-5">
                  <div class="panel-body">
                    <div class="form-group">
                      <form [formGroup]="sliderForm">
                        <ng5-slider formControlName="sliderControl" [(value)]="minKm" [(highValue)]="maxKm"
                          [options]="optionsKm" (userChangeEnd)="priceChange($event,'km')"></ng5-slider>
                      </form>
                    </div>
                    <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>
                  </div>
                </div>
              </div>

              <!-- colors -->
              <div class="panel panel-default">
                <div [ngClass]="isCollapsed6 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-6">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('six')" role="button" data-toggle="collapse"
                      href="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                      <h5>
                        <strong>
                          <span [ngClass]="isCollapsed6 ? 'isCollpsed-Color' : ''">

                            <strong>couleurs</strong>

                          </span>

                          <span style="float: right;font-size:14px;text-align:center;margin-right: 10px;">
                            <i
                              [ngClass]="isCollapsed6 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                          </span>
                        </strong>
                      </h5>
                    </a>
                  </h4>
                </div>
                <div id="collapse-6" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-6">
                  <div class="panel-body">
                    <div class="form-group"></div>
                    <div class="row align-items-start">
                      <div class="col" *ngFor="let couleur of couleurList;" style="flex-grow: 0 !important;">
                        <label class="color-container">
                          <input type="checkbox" (change)="filterList($event,'couleur')" value="{{couleur.term}}">
                          <span class="color-checkmark"  [ngStyle]="{'background-color': '#'+couleur.term}"></span>
                        </label>
                      </div>
                    </div>
                    <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>

                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div [ngClass]="isCollapsed7 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-7">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('seven')" role="button" data-toggle="collapse"
                      href="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                      <h5>
                        <strong>
                          <span [ngClass]="isCollapsed7 ? 'isCollpsed-Color' : ''">
                            <strong>Boite de vitesses</strong>
                          </span>

                          <span style="float: right;font-size:14px;text-align:center; margin-right: 10px;">
                            <i
                              [ngClass]="isCollapsed7 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                          </span>
                        </strong>
                      </h5>
                    </a>
                  </h4>
                </div>
                <div id="collapse-7" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-7">
                  <div class="panel-body">
                    <div class="form-group">
                      <div class="checkbox custom_container" *ngFor="let boiteVitesse of genericBoitedeVitesseArray;">
                        <label>
                          <input type="checkbox" (change)="filterList($event,'boiteVitesse')"
                            value="{{boiteVitesse.term}}">
                          <span class="filter-label">{{boiteVitesse.term | popPipe | titlecase}}</span>
                          <span class=" checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div [ngClass]="isCollapsed8 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-8">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('eight')" role="button" data-toggle="collapse"
                      href="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
                      <h5>
                        <strong>
                          <span [ngClass]="isCollapsed8 ? 'isCollpsed-Color' : ''">
                            <strong>Energie</strong>
                          </span>
                          <span style="float: right;font-size:14px;text-align:center;margin-right: 10px;">
                            <i
                              [ngClass]="isCollapsed8? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                          </span>
                        </strong>
                      </h5>
                    </a>
                  </h4>
                </div>
                <div id="collapse-8" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-8">
                  <div class="panel-body">
                    <div class="form-group">
                      <div class="checkbox custom_container" *ngFor="let energie of energieList;">
                        <label>
                          <input type="checkbox" (change)="filterList($event,'energie')" value="{{energie.term}}">
                          <span class="filter-label">{{energie.term | lowercase}}</span>
                          <span class=" checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div [ngClass]="isCollapsed10 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab"
                  id="heading-10">
                  <h4 class="text-left">
                    <a class="collapsed" (click)="toggleClass('ten')" role="button" data-toggle="collapse"
                      href="#collapse-10" aria-expanded="false" aria-controls="collapse-10">
                      <!-- <h5>
                        <strong>
                          <span [ngClass]="isCollapsed10? 'isCollpsed-Color' : ''">
                            <strong>Contrats de services</strong>
                          </span>
                          <span style="float: right;font-size:14px;text-align:center;margin-right: 10px;">
                            <i
                              [ngClass]="isCollapsed10? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                          </span>
                        </strong>
                      </h5> -->
                    </a>
                  </h4>
                </div>
                <div id="collapse-10" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-10">
                  <div class="panel-body">
                    <!-- <div class="form-group">
                      <div class="checkbox custom_container">
                        <label>
                          <input type="checkbox" (change)="filterList($event,'Label Top Renault')"
                            value="Label Top Renault">
                          <span class="filter-label">Label Top Renault</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div> -->
                    <button (click)="closeTabs()" class="btn btn-warning">Appliquer</button>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane fade" id="Section2">
              <!-- <ng-select [items]="trierArray" (change)="getTriervalue($event)" bindLabel="Select" bindValue="trierArray" [(ngModel)]="selectedValue">
                            </ng-select> -->
              <ul class="list-group">
                <li class="list-group-item" *ngFor="let trier of trierArray;let i=index;"
                  (click)="getTriervalue(trier,i)">
                  {{trier}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-9"
        style="max-width: 100% !important;padding-right: 0px !important;padding-left: 0 !important;">
        <!-- <router-outlet></router-outlet> -->
        <app-car-list-view   [carSubject]="carSubject.asObservable()"></app-car-list-view>
        <!-- <app-car-list-view   [carInput]="carInput"></app-car-list-view> -->
      </div>
    </div>
  </div>
</section>
