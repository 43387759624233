import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges, ChangeDetectorRef, Output, ChangeDetectionStrategy } from '@angular/core';
import { ToprenaultService } from '../services/toprenault.service';
import { ActivatedRoute, UrlSerializer, Router, NavigationEnd, NavigationCancel, NavigationError, NavigationStart, Event } from '@angular/router';
// import { CarList } from '../models/car-list';
// import { CarListComponent } from '../car-list/car-list.component';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { animate, state, transition, trigger, style, keyframes } from '@angular/animations';

import { OrderPipe } from 'ngx-order-pipe';
import { CockpitCmsService } from '../services/cockpitCms.service';
import { Vignette } from '../models/car';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-car-list-view',
  templateUrl: './car-list-view.component.html',
  styleUrls: ['./car-list-view.component.scss'],
  animations: [
    trigger('show', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]

})
export class CarListViewComponent implements OnInit {


  public subscription: Subscription;
  public carsData: any;
  public listOrGrid: string;
  public totalItems: number;
  public currentPage: number = 1;
  public p: number = 1;
  public itemsperPage: number;
  public searchParam;
  public facets = [];
  public carList: any = [];
  public trierArray = ["sélectionner","Version croissante", "Version décroissante", "Date de disponibilité croissante", "Date de disponibilité  décroissante", "Prix croissant", "Prix décroissant", "Kilométrage croissant", "Kilométrage décroissant"];
  public selectedValue: any;
  public name = "please select a value";
  public sortedCarData;
  public searchParamSort: string = "";
  public loading: boolean = false;
  public ModelText: any;
  public totalPages: string;
  public isPage: boolean = false;
  public sortText: string; s
  public i: number = 0;
  public disabled = false;
  public today: string;
  public sortedCollection: any[];
  public vignette = {} as Vignette;
  public isFirstLoad = true;
  public isFavorite: boolean = false;
  public carIds:Array<any> = [];
  public userObj;
  public isInFavorites: boolean = false;
  public favoritesList = [];
  public selectedFilters: any = [];
  public colorsFilters: any = [];
  // @Input('carSubject') carSubject = new BehaviorSubject({});
  @Input('carSubject') carSubject: Subject<any> = new Subject<any>();
  @Input('carInput') carInput: any;
  constructor(private activeRoute: ActivatedRoute, private cdr: ChangeDetectorRef, private router: Router, private topRenaultservice: ToprenaultService, private orderPipe: OrderPipe, private cockpitCmsService: CockpitCmsService) {
    this.today = new Date().toISOString().split("T")[0];
    this.listOrGrid = "grid";
    this.searchParamSort = sessionStorage.getItem("searchParamSort");
    
  }

  ngOnInit() {

    // this.subscription = this.topRenaultservice.currentParamCarFilters.subscribe(carRequest => {
    this.subscription = this.carSubject.subscribe(carRequest => {
      this.carsData = {};
      this.setDataCar(carRequest);
      if (sessionStorage.getItem("selectedValue") !== undefined && sessionStorage.getItem("selectedValue") !== "undefined" && sessionStorage.getItem("selectedValue") !== null) {
        this.selectedValue = sessionStorage.getItem("selectedValue");
        // this.sortCars(this.selectedValue);
        this.getTriervalue(this.selectedValue);
      } else {
        this.selectedValue = this.trierArray[0];
         this.getTriervalue(this.selectedValue);
      }
      this.cdr.detectChanges();
    })
  }

  async setDataCar(carRequest) {
    if (carRequest != null) {
      // let filters = this.topRenaultservice.selectedFilters.getValue();
      let filters = JSON.parse(sessionStorage.getItem("checked-filerts-storage"));
      filters = filters == null || filters == undefined ? [] : filters;
      this.selectedFilters = filters;
      this.topRenaultservice.colorFilters$.subscribe(colors => {
        this.colorsFilters = colors;
      });
      if (sessionStorage.getItem("selectedValue") !== undefined && sessionStorage.getItem("selectedValue") !== "undefined" && sessionStorage.getItem("selectedValue") !== null) {
        this.selectedValue = sessionStorage.getItem("selectedValue");
      } else {
        this.selectedValue = this.trierArray[0];
      }


      registerLocaleData(fr);
      this.loading = true;
      let pageCount = this.topRenaultservice.getpageSize();
      this.carsData = carRequest;
      if (pageCount === 9) {

        this.carList = this.carsData.data;

        for (let car of this.carsData.data) {
          if ('favoritesList' in localStorage) {
            let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
            let index = favoritesInLocalStorage.indexOf(car.productId);
            if (index !== -1) {
              car = Object.assign(car, { 'isInFavorites': true }, { 'isNotInFavorites': false });
            } else {
              car = Object.assign(car, { 'isInFavorites': false }, { 'isNotInFavorites': true });
            }
          } else {
            car = Object.assign(car, { 'isInFavorites': false }, { 'isNotInFavorites': true });
          }
        };

        this.carList.sort((car, car1) => (car['topRenaultAd']['topRenaultLabelFlag'] === car1['topRenaultAd']['topRenaultLabelFlag'] ? 0 : car['topRenaultAd']['topRenaultLabelFlag'] ? -1 : 1));
        this.isFirstLoad = false;

        for (let car of this.carsData.data) {
          this.carIds.push(car.productId);
        }

      } else {
        // this.carsData = this.activeRoute.snapshot.data['dataCars'];
        for (let car of this.carsData.data) {
          if ('favoritesList' in localStorage) {
            let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
            let index = favoritesInLocalStorage.indexOf(car.productId);
            if (index !== -1) {
              car = Object.assign(car, { 'isInFavorites': true }, { 'isNotInFavorites': false });
            } else {
              car = Object.assign(car, { 'isInFavorites': false }, { 'isNotInFavorites': true });
            }
          } else {
            car = Object.assign(car, { 'isInFavorites': false }, { 'isNotInFavorites': true });
          }
        };

        for (let car of this.carsData.data) {
          // if (car != null && car['topRenaultAd']['topRenaultLabelFlag'] == true) {
          //   this.carList.unshift(car);
          // } else if (car != null && car['topRenaultAd']['topRenaultLabelFlag'] == false) {
          this.carIds.push(car.productId);
          this.carList.push(car);
          // }
        }

        this.carIds = [...new Set(this.carIds)];


        // this.carList.sort((car, car1) => (car['topRenaultAd']['topRenaultLabelFlag'] === car1['topRenaultAd']['topRenaultLabelFlag'] ? 0 : car['topRenaultAd']['topRenaultLabelFlag'] ? -1 : 1));
        //Récupération des données de la vignette
        var data = await this.cockpitCmsService.getVignetteHomePage().toPromise();

        // this.cockpitCmsService.getVignetteHomePage().subscribe(data => {
        // if (data != undefined && !data['entries'].isEmpty) {
        //   this.vignette.titre = data['entries'][0]['Title'],
        //     //this.vignette.description = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        //     this.vignette.description = data['entries'][0]['Description'],
        //     this.vignette.link = data['entries'][0]['Link'],
        //     this.vignette.bouton = data['entries'][0]['Bouton'],
        //     this.vignette.position = Number(data['entries'][0]['PositionListVehicule']) - 1;
        //   this.carList.splice(this.vignette.position, 0, this.vignette);
        // }
        // })
        this.carsData.data = this.carList;
      }

      // console.log("car list ", JSON.stringify(this.carsData))
      this.topRenaultservice.setCarsCount(this.carsData.totalElements);

      this.loading = false;
      this.totalItems = this.carsData.totalElements;
      this.totalPages = this.carsData.totalPages;
      this.itemsperPage = this.carsData.pageSize;

      sessionStorage.setItem("carsDataLength", this.carsData.totalElements);

      //Dans le cadre de la pagination, on a besoin de récupérer la page courante
      if (sessionStorage.getItem("isPage") !== "undefined" &&
        sessionStorage.getItem("isPage") !== "null") {
        this.p = JSON.parse(sessionStorage.getItem("pageCurrent"));
        sessionStorage.setItem("isPage", undefined);
      } else {
        this.p = 1;
      }
      if (sessionStorage.getItem("full-Text") !== undefined && sessionStorage.getItem("full-Text") !== 'undefined') {
        this.ModelText = JSON.parse(sessionStorage.getItem("full-Text"));
      }
      if (sessionStorage.getItem("selectedValueMobile") !== 'undefined' && sessionStorage.getItem("selectedValueMobile") !== 'null') {
        this.sortText = sessionStorage.getItem("selectedValueMobile");
      }
      let searchFilters;
      if (sessionStorage.getItem("searchFilters") !== undefined && sessionStorage.getItem("searchFilters") !== 'undefined') {
        searchFilters = sessionStorage.getItem("searchFilters");
      }
      if (sessionStorage.getItem('flagInventoryGtm') !== undefined
        && sessionStorage.getItem('flagInventoryGtm') === 'true') {
        this.pushDataLayerForPageView(searchFilters, this.ModelText);
      }
      sessionStorage.setItem('flagInventoryGtm', 'false');
      sessionStorage.setItem("flagEngagementGtm", 'true');
      //sessionStorage.setItem("full-Text", undefined);
      sessionStorage.setItem("flagHomePageGtm", 'true')

      // }
    }
  }
  uncheckFilterHome() {
    this.topRenaultservice.setFilterTerm([]);
    this.ModelText = null;
    // sessionStorage.removeItem('fullText');
  }
  uncheckFilter(filterterm) {
    this.selectedFilters = this.selectedFilters.filter(term => term !== filterterm);
     this.topRenaultservice.setFilterTerm(filterterm);

  }

  unCheckFilterColor(color) {
    this.colorsFilters = this.colorsFilters.filter(term => term !== color);
    this.topRenaultservice.setFilterTerm(color);
  }

  unSelect() {
    sessionStorage.setItem("full-Text", undefined);
    sessionStorage.setItem("selectedValueMobile", undefined);
    let query = sessionStorage.getItem("query");
    // this.router.navigate(['liste/updating', { q: query }]);
  }

  getTriervalue(e) {
    if (e !== this.trierArray[0] ) {
      this.sortCars(e);
    }
  }

  selectCar(id) {
    sessionStorage.setItem("id", id);
    sessionStorage.getItem("id");
    sessionStorage.setItem('flagDetailsGtm', 'true')
    this.router.navigate(['/details']);
  }

  runJS() {
    if (document.getElementById("topRenault")) {
      document.getElementById("topRenault").remove();
    }

    let topRenault = document.createElement("script");
    topRenault.setAttribute("id", "topRenault");
    topRenault.setAttribute("src", "assets/js/script.js");
    document.body.appendChild(topRenault);
  }


  addToFavorite(id, e) {
    e.stopPropagation();
    if ('favoritesList' in localStorage) {
      let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
      let index = favoritesInLocalStorage.indexOf(id);
      if (index == -1) {
        favoritesInLocalStorage.push(id);
        for (let car of this.carsData.data) {
          if (car.productId == id) {
            car.isNotInFavorites = false;
            car.isInFavorites = true;
          }

        }
        localStorage.setItem('favoritesList', JSON.stringify(favoritesInLocalStorage));
        this.isInFavorites = true;
        this.topRenaultservice.setFavorisCount(favoritesInLocalStorage.length);
      }
    } else {
      this.favoritesList.push(id);
      for (let car of this.carsData.data) {
        if (car.productId == id) {
          car.isNotInFavorites = false;
          car.isInFavorites = true;
        }

      }
      localStorage.setItem('favoritesList', JSON.stringify(this.favoritesList));
      this.topRenaultservice.setFavorisCount(this.favoritesList.length);

    }
  }


  removeFromFavorite(id, e) {
    e.stopPropagation()
    let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
    favoritesInLocalStorage = favoritesInLocalStorage.filter(idInLocalStorage => id !== idInLocalStorage);
    localStorage.setItem('favoritesList', JSON.stringify(favoritesInLocalStorage));
    for (let car of this.carsData.data) {
      if (car.productId == id) {
        car.isNotInFavorites = true;
        car.isInFavorites = false;
      }

    }
    this.topRenaultservice.setFavorisCount(favoritesInLocalStorage.length);

  };


  async sortCars(val) {
    this.topRenaultservice.setPageSize(9);
    sessionStorage.setItem("isNotLoadedFirstTime", "true");
    this.searchParam = sessionStorage.getItem("query");
    this.searchParam = this.searchParam == null || this.searchParam == undefined ? "&q" : this.searchParam;
    sessionStorage.setItem("selectedValue", val)
    this.selectedValue = val;
    if (this.searchParam !== undefined && this.searchParam !== null && this.searchParam.includes("&sort=")) {

      let text = this.searchParam.substring(this.searchParam.indexOf('&sort='))
      this.searchParam = this.searchParam.replace(text, "");
    }
    if (val === "Date de disponibilité croissante") {
      this.searchParamSort = "&sort=%2BtopRenaultAd.availabilityDate";

    }
    else if (val === "Date de disponibilité  décroissante") {
      this.searchParamSort = "&sort=%2DtopRenaultAd.availabilityDate";

    }
    else if (val === "Prix croissant") {
      this.searchParamSort = "&sort=%2Bprices.discountedPriceWithTaxes";
      this.searchParam = this.searchParam + this.searchParamSort;
    } else if (val === "Prix décroissant") {
      this.searchParamSort = "&sort=%2Dprices.discountedPriceWithTaxes"
    }
    else if (val === "Kilométrage croissant") {
      this.searchParamSort = "&sort=%2BtopRenaultAd.mileage";

    }
    else if (val === "Kilométrage décroissant") {
      this.searchParamSort = "&sort=%2DtopRenaultAd.mileage";

    }
    else if (val === "Version croissante") {
      this.searchParamSort = "&sort=%2Bversion.label.raw";

    }
    else if (val === "Version décroissante") {
      this.searchParamSort = "&sort=%2Dversion.label.raw";

    }




    sessionStorage.setItem("sort", JSON.stringify(true));
    // sessionStorage.setItem("query", this.searchParam);
    sessionStorage.setItem("searchParamSort", this.searchParamSort);
    this.carsData = await this.topRenaultservice.getfilteredList(this.searchParam).toPromise();
    this.setDataCar(this.carsData)
    this.totalItems = this.carsData.totalElements;
    this.totalPages = this.carsData.totalPages;
    this.itemsperPage = this.carsData.pageSize;

    this.cdr.detectChanges();
    // this.topRenaultservice.changeCurrentParamCarFilters(true)
    // this.router.navigate(['liste', { q: this.searchParam }]);
  }


  // Cette méthode est utilisée pour la pagination
  async onChangePage(indexPage) {
    //hide the lable top renault card
    this.topRenaultservice.setPageSize(9);
    sessionStorage.setItem("isNotLoadedFirstTime", "true");

    sessionStorage.setItem("pageCurrent", JSON.stringify(indexPage));
    indexPage = indexPage - 1;
    let query = sessionStorage.getItem("query");
    query = query == null || query == undefined ? "&q=" : query;
    // console.log("query onChangePage",JSON.stringify(query));
    //On verifie s'il y a la clé &page= dans le query si oui, on l'enlève pour ajouter la nouvelle
    if (query !== undefined && query !== null && query.includes("&page=")) {
      query = query.slice(0, query.lastIndexOf("&page="));
    }
    // query = query.replace(null, '&q=');

    query += "&page=" + indexPage;
    sessionStorage.setItem("sort", JSON.stringify(true));
    sessionStorage.setItem("isPage", JSON.stringify(true));
    // Mise à jour de query
    sessionStorage.setItem("query", query);
    this.carsData = await this.topRenaultservice.getfilteredList(query).toPromise();

    this.setDataCar(this.carsData);
    this.carSubject = new Subject<any>();
    this.totalItems = this.carsData.totalElements;
    this.totalPages = this.carsData.totalPages;
    this.itemsperPage = this.carsData.pageSize;
    // this.carSubject.next(this.carsData);
    this.cdr.detectChanges();
    // this.topRenaultservice.changeCurrentParamCarFilters(true)
    // this.router.navigate(['liste', { q: query }]);
  }


  pushDataLayerForPageView(searchFilter, searchTerm) {
    window.dataLayer.push({
      event: 'virtualPageview',
      googleAccount: 'UA-19376323-1',
      businessType: 'used-car',
      pageType: 'inventory',
      leadID: undefined,
      formType: undefined,
      vehicleCategory: undefined,
      vehicleModel: undefined,
      inventoryId: undefined,
      optinStatus: undefined,
      loginStatus: undefined,
      countryCode: 'FR',
      languageCode: 'FR',
      formCategory: undefined,
      formName: undefined,
      searchFilters: searchFilter,
      searchTerm: searchTerm,
      resultsNumber: undefined,
      brand: undefined,
      pageName: 'top-renault | inventory'
    })
  }

  goToPage() {
    this.router.navigate([this.vignette.link], { queryParams: { page: 'ltr' } });
  }


}

