
 <div class="d-flex" style="background: #f3f5f6;">
<ul *ngIf="selectedFilters.length>0" style="padding:10px 8px;background-color: #f3f5f6 !important">
  <li *ngFor="let filter of selectedFilters" class="checked-filter" (click)="uncheckFilter(filter)">
    {{filter}}
    <span class="checked-close-btn"
      *ngIf="selectedFilters.length >0 && selectedFilters[0]!=='null' && selectedFilters[0] !==undefined"
      style="font-size:11px !important;cursor: pointer;margin-left:5px;color:#ffffff;" class="icon flaticon-cancel">
    </span>
  </li>
</ul>
<div *ngIf="ModelText!==undefined && ModelText!==null" id="selected-model">
  <span id="model-text">{{ModelText}} <span id="close-btn" style="font-size:10px !important;"
      (click)="uncheckFilterHome()" class="icon flaticon-cancel"></span> </span>
</div>
<ul *ngIf="colorsFilters.length>0" style='background-color: #f3f5f6 !important;padding-bottom:8px;padding-top: 14px'>
  <li title="couleur" *ngFor="let color of colorsFilters" style="display:inline-block !important;margin-top:6px"
    class="checked-filter-color" [ngStyle]="{'background-color': '#' + color}">
    <span class="checked-close-btn"
      style="font-size:11px !important;cursor: pointer;margin-left: 22px;margin-right: 6px;color:#ffffff;"
      class="icon flaticon-cancel" (click)='unCheckFilterColor(color)'></span>
  </li>
</ul>
 </div>



<div *ngIf="totalItems===0; else elseBlock" id="no-vechicule-text">
  <h4 class='vechicules-count'>
    {{totalItems | number:'':'fr'}} véhicules en vente
  </h4>
  <p> Votre recherche n'a retourné aucun résultat. Veuillez modifier les critères de recherche.</p>
</div>
<ng-template #elseBlock>
  <div class="col-md-12" style="padding-right:0 !important;padding-left:0 !important;">
    <div style="padding-left:0 !important;background-color: #f3f5f6 !important;margin-bottom: 0 !important;"
      id="list-container" class="column col-lg-12 col-md-12 col-sm-12 col-xs-12" @show>

      <div *ngIf="sortText!==undefined && sortText!==null " id="sort-trier">
        <span id="sort-text">{{sortText}}
          <span id="close-btn" style="font-size:10px !important;" (click)="unSelect()" class="icon flaticon-cancel">
          </span>
        </span>
      </div>


      <div class="row" style="padding: 15px 20px 20px 25px !important;margin-left:0 !important;">
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 float-left" style="padding-left:0 !important;">
          <div class="sort-form">
          </div>


          <h4 class='vechicules-count'>
            {{totalItems | number:'':'fr'}} véhicules en vente
          </h4>
        </div>
        <div id="desktop-trier" class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 float-right"
          style="padding-right:0 !important;padding-left:0 !important;">
          <div class="row">
            <div class="col-3 text-right text-center pt-1">
              <span>Trier par</span>
            </div>
            <div class="col-9">
            <ng-select [items]="trierArray" (change)="getTriervalue($event)" bindLabel="Select" bindValue="trierArray"
              [(ngModel)]="selectedValue">
            </ng-select>
            </div>

          </div>


        </div>
      </div>

      <div style="padding-left:15px;">


        <div id="dekstop-view" class="row"  *ngIf="carsData!=undefined && carsData.data!=undefined" >

          <div
            *ngFor="let car of carsData.data | paginate: { itemsPerPage:itemsperPage, currentPage: p,totalItems:totalItems };let i=index;"
            class="car-block col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div *ngIf="car!==undefined && car.productId!==undefined && !isFirstLoad" class="inner-box"
                (click)="selectCar(car.productId)">

                <div class="img-container layerd"
                  *ngIf="car.assets!==undefined && car.assets.length > 0 && car.assets[0].renditions!==undefined">
                  <a>
                    <img src="{{car.assets[0].renditions[0]?.url}}" alt="car-image" />
                  </a>
                    <div class="tgas-container">
                      <div class="tags">
                        <div class="tag">
                          <div
                            *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined && car.prices[0].discounts.length > 0">
                             - {{(car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined && car.prices[0].discounts.length > 0 && car.prices[0]?.discounts[0]?.discountAmount!=undefined && car.prices[0]?.discounts[0]?.discountAmount!=='') ? (car.prices[0]?.discounts[0]?.discountAmount | numberPipe | number:'':'fr') : ''}} €

                          </div>
                        </div>
                        <div class="tag">
                          <!-- <div [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                            <span>Label top Renault </span>
                          </div> -->
                        </div>
                      </div>
                    </div>

                </div>
                <div class="wrapper">
                  <div class="brand-wrapper">
                    <p class="model">
                      {{car.model?.label | uppercase }}
                    </p>
                    <p class="version">
                      {{car.version?.label}}
                    </p>
                  </div>
                  <div class="fuel-gear-wrapper">
                    <span class="fuel">{{car.energy?.label }} </span>
                    <span class="gear">{{car.transmission?.label  | titlecase}}</span>
                  </div>
                  <div class="year-kms">
                    <!-- <span> <span style="color:#cccccc"> | </span> {{car.topRenaultAd?.mileage}} km</span>
                                <span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy'}}</span> -->

                    <span> {{car.topRenaultAd?.mileage}} km <span style="color:#cccccc"> | </span></span>
                    <span class="date-dispo-future" *ngIf="today < car.topRenaultAd?.availabilityDate">
                      <span class="dispo-text">dispo</span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy' }}
                    </span>
                    <span class="date-dispo-passed" *ngIf="today > car.topRenaultAd?.availabilityDate">
                      disponible
                    </span>

                  </div>
                  <div class="location">
                    <span class="icon flaticon-location-pin"
                      style="font-size: 15px !important;font-weight: 700 !important;color: #777777c4 !important;"
                      aria-hidden="true"></span>
                    <span class="department">département - {{car.topRenaultAd?.department}}</span>
                  </div>
                    <div class="price" *ngIf="car.prices!==undefined && car.prices!==null">
                      <p *ngIf="car.originalPrices!=undefined && car.originalPrices.length>0 && car.originalPrices[0].priceWithTaxes !=undefined && car.originalPrices[0].priceWithTaxes!=''">
                        <span  class="original-price mb-1">prix public : {{car.originalPrices[0]?.priceWithTaxes | numberPipe |number:'':'fr'}} € TTC</span>
                      </p>
                      <p class="prix-net">

                        prix net : {{car.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} €
                      </p>
                    </div>
                  <div class="price-negotialble" [hidden]="!car.topRenaultAd?.negotiablePrice">
                    <span>( prix à débattre )</span>
                  </div>
                  <div class="desktop-favoris">
                   <div *ngIf='car.isNotInFavorites' class="favoris mr-1" (click)='addToFavorite(car.productId,$event)'>  <span class="bg-white p-2 favoris-inner pl-3 pr-3">ajouter aux favoris <i class="far fa-star"></i></span> </div>
                    <div *ngIf='car.isInFavorites' class="retirer-favoris mr-1" (click)='removeFromFavorite(car.productId,$event)'> <span class="bg-white p-2 retirer-favoris-inner pl-3 pr-3">
                            retirer des favoris <i class="fas fa-star"></i></span>
                    </div>
                   </div>
                </div>
              </div>
            <div *ngIf="car!==undefined && car.productId!==undefined  && isFirstLoad" class="inner-box"
              (click)="selectCar(car.productId)">

              <div class="img-container layerd"
                *ngIf="car.assets!==undefined && car.assets.length > 0 && car.assets[0].renditions!==undefined">
                <a>
                  <img src="{{car.assets[0].renditions[0]?.url}}" alt="car-image" />
                  <div class="tgas-container">
                    <div class="tags">
                      <div class="tag">
                        <div
                          *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined
                                                                                                                                && car.prices[0].discounts.length > 0">
                          - {{car.prices[0].discounts[0]?.discountAmount | numberPipe | number:'':'fr'}} €

                        </div>
                      </div>
                      <div class="tag">
                        <!-- <div [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                          <span>Label top Renault </span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="wrapper">
                <div class="brand-wrapper">
                  <p class="model">
                    {{car.model?.label | uppercase }}
                  </p>
                  <p class="version">
                    {{car.version?.label}}
                  </p>
                </div>
                <div class="fuel-gear-wrapper">
                  <span class="fuel">{{car.energy?.label }} </span>
                  <span class="gear">{{car.transmission?.label | popPipe | titlecase}}</span>
                </div>
                <div class="year-kms">
                  <!-- <span> <span style="color:#cccccc"> | </span> {{car.topRenaultAd?.mileage}} km</span>
                  <span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy'}}</span> -->

                  <span> {{car.topRenaultAd?.mileage}} km <span style="color:#cccccc"> | </span></span>
                  <span class="date-dispo-future" *ngIf="today < car.topRenaultAd?.availabilityDate">
                    <span class="dispo-text">dispo</span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy' }}
                  </span>
                  <span class="date-dispo-passed" *ngIf="today > car.topRenaultAd?.availabilityDate">
                    disponible
                  </span>

                </div>

                <!-- <div class="remise"
                  *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined ">
                  - {{car.prices[0].discounts[0]?.discountAmount  | numberPipe | number:'':'fr'}} €
                </div>
                <div class="label-topRenault" [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                  <span>Label top Renault </span>
                </div> -->
                <div class="location">
                  <span class="icon flaticon-location-pin"
                    style="font-size: 15px !important;font-weight: 700 !important;color: #777777c4 !important;"
                    aria-hidden="true"></span>
                  <span class="department">département - {{car.topRenaultAd?.department}}</span>
                </div>
                <div class="price" *ngIf="car.prices!==undefined && car.prices!==null">
                  <p>
                    <span class="original-price mb-1">prix public : {{car.originalPrices[0]?.priceWithTaxes | numberPipe | number:'':'fr'}}
                      € TTC</span>
                  </p>
                  <p class="prix-net">

                   prix net : {{car.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} €
                  </p>
                </div>
                <div class="price-negotialble" [hidden]="!car.topRenaultAd?.negotiablePrice">
                  <span>( prix à débattre )</span>
                </div>
                  <div class="desktop-favoris">
                    <div *ngIf='car.isNotInFavorites' class="favoris mr-1" (click)='addToFavorite(car.productId,$event)'> <span
                        class="bg-white p-2 favoris-inner pl-3 pr-3">ajouter aux favoris <i class="far fa-star"></i></span> </div>
                    <div *ngIf='car.isInFavorites' class="retirer-favoris mr-1" (click)='removeFromFavorite(car.productId,$event)'> <span
                        class="bg-white p-2 retirer-favoris-inner pl-3 pr-3">
                        retirer des favoris <i class="fas fa-star"></i></span>
                    </div>
                  </div>
              </div>
            </div>
              <!-- <div *ngIf="i==vignette.position && isFirstLoad  && car.description!=undefined && car.description!=null" class="inner-box offer-special" style="cursor: default !important;">
                  <div class="wrapper"  *ngIf="car.description!=undefined && car.description!=null">
                    <h3 class="offer-title text-lowercase" [class.large-font]="car.description.length>100"
                      [class.medium-font]="car.description.length>50 && car.description.length<100">{{car.titre}}</h3>
                    <h5 class="offer-description text-lowercase" [class.large-font]="car.description.length>100"
                      [class.medium-font]="car.description.length>50 && car.description.length<100">{{car.description}}</h5>
                    <button class="offer-btn" (click)="goToPage()">{{car.bouton}}</button>
                  </div>
              </div> -->
          </div>
        </div>
        <div id="mobile-view"   *ngIf="carsData!=undefined && carsData.data!=undefined">
          <div
            *ngFor="let car of carsData.data | paginate: { itemsPerPage:itemsperPage, currentPage: p,totalItems:totalItems };let i=index;"
            class="car-block col-xs-12">
                <div *ngIf="car && !isFirstLoad " class="inner-box" (click)="selectCar(car.productId)">
                  <div class="flex-wrapper">
                    <div class="flex-item-1">
                      <div class="mobile-image layerd"
                        *ngIf="car.assets!==undefined && car.assets.length > 0 && car.assets[0].renditions!==undefined">
                        <a>
                          <img src="{{car.assets[0].renditions[0]?.url}}" alt="car-image" />
                        </a>
                        <!-- [hidden]="!car.topRenaultAd?.topRenaultLabelFlag" -->
                        <!-- <div class="mobile-label-topRenault" [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                          <span>Label top Renault </span>
                        </div> -->
                      </div>

                      <div class="mobile-price" *ngIf="car.prices!==undefined && car.prices.length > 0">

                        <p> <span class="mobile-original-price">{{car.originalPrices[0]?.priceWithTaxes | numberPipe | number:'':'fr'}}
                            € </span>
                          {{car.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} €</p>
                      </div>
                      <div class="mobile-price-negotialble" style="padding:-10px 0 !important;"
                        [hidden]="!car.topRenaultAd?.negotiablePrice">
                        <span style="padding-left: 5%;font-size: 12px;">( prix à débattre )</span>
                      </div>
                    </div>
                    <div class="flex-item-2">
                      <div class="mobile-brand-wrapper">
                        <p class="mobile-model">
                          {{car.model?.label | uppercase }}
                        </p>
                        <p class="mobile-version">
                          {{car.version?.label}}
                        </p>
                      </div>
                      <div class="mobile-fuel-gear-wrapper">
                        <span class="mobile-fuel">{{car.energy?.label }} </span>
                        <span class="mobile-gear">{{car.transmission?.label | popPipe | titlecase}}</span>
                      </div>
                      <div class="mobile-year-kms">
                        <span>{{car.topRenaultAd?.mileage}} km <span style="color:#cccccc"> |</span></span>
                        <span class="date-dispo-future" *ngIf="today < car.topRenaultAd?.availabilityDate">
                          <span class="dispo-text">Dispo</span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy' }}
                        </span>
                        <span class="date-dispo-passed" *ngIf="today > car.topRenaultAd?.availabilityDate">
                          Disponible
                        </span>
                        <!-- <span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy'}}
                                                             <span style="font-size: 12px !important;color: #777777c4 !important;">(Date dispo) </span>
                                                        </span> -->

                      </div>
                      <div class="mobile-remise"
                        *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined ">
                        - {{car.prices[0].discounts[0]?.discountAmount  | numberPipe | number:'':'fr'}} €
                      </div>
                      <div class="mobile-location" style="padding: 0 0 5px 0 !important;">
                        <span class="mobile-icon flaticon-location-pin"
                          style="font-size: 12px !important;font-weight: 700 !important;color: #777777c4 !important;"
                          aria-hidden="true "></span>
                        <span class="mobile-department">{{car.brand?.label}} - département -
                          {{car.topRenaultAd?.department}}</span>
                      </div>
                      <div class="desktop-favoris">
                        <div *ngIf='!car.hasOwnProperty("Favorite")' class="favoris mr-1" (click)='addToFavorite(car.productId,$event)'> <span
                            class="bg-white p-2 favoris-inner pl-3 pr-3">ajouter aux favoris <i class="far fa-star"></i></span> </div>
                        <div *ngIf='car.hasOwnProperty("Favorite")' class="retirer-favoris mr-1"
                          (click)='removeFromFavorite(car.productId,$event)'> <span class="bg-white p-2 retirer-favoris-inner pl-3 pr-3 border">
                            retirer des favoris <i class="fas fa-star"></i></span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
            <div *ngIf="car" class="inner-box" (click)="selectCar(car.productId)">
              <div class="flex-wrapper">
                <div class="flex-item-1">
                  <div class="mobile-image layerd"
                    *ngIf="car.assets!==undefined && car.assets.length > 0 && car.assets[0].renditions!==undefined">
                    <a>
                      <img src="{{car.assets[0].renditions[0]?.url}}" alt="car-image" />
                    </a>
                    <!-- [hidden]="!car.topRenaultAd?.topRenaultLabelFlag" -->
                        <!-- <div class="mobile-label-topRenault"  [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                        <span>Label top Renault </span>
                        </div> -->
                  </div>

                  <div class="mobile-price" *ngIf="car.prices!==undefined && car.prices.length > 0">

                    <p> <span
                        class="mobile-original-price">{{car.originalPrices[0]?.priceWithTaxes | numberPipe | number:'':'fr'}}
                        € </span>
                      {{car.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} €</p>
                  </div>
                    <div class="mobile-price-negotialble" style="padding:-10px 0 !important;" [hidden]="!car.topRenaultAd?.negotiablePrice">
                      <span style="padding-left: 5%;font-size: 12px;">( prix à débattre )</span>
                    </div>
                </div>
                <div class="flex-item-2">
                  <div class="mobile-brand-wrapper">
                    <p class="mobile-model">
                      {{car.model?.label | titlecase }}
                    </p>
                    <p class="mobile-version">
                      {{car.version?.label}}
                    </p>
                  </div>
                  <div class="mobile-fuel-gear-wrapper">
                    <span class="mobile-fuel">{{car.energy?.label }} </span>
                    <span class="mobile-gear">{{car.transmission?.label | popPipe | titlecase}}</span>
                  </div>
                  <div class="mobile-year-kms">
                    <span>{{car.topRenaultAd?.mileage}} km <span style="color:#cccccc"> |</span></span>
                      <span class="date-dispo-future" *ngIf="today < car.topRenaultAd?.availabilityDate">
                        <span class="dispo-text">dispo</span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy' }}
                      </span>
                      <span class="date-dispo-passed" *ngIf="today > car.topRenaultAd?.availabilityDate">
                        disponible
                      </span>
                    <!-- <span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy'}}
                         <span style="font-size: 12px !important;color: #777777c4 !important;">(Date dispo) </span>
                    </span> -->

                  </div>
                  <div class="mobile-remise"
                    *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined ">
                    - {{car.prices[0].discounts[0]?.discountAmount  | numberPipe | number:'':'fr'}} €
                  </div>
                  <div class="mobile-location" style="padding: 0 0 5px 0 !important;">
                    <span class="mobile-icon flaticon-location-pin"
                      style="font-size: 12px !important;font-weight: 700 !important;color: #777777c4 !important;"
                      aria-hidden="true "></span>
                    <span class="mobile-department">{{car.brand?.label}} - département -
                      {{car.topRenaultAd?.department}}</span>
                  </div>
                  <div class="desktop-favoris">
                    <div *ngIf='!car.hasOwnProperty("Favorite")' class="favoris mr-1" (click)='addToFavorite(car.productId,$event)'> <span
                        class="bg-white p-2 favoris-inner pl-3 pr-3 border">ajouter aux favoris <i class="far fa-star"></i></span> </div>
                    <div *ngIf='car.hasOwnProperty("Favorite")' class="retirer-favoris mr-1"
                      (click)='removeFromFavorite(car.productId,$event)'> <span class="bg-white p-2 retirer-favoris-inner pl-3 pr-3 border">
                        retirer des favoris <i class="fas fa-star"></i></span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
              <!-- <div *ngIf="i==vignette.position && isFirstLoad" class="inner-box offer-special-mobile" style="cursor: default !important;">
                <div class="wrapper">
                  <h3 class="offer-title-mobile">{{car.titre}}</h3>
                    <h5 class="offer-description-mobile">{{car.description}}</h5>
                  <button class="offer-btn-mobile mb-2" (click)="goToPage()">{{car.bouton}}</button>
                </div>
              </div> -->
          </div>
        </div>

      </div>


      <div class="text-center">
        <pagination-controls screenReaderPaginationLabel="Pagination" responsive="false" [maxSize]="7"
          previousLabel="&nbsp;" nextLabel="&nbsp;" (pageChange)="onChangePage(p=$event)"></pagination-controls>
      </div>
    </div>

  </div>
</ng-template>
