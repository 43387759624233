<div class="page-wrapper">
    <!-- Preloader -->
    <div class="preloader"></div>
    <!-- Main Header-->
    <div class="navs">
        <header class="main-header">
            <div class="header-upper">
                <div class="auto-container" style="padding: 0 !important;">
                    <div class="upper-inner clearfix">
                        <div class="row row-list" style="flex-wrap: nowrap;">
                            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="width: 100% !important;">
                                <div id="renault-logo">
                                    <a href="https://toprenault.com/">
                                        <img style="max-width: none!important; display: block; flex-shrink: 0; margin: 10px 16px; float:right;"
                                            height="40px" width="131.86px"
                                            src="../../assets/images/new-logo-renault.svg" alt="image" srcset="">
                                    </a>
                                </div>

                                <div id="renault-logo-mobile">
                                    <a href="https://toprenault.com/">
                                        <img style="max-width: none!important; display: block; flex-shrink: 0; margin: 10px 16px; float: left;"
                                            height="40px" src="../../assets/images/new-logo-renault.svg" alt="image"
                                            srcset="">

                                    </a>
                                </div>
                            </div>
                            <div class="col-xs-10 col-md-10 col-sm-10 col-lg-10">
                                <div class="topmenu" style="float: right;">
                                    <a (click)="showmenu()" routerLink="" class="responsive-icon">
                                        <img src="../../assets/images/new-logo-renault.svg" alt="" srcset="">
                                    </a>

                                    <button title="Menu" type="button" class="btn btn-menu" data-toggle="modal"
                                        data-target="#menuModal">
                                        <img src="../../assets/images/background/group-3.png" alt="" srcset="">
                                    </button>
                                    <a routerLink=""
                                        style="padding-left:8px; font-family: 'RenaultLife-Bold' !important;color:#000000;font-weight: bold;font-size: 14px;"
                                        id="brand"> </a>
                                    <a target="_blank" class="other-links connexion-pgr" href="https://fr.renew.auto/"
                                        style="border-right: none;" 
                                        (click)="trackRedirectMyRenault()">renew </a>
                                    <a target="_blank" class="other-links connexion-pgr"
                                        href="https://myr.renault.fr/top-renault-space.html" style="border-right: none;"
                                         (click)="trackRedirectRenaultOccasion()">connexion <span class="text-uppercase">PGR</span> </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="header-lower">
                <div class="auto-container" style="padding: 0 !important;">
                    <div class="lower-inner" style="background-color: white;border-top:1px solid #f0f0f0;">
                        <div class="topnav" id="myTopnav">
                            <div class="right-nav-container">
                                <div class="nav-item"><a href="https://fr.renew.auto/" target="_blank"
                                        style="font-family:RenaultLife-Regular">renew  </a></div>
                                <div class="nav-item"><a href="https://myr.renault.fr/top-renault-space.html" target="_blank"
                                        style="font-family:RenaultLife-Regular">connexion PGR</a></div>
                            </div>
                            <div class="auto-container nav-auto-container" style="display: flex; flex-direction: row;">

                                <div class="nav-container">
                                    <div class="nav-item"><img class="renault-new-logo" routerLink="" src="../../assets/images/new-logo-renault.svg" alt=""
                                            srcset=""></div>
                                    <div class="nav-item"><a  style="cursor: pointer;" (click)='goToPageCars()'>véhicules collaborateurs </a>
                                    </div>
                                    <div class="nav-item"><a routerLink="/engagements">nos engagements</a></div>
                                    <!-- <div class="nav-item"><a routerLink="/prime-conversion">prime à la conversion</a>
                                    </div> -->


                                </div>
                                <div class="favoris-container-parent ml-3 mt-3" style="width: 20%;">
                                    <div class="nav-item">
                                        <a class="favoris-link pl-2 pr-2" routerLink='/favorites'
                                            data-dismiss="modal">
                                            <div class="favoris-count" *ngIf="favorisCount!=0">
                                                <span style="position: absolute;top: 13%;left: 32%;">
                                                    {{favorisCount}}
                                                </span>
                                            </div>
                                            <div class="favoris-container pl-3">

                                                <div>
                                                    <img src="../../assets/images/background/communication-users-feedbacks-favourite.svg"
                                                        alt="">
                                                    <!-- <i class="far fa-star mb-1" style="font-size: 22px;"></i> -->
                                                </div>
                                                <div class="favoris-text">
                                                    favoris
                                                </div>
                                            </div>


                                        </a>
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>

                            <!-- <div class="col-lg-2" style="border-left: 1px solid #f0f0f0;text-align: center !important;">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <div><span><i style="font-size:25px; padding: 5px 0 2px 0;"
                                                    class="far fa-user"></i></span></div>
                                    </div>
                                </div>

                                <div class="row ">
                                    <div class="col-xs-12">
                                        <div style="font-size:13px; color : #000000">MY Renault</div>
                                    </div>
                                </div>

                            </div> -->


                        </div>


                    </div>
                </div>
            </div>

        </header>
        <!--End Main Header -->
        <router-outlet>
            <div *ngIf="loading" class="loading text-center">
                <div>
                <img src="../../assets/images/ring-spinner.gif" alt="spinner" width="120" height="120">
                    </div>
            </div>

        </router-outlet>

        <!--Main Footer-->
        <footer class="main-footer">
            <div class="auto-container" style="padding: 0;">
                <!--Widgets Section-->
                <div class="widgets-section" style="text-align: center">
                    <div class="row clearfix">

                        <!--Services Block-->
                        <div class="footer-column col-md-6 col-sm-6 col-xs-12">
                            <div class="footer-widget" style="color: #f3f5f6;">
                                <div style="margin-bottom: 10px !important;" class="icon-box">
                                    <img style="color:#fff" src="../../assets/images/background/business-saving-1.svg"
                                        alt="business-saving">
                                </div>
                                <h4>
                                    <a style="color: #f3f5f6 !important;font-size: 1.3rem;font-family:Read-Regular;font-size:15px;"
                                        routerLink="/engagements">Votre Véhicule à Prix d'amis</a>
                                </h4>
                                <div class="text"
                                    style="color: #f3f5f6; margin-top: 10px !important; margin-right: 20px; font-size: 13px;">
                                    La plus belle occasion de s'offrir un
                                    <br>véhicule Renault ou Dacia
                                </div>
                            </div>
                        </div>

                        <!--Services Block-->
                        <!-- <div class="footer-column col-md-4 col-sm-6 col-xs-12">
                            <div class="footer-widget" style="color: #f3f5f6;">
                                <div style="margin-bottom: 10px !important;" class="icon-box">
                                    <img style="color:#fff"
                                        src="../../assets/images/background/business-ic-special-1.svg"
                                        alt="business-saving">
                                </div>
                                <h4>
                                    <a (click)="goToPage('ltr')"
                                        style="color: #f3f5f6 !important;font-size: 1.3rem;font-family:Read-Regular;font-size:15px;cursor:pointer;">Label
                                        Top Renault</a>
                                </h4>
                                <div class="widget-content"
                                    style="color: #f3f5f6; margin-top: 10px !important; margin-right: 20px; font-size: 13px;">
                                    Un nouveau label conçu pour vous
                                </div>
                            </div>
                        </div> -->
                        <!--Services Block-->
                        <div class="footer-column col-md-6 col-sm-6 col-xs-12">
                            <div class="footer-widget" style="color: #f3f5f6;">
                                <div style="margin-bottom: 10px !important;" class="icon-box">
                                    <img style="fill:#fff !important"
                                        src="../../assets/images/background/edit-sheet-warrantly-1.svg"
                                        alt="business-saving">
                                </div>
                                <h4>
                                    <a (click)="goToPage('contrats')"
                                        style="color: #f3f5f6 !important;font-size: 1.3rem;font-family:Read-Regular; font-size:15px;cursor:pointer;">Votre
                                        Contrat de Service
                                    </a>
                                </h4>
                                <div class="widget-content"
                                    style="color: #f3f5f6; margin-top: 10px !important; margin-right: 20px; font-size:13px;">
                                    Votre contrat d'extension de garantie
                                    <br> Une solution simple et pratique pour rouler tranquille
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!--Footer Bottom-->
            <div class="footer-bottom">
                <div class="auto-container">
                    <div class="row">

                        <div class="col-md-9 col-sm-9 col-xs-12">
                            <ul class="footer-nav" style="font-family:RenaultLife-Regular;">
                                <li>
                                    <a href="https://www.renault.fr/contactez-nous/demande-information.html"
                                        target="_blank" rel="noopener">contactez-nous</a>
                                </li>
                                <li>
                                    <a href="https://www.renault.fr/donnees-personnelles.html" target="_blank"
                                        rel="noopener">données personnelles</a>
                                </li>
                                <li>
                                    <a routerLink="/mentionslegales">informations légales</a>
                                </li>
                                <li>
                                    <a href="https://www.renault.fr/cookies.html" target="_blank"
                                        rel="noopener">cookies</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12">
                            <div class="copyright">
                                © Renault {{siteDevelopedYr}} - {{currentYr}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    <!--End pagewrapper-->

    <!--Scroll to top-->
    <div class="scroll-to-top scroll-to-target" data-target="html">
        <span class="icon fa fa-angle-up"></span>
    </div>

    <!-- Modal -->
    <div class="modal right fade" id="menuModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog">
            <div class="modal-content" style="border-radius: 0;background: transparent;">
                <div style="display: flex; height : 100%; width: 100%; flex-direction: row-reverse;">
                    <div class="left-part"
                        style="display: flex; height : 100%; width: 85%; flex-direction: column;background-color:#000;color:#fff;font-family: RenaultLife-Regular; position: relative;">
                        <div (click)="toggleMenu()" class="resp-menu-upper"
                            style="width: 100%;background-color:#000;color:#fff">
                            <span style="padding-left: 2px; font-size: 12px;">vous êtes sur le site </span><br>
                            <a routerLink=""
                                style="font-weight: 700;font-size:16px; color: #000000;font-family:RenaultLife-Bold;"
                                data-dismiss="modal">TOPRENAULT
                            </a>
                            <span>
                                <i style="font-size: 19px; color:white; float: right;font-weight: bold;margin-top: -10px;"
                                    id="chevronmenu" class="icofont-thin-down"></i>
                            </span>
                        </div>
                        <div id="lev1menu" class="resp-menu-lower responsive" style="width: 100%">
                            <a href="https://fr.renew.auto/">renew</a>
                        </div>
                        <div id="lev2menu" class="resp-menu-lower responsive" style="width: 100%">
                            <a href="https://myr.renault.fr/top-renault-space.html">connexion <span class="text-uppercase">PGR</span></a>
                        </div>
                        <div id="lev3menu" class="resp-menu-lower responsive" style="width: 100%">
                            <a routerLink="/liste" data-dismiss="modal">véhicules collaborateurs</a>
                            <a routerLink="/engagements" data-dismiss="modal">nos engagements</a>
                            <!-- <a routerLink="/prime-conversion" data-dismiss="modal">prime à la conversion</a> -->

                        </div>
                        <div class="mobile-container border-top border-left-0" routerLink='/favorites'>

                            <a class="favoris-link pl-2 pr-2  w-50" data-dismiss="modal">
                                <div class="favoris-count" style="top: 4px;right: 37%;" *ngIf="favorisCount!=0">
                                    <span style="position: absolute;top: 13%;left: 32%;" >
                                        {{favorisCount}}
                                    </span>
                                </div>
                                <div class="favoris-container pl-3 pr-3">

                                    <div>
                                        <i class="far fa-star mb-1" style="font-size: 18px;"></i>
                                    </div>
                                    <div class="favoris-text">
                                        Favoris
                                    </div>
                                </div>


                            </a>
                            <!-- <a *ngIf='isUserConnected' class="my-account-container pl-2 pr-2 w-50" (click)='toggleUser()'>
                            <div style="position: relative;">
                                <img width='18' src="../../assets/images/user.png" alt="" class="mb-1" srcset="">
                                <span class="dot-online">
                                    <i class="fas fa-circle ml-1"></i>
                                </span>
                            </div>
                            <div class="my-account-text">
                                Mon compte <i class="fas fa-chevron-up"></i>
                            </div>
                            <div *ngIf='menuToggle' class="menu-toggle" style="top: -140px;width: fit-content;right:0">
                                <ul class="list-group">
                                    <li class="list-group-item ">Bienvenue <strong>{{user.first_name}} {{user.family_name}}</strong></li>
                                    <li class="list-group-item" data-dismiss="modal" (click)='userProfile()'>Mon profil</li>
                                    <li class="list-group-item"  data-dismiss="modal" (click)="logOut()">Se déconnecter <i class="fas fa-sign-out-alt"
                                            style="float:right;padding-top: 2px;"></i></li>
                                </ul>
                            </div>
                        </a> -->

                            <!-- <a *ngIf='!isUserConnected' class="my-account-container pl-2 pr-2" routerLink="/user-authorise" data-dismiss="modal" data-dismiss="modal">
                            <div style="position: relative;">
                                <img width='18' src="../../assets/images/user.png" alt="" class="mb-1" srcset="">
                            </div>
                            <div class="my-account-text">
                                Mon compte
                            </div>
                        </a> -->
                        </div>
                    </div>

                    <div class="resp-close" style="display: flex; height : 100%; width: 15%; flex-direction: column;">
                        <div style="flex:1">
                            <button type="button" style="background: transparent;cursor:pointer;margin-top: 15px;"
                                data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i style="font-size: 30px; font-weight:500;color:#ffffff;"
                                        class="icofont-close-line"></i>
                                </span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <!-- modal-content -->
        </div>
        <!-- modal-dialog -->
    </div>
    <!-- modal -->