import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberPipe'
})
export class NumberPipe implements PipeTransform {

  transform(value: any): any {
    value=Math.round(value);
    return value;
  }

}
