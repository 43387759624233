import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'popPipe'
})
export class PopPipePipe implements PipeTransform {
  transform(value: string): string {
    if(value!==undefined && value!==null){
      return value.split(' ').pop();
    }
   
  }
}
