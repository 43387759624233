<div class="auto-container" style="max-width:1198px !important;">
    <div class="bg-showcase">

            <div class="row justify-content-center align-self-center">
                <div class="col-md-5 col-sm-5 col-xs-5 col-lg-5 card mt-5 rounded-0">
                    <div class="image-container text-center m-3 mt-4">
                        <img class="user-account-img" src="../../assets/images/background/user.svg"  alt="compte activation" width="100" height="100"
                            >
                    </div>
                    <div class="congrats-text mb-2">
                        <strong>Félicitations !</strong>
                    </div>
                   
                    <div class="activated-msg text-center m-3">
                        Votre compte a bien été créé.
                        
                        Vous pouvez dès à présent consulter votre boîte mail à l'adresse suivante: <strong>{{email}}</strong>  pour activer votre
                        compte.
                    </div>
                    <div class="not-received-email m-3 mb-5 text-center pt-4">
                        Vous n'avez pas reçu d'email d'activation? 
                        <span class="resend-link"  (click)="resendEmail()">Renvoyez le moi</span> <br>
                        <span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i>Traitement en cours...</span>
                    </div>
                    
                    <!-- <div class="text-center">
                       
                    </div> -->
                   
                </div>
            </div>
    </div>
</div>