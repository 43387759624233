import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import $ from '../../assets/js/jquery.js';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToprenaultService } from '../services/toprenault.service';
import fr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { animate, state, transition, trigger, style, keyframes } from '@angular/animations';
import { ReCaptcha2Component } from 'ngx-captcha';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-car-details',
  templateUrl: './car-details.component.html',
  styleUrls: ['./car-details.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('show', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(800, style({ opacity: 0 }))
      ])
    ]),
    trigger('image-show', [
      transition(':enter', [
        style({ height: 0 }),
        animate(300, style({ opacity: 0.2 }))
      ]),
      transition(':leave', [
        style({ height: 'auto' }),
        animate(300, style({ opacity: 0.3 }))
      ])
    ]),
  ]
})
export class CarDetailsComponent implements OnInit {
  isCollapsed3: boolean;
  isCollapsed2: boolean;
  isCollapsed1: boolean;
  isCollapsed: boolean;
  public employees = [];
  public car: any;
  public show: boolean = false;
  public isEmail: boolean = false;
  public isTelephone: boolean = false;
  public btnTxt: string = "...lire la suite";
  public submitted = false;
  public isContinue = false;
  userForm: FormGroup;
  public technicalspecifications = [];
  public equipements = [];
  public options = [];
  public exterieur: boolean = true;
  public interieur: boolean = false;
  public charFilter: any = { label: '' };
  public equipFilter: any = { label: '' };
  nombrePorte: any = "";
  public marque;
  public model;
  fullText: any;
  accountId: any;
  personId: any;
  vin: any;
  public today: any;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'fr';
  public type: 'image' | 'audio';
  public useGlobalDomain: boolean = false;
  public apiResponseDescription: string = "";
  public isValidPhoneNumber: boolean = false;
  public discountPercentage: number;
  public readonly siteKey = '6Ldor8cUAAAAAGF54mtqYILJHlf0CAobNHJaymS_';
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  public isFavorite: boolean = false;
  public userObj;
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  imageObject;
  currentIndex = 0;
  public favoritesList = [];
  public isInFavorites: boolean = false;
  constructor(private cdr: ChangeDetectorRef, private toastr: ToastrService, private activeRoute: ActivatedRoute, private formBuilder: FormBuilder, private render: Renderer2, private router: Router, private topRenaultservice: ToprenaultService) {
    sessionStorage.setItem('onglet', "vehicule-detail");
    this.today = new Date().toISOString().split("T")[0];
  }


  showLightbox(index) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
  toggleChartab(val) {

    let elt = document.getElementById("heading-" + val);
    if (elt != undefined) {
      if (elt != undefined && elt.classList.contains("bg-lightblue-accordion")) {
        elt.classList.add("panel-heading1");
        elt.classList.remove("bg-lightblue-accordion");
        elt.classList.remove("panel-heading");
        $(elt).find(".icon").addClass("flaticon-minus");
        $(elt).find(".icon").removeClass("flaticon-plus-symbol")
      }
      else {
        elt.classList.add("panel-heading");
        elt.classList.add("bg-lightblue-accordion");
        elt.classList.remove("panel-heading1");
        $(elt).find(".icon").removeClass("flaticon-minus");
        $(elt).find(".icon").addClass("flaticon-plus-symbol");
      }
    }
  }

  toggleEquiptab(val) {
    let elt = document.getElementById("equip-heading-" + val);
    if (elt != undefined) {
      if (elt != undefined && elt.classList.contains("bg-lightblue-accordion")) {
        elt.classList.add("panel-heading1");
        elt.classList.remove("bg-lightblue-accordion");
        elt.classList.remove("panel-heading");
        $(elt).find(".icon").addClass("flaticon-minus");
        $(elt).find(".icon").removeClass("flaticon-plus-symbol")
      }
      else {
        elt.classList.add("panel-heading");
        elt.classList.add("bg-lightblue-accordion");
        elt.classList.remove("panel-heading1");
        $(elt).find(".icon").removeClass("flaticon-minus");
        $(elt).find(".icon").addClass("flaticon-plus-symbol");
      }
    }
  }

  toggleOptiontab(val) {
    let elt = document.getElementById("option-heading-" + val);
    if (elt != undefined) {
      if (elt != undefined && elt.classList.contains("bg-lightblue-accordion")) {
        elt.classList.add("panel-heading1");
        elt.classList.remove("bg-lightblue-accordion");
        elt.classList.remove("panel-heading");
        $(elt).find(".icon").addClass("flaticon-minus");
        $(elt).find(".icon").removeClass("flaticon-plus-symbol")
      }
      else {
        elt.classList.add("panel-heading");
        elt.classList.add("bg-lightblue-accordion");
        elt.classList.remove("panel-heading1");
        $(elt).find(".icon").removeClass("flaticon-minus");
        $(elt).find(".icon").addClass("flaticon-plus-symbol");
      }
    }
  }

  filterItemsOfType(val) {
    return this.car.technicalSpecifications.filter(spec => spec.presentationGroup.code == val);
  }

  filterLabel(val) {
    return this.car.technicalSpecifications.find(spec => spec.presentationGroup.order == val);
  }

  getPriceDisountPercentage() {
    this.car.prices[0].discounts.forEach(discountItem => {
      if (discountItem.offerType === "discountOverall") {
        this.discountPercentage = discountItem.discountValue;
        return this.discountPercentage;
      }
    })
  }

  onSubmit() {
    this.isContinue = true;
    $("#form-tab2").removeClass("active");
    $("#form-tab3").addClass("active");
    $(".input").focusout().removeClass("focus");

    //Add form complete GTM
    this.formCompletePush();
    sessionStorage.setItem('flagDetailsGtm', 'true')
    //Build data of notification center to send to api
    let notificationCenter = {
      'userId': this.personId,
      'userIdType': 'PERSON_ID',
      'domain': 'TOR',
      'country': 'FR',
      'notifCode': 'FR_FR_REN_OTHER_CONTACTOWNEREMAIL',
      'forceChannel': 'EMAIL',
      'archiveOnly': false,
      'captcha': this.captchaResponse,
      'context': {
        'values': {
          'civilite': this.userForm.get('sexe').value,
          'phone': this.userForm.get('phone').value,
          'Prenom': this.userForm.get('prenom').value,
          'Nom': this.userForm.get('nom').value,
          'email': this.userForm.get('email').value,
          'comment': this.userForm.get('message').value,
          'vin': this.vin
        }
      }
    }

    this.topRenaultservice.sendNotificationCenter(notificationCenter).subscribe(res => {

      if (res.code !== 0) {
        this.apiResponseDescription = res.description;
        this.userForm.get('desc').setValue(res.description);
      } else {
        $("#form-tab2").removeClass("active");
        $("#form-tab3").addClass("active");
      }

    }
    )
  }
  // keyPress(event) {
  //     if(event.target.value.length===10){
  //       return this.isValidPhoneNumber = true;
  //     }  
  //   }
  onContinue() {
    if ($("#prenom").val() == '') {
      $("#prenom").css("border-bottom-color", "#ff0000");

    }
    if ($("#nom").val() == '') {
      $("#nom").css("border-bottom-color", "#ff0000");
    }
    if ($("#email").val() == '') {
      $("#email").css("border-bottom-color", "#ff0000");
    }
    if ($("#phone").val() == '') {
      $("#phone").css("border-bottom-color", "#ff0000");
    }

    return this.isContinue = true;

  }

  toggleImage(val) {
    if (val == 2) {
      this.interieur = true;
      this.exterieur = false;
    } else {
      this.interieur = false;
      this.exterieur = true;
    }
  }

  toggleComment() {
    this.show = !this.show;
    if (this.show) {
      this.btnTxt = "lire moins";
    } else {
      this.btnTxt = "..lire la suite";
    }
  }

  ngOnInit() {

    if (sessionStorage.getItem("full-Text") !== undefined && sessionStorage.getItem("full-Text") !== null) {
      this.fullText = sessionStorage.getItem("full-Text");
    }


    registerLocaleData(fr);
    this.runJS();
    this.car = this.activeRoute.snapshot.data['cardata'];
    console.log("car", this.car);
    let res = this.activeRoute.snapshot.data['favorisData'];
    console.log("res['isExist']", res['isExist']);
    if (res['isExist'] == true) {
      this.isFavorite = true;
    } else {
      this.isFavorite = false;
    }
    console.log("vehicule :", JSON.stringify(this.car))
    this.getPriceDisountPercentage();
    if ('favoritesList' in localStorage) {
      let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
      let index = favoritesInLocalStorage.indexOf(this.car.productId);
      if (index !== -1) {
        this.isInFavorites = true;
      }
    }
    this.technicalspecifications = this.mapperTechnicalSpecifications(this.car);

    this.equipements = this.mapperEquipements(this.car.equipments);
    this.options = this.mapperEquipements(this.car.options);
    this.model = this.car.model.label;
    this.marque = this.car.brand.label;
    this.accountId = this.car.topRenaultAd.accountId;
    this.personId = this.car.topRenaultAd.personId;
    this.vin = this.car.vin;
    this.imageObject = [{
      image: this.car.assets[0].renditions[0]?.url// Youtube url
    },
    {
      image: this.car.assets[1].renditions[0]?.url
    }
    ];
    this.userForm = this.formBuilder.group({
      sexe: [null, Validators.required],
      prenom: [null, Validators.required],
      nom: [null, Validators.required],
      email: [null, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: [null, [Validators.required, Validators.maxLength(13), Validators.minLength(10), Validators.pattern("[0-9]{0-10}")]],
      message: [null, Validators.required],
      desc: [null],
      recaptcha: ['', Validators.required]

    });

    // this.checkIsFav();
    //initialisation du full-text dans la session storage
    // sessionStorage.setItem("full-Text", undefined);
    //Obtenir le nombre des portes
    this.getDoorNumber(this.technicalspecifications);
    // Begin add GTM analytics
    if (this.car !== undefined && this.car !== null && (sessionStorage.getItem('flagDetailsGtm') === undefined ||
      sessionStorage.getItem("flagDetailsGtm") === null
      || sessionStorage.getItem('flagDetailsGtm') === "false")) {
      window.dataLayer.push({
        googleAccount: 'UA-19376323-1',
        businessType: 'used-car',
        pageType: 'details',
        vehicleCategory: undefined,
        vehicleModel: this.model,
        inventoryId: undefined,
        optinStatus: undefined,
        loginStatus: undefined,
        countryCode: 'FR',
        languageCode: 'FR',
        searchFilters: undefined,
        searchTerm: undefined,
        brand: this.marque,
        pageName: 'top-renault | details'
      })
      // End add GTM analytics
    } else {
      window.dataLayer.push({
        event: "virtualPageview",
        googleAccount: "UA-19376323-1",
        businessType: undefined,
        pageType: 'top-renault | details',
        leadID: undefined,
        formType: undefined,
        vehicleCategory: undefined,
        vehicleModel: this.model,
        inventoryId: undefined,
        optinStatus: undefined,
        loginStatus: undefined,
        countryCode: "FR",
        languageCode: "FR",
        formCategory: undefined,
        formName: undefined,
        searchFilters: undefined,
        searchTerm: undefined,
        resultsNumber: undefined,
        project: undefined,
        brand: this.marque,
        usedBrand: undefined,
        pageName: "details",
      });
    }
    sessionStorage.setItem('flagDetailsGtm', 'false');
    sessionStorage.setItem("flagEngagementGtm", 'true');
    sessionStorage.setItem("flagHomePageGtm", 'true');
    sessionStorage.setItem("flagInventoryGtm", 'true')

  }
  runJS() {
    if (document.getElementById("topRenault")) {
      document.getElementById("topRenault").remove();
    }

    let topRenault = document.createElement("script");
    topRenault.setAttribute("id", "topRenault");
    topRenault.setAttribute("src", "assets/js/script.js");
    document.body.appendChild(topRenault);
  }


  validateNumber(event) {
    let charCode = (event.which) ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  onCall() {
    this.isTelephone = !this.isTelephone;
    console.log("onCall() ==")
    //GTM
    window.dataLayer.push(
      {
        event: "clicTel",
        googleAccount: "UA-19376323-1",
        brand: this.marque,
        vehicleCategory: "undefined",
        vehicleModel: this.model,
        businessType: undefined,
        inventoryId: undefined,
        pageType: 'top-renault | details',
        countryCode: "FR",
        pageName: "details",
        languageCode: "FR"
      })

    console.log("Fin onCall() ==")
  }
  checkIsFav() {
    if ('userObj' in sessionStorage) {
      let user_id = sessionStorage.getItem('user_id');
      let car_id = sessionStorage.getItem('id');
      if (user_id !== undefined && user_id !== null && car_id !== undefined && car_id !== null) {
        let userObj = {
          user_id: user_id,
          car_id: car_id
        }
        this.topRenaultservice.checkIsFavorite(userObj).subscribe(res => {
          if (res['isExist']) {
            this.isFavorite = true;
            this.cdr.detectChanges();
          } else {
            this.isFavorite = false;
            this.cdr.detectChanges();
          }
        })
      }
    } else if ('userObj' in localStorage) {
      let user_id = localStorage.getItem('user_id');
      let car_id = localStorage.getItem('id');
      if (user_id !== undefined && user_id !== null && car_id !== undefined && car_id !== null) {
        let userObj = {
          user_id: user_id,
          car_id: car_id
        }
        this.topRenaultservice.checkIsFavorite(userObj).subscribe(res => {
          if (res['isExist']) {
            this.isFavorite = true;
            this.cdr.detectChanges();
          } else {
            this.isFavorite = false;
            this.cdr.detectChanges();
          }
        })
      }

    }

  }

  onEmail() {
    this.isEmail = !this.isEmail;
    document.getElementById("btns-wrapper").style.display = "none";
    // this.pushDataLayerForPageView();
    this.onPushDataLayerFormular();
  }

  onCancel(type) {

    if (type === 'telephone') {
      this.isTelephone = !this.isTelephone;
      this.isContinue = false;
    }
    if (type === 'email') {
      this.isEmail = !this.isEmail;
      this.isContinue = false;
    }

    // this.pushDataLayerForPageView();
    $('#interset-form').trigger("reset");
    $(".inputBox").removeClass("focus");
    $(".input").css("border-bottom", "1px solid #ddd");

    document.getElementById("btns-wrapper").style.display = "block";
  }
  removeDuplicates(array, key) {
    let lookup = {};
    return array.filter(obj => obj.id === key);
  }

  mapperTechnicalSpecifications(car): Array<any> {
    let id: number;
    let codes = [];
    let presentationsGroupsOrganizers = [];
    for (let technicalSpecification of car.technicalSpecifications) {
      codes.push(technicalSpecification.presentationGroup.code);
    }
    //this.removeDuplicates(this.employees, id);
    codes = Array.from(new Set(codes));
    for (let code of codes) {

      let presentationsGroupOrganizer = [];
      let label: string;
      let order: string;
      for (let technicalSpecification of car.technicalSpecifications) {
        if (technicalSpecification.presentationGroup.code === code) {
          let newObj = {
            "code": technicalSpecification.code,
            "label": technicalSpecification.label,
            "value": technicalSpecification.value,
            "order": technicalSpecification.order
          }
          presentationsGroupOrganizer.push(newObj);
          label = technicalSpecification.presentationGroup.label;
          order = technicalSpecification.presentationGroup.order;
        }
      }
      let presentationGroup1 = {
        code,
        label,
        order,
        presentationsGroupOrganizer
      }
      presentationsGroupsOrganizers.push(presentationGroup1);
    }
    return presentationsGroupsOrganizers;
  }

  mapperEquipements(equipements): Array<any> {
    let id: number;
    let codes = [];
    let presentationsGroupsOrganizers = [];
    for (let equipement of equipements) {
      codes.push(equipement.presentationGroup.code);
    }
    //this.removeDuplicates(this.employees, id);
    codes = Array.from(new Set(codes));
    for (let code of codes) {

      let presentationsGroupOrganizer = [];
      let label: string;
      let order: string;
      for (let equipement of equipements) {
        if (equipement.presentationGroup.code === code) {
          let technicalGroup = {
            "code": equipement.technicalGroup.code,
            "label": equipement.technicalGroup.label,
            "order": equipement.technicalGroup.order
          }

          let presenetationGroupe = {
            "code": equipement.code,
            "label": equipement.label,
            "order": equipement.order,
            "group": equipement.group,
            "technicalGroup": technicalGroup

          }

          presentationsGroupOrganizer.push(presenetationGroupe);
          label = equipement.presentationGroup.label;
          order = equipement.presentationGroup.order;
        }
      }
      let presentationGroup1 = {
        code,
        label,
        order,
        presentationsGroupOrganizer
      }
      presentationsGroupsOrganizers.push(presentationGroup1);
    }
    return presentationsGroupsOrganizers;
  }


  toggleClass(val) {
    switch (val) {
      case 'first':
        this.isCollapsed1 = !this.isCollapsed1;
        break;
      case 'second':
        this.isCollapsed2 = !this.isCollapsed2;
        break;
      case 'third':
        this.isCollapsed3 = !this.isCollapsed3;
        break;
      default:
        this.isCollapsed1 = !this.isCollapsed1;
    }
  }

  //Obtenir le nombre des portes
  getDoorNumber(specificationTech) {
    for (let specification of specificationTech) {
      if (specification.label == 'ARCHITECTURE CAISSE') {
        for (let presentation of specification.presentationsGroupOrganizer) {
          if (presentation.label = 'Nombre de portes') {
            this.nombrePorte = presentation.value;
          }
        }
      }
    }
  }

  //GTM method to push datalayer with event form open
  onPushDataLayerFormular() {
    window.dataLayer.push({
      event: 'formOpen',
      googleAccount: 'UA-19376323-1',
      formCategory: 'lead_usedcar',
      formName: 'top-renault-contact-request',
      vehicleCategory: undefined,
      vehicleModel: this.model,
      inventoryId: undefined,
      formType: 'purchase-request',
      businessType: undefined,
      pageType: 'details',
      countryCode: 'FR',
      languageCode: 'FR',
      brand: this.marque
    })
  }
  //GTM method to push datalayer with event form complete
  formCompletePush() {
    window.dataLayer.push({
      event: 'formComplete',
      googleAccount: 'UA-19376323-1',
      leadID: undefined,
      formCategory: "lead_usedcar",
      formName: "top-renault-contact-request",
      vehicleCategory: undefined,
      vehicleModel: this.model,
      inventoryId: undefined,
      formType: 'purchase-request',
      businessType: undefined,
      pageType: 'details',
      countryCode: 'FR',
      languageCode: 'FR',
      brand: this.marque
    })
  }
  returnResearch() {
    //For the pagination
    sessionStorage.setItem("isPage", JSON.stringify(true));
    sessionStorage.setItem("flagReseachHomePage", JSON.stringify(true));
    //GTM 
    sessionStorage.setItem('flagInventoryGtm', 'true');

    this.router.navigate(['liste']);
  }


  addToFavorite(id) {
    if ('favoritesList' in localStorage) {
      let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
      let index = favoritesInLocalStorage.indexOf(id);
      if (index === -1) {
        favoritesInLocalStorage.push(id);
        localStorage.setItem('favoritesList', JSON.stringify(favoritesInLocalStorage));
        this.isInFavorites = true;
      }
      this.topRenaultservice.setFavorisCount(favoritesInLocalStorage.length);
    } else {
      this.favoritesList.push(id);
      localStorage.setItem('favoritesList', JSON.stringify(this.favoritesList));
      this.isInFavorites = true;
      this.topRenaultservice.setFavorisCount(this.favoritesList.length);

    }

  }


  removeFromFavorite(id) {
    let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
    favoritesInLocalStorage = favoritesInLocalStorage.filter(idInLocalStorage => id !== idInLocalStorage);
    localStorage.setItem('favoritesList', JSON.stringify(favoritesInLocalStorage));
    this.isInFavorites = false;
    this.topRenaultservice.setFavorisCount(favoritesInLocalStorage.length);
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }
}
