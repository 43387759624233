<div class="container">
    <div class="auto-container-error" >
        <!-- <div class="background">
           <p class="error-number"> 404</p>
        </div>        -->
        <div class="error-number">
            <!-- <p class="error-number"> 500</p> -->
            <p > 500</p>
         </div> 
          <div class="image-error">
                    <img src="assets/images/background/Renault_clio_error.png"  alt="Renault_clio_error">                
                    <div ><strong>OUPS ! Indisponibilté de cette page.</strong>
                    </div>
                <br/>
                <br/>
            <button (click)="revenir()" class="btn-number btn-warning-number">REVENIR</button> 
           </div>                                     
    </div>
</div>
<!-- <div class="container">
<h1 class="text-center text-danger">Information NOT Found 404</h1>
</div> -->