import {
  Component,
  OnInit,
  NgZone,
  ChangeDetectorRef,
  AfterContentChecked
} from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { Options, LabelType, ChangeContext } from "ng5-slider";
import { FormGroup, FormControl } from "@angular/forms";
import { ToprenaultService } from "../services/toprenault.service";
import $ from "../../assets/js/jquery.js";
import { animate, transition, trigger, style } from "@angular/animations";
import listCodeDepart from "../codeDep.json";
import { browserRefresh } from '../app.component';
import { BehaviorSubject, Subject } from "rxjs";

@Component({
  selector: "app-car-list",
  templateUrl: "./car-list.component.html",
  styleUrls: ["./car-list.component.css"],
  animations: [
    trigger("show", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CarListComponent
  implements OnInit, AfterContentChecked {
  public loading: boolean;
  public toggle: boolean = false;
  public listOrGrid: string;
  public filteredList: any;
  public isChecked: boolean = false;
  public carList: any;
  public paramsArray: Array<string> = [];
  public modelArray: Array<string> = [];
  public energieArray: Array<string> = [];
  public couleurArray: Array<string> = [];
  public boiteVitesseArray: Array<string> = [];
  public codeDepLists: Array<any> = [];
  public marqueArray: Array<string> = [];
  public checkedFiltersArray: Array<any> = [];
  public energieValues: string = "";
  public couleurValues: string = "";
  public marqueValues: string = "";

  public modelStr: string = "";
  public energieStr: string = "";
  public couleurStr: string = "";
  public boiteVitesseStr: string = "";
  public marqueStr: string = "";
  public geoCoder;
  public code_postale = "";

  public plusMoinsTxt: string = "Plus de modèles";
  public qParamStr: string = "";
  //dynamic inputs
  public dynamicModelsArr = [];
  public facets: Array<any> = [];
  public carsData: any;
  public marqueList: Array<any> = [];
  public energieList: Array<any> = [];
  public modeleList: Array<any> = [];
  public couleurList: Array<any> = [];
  public boiteVitesseList: Array<any> = [];
  public available: boolean = false;
  public flagReseachCodeDep: boolean;
  public browserRefresh;
  public isLoading = false;
  //Data get in facets
  public trierArray = [
    "Trier Par",
    "Version croissante",
    "Version décroissante",
    "Date de disponibilité  décroissante",
    "Date de disponibilité croissante",
    "Prix croissant",
    "Prix décroissant",
    "Kilométrage croissant",
    "Kilométrage décroissant"
  ];

  public isCollapsed: boolean = true;
  public isCollapsed1: boolean = false;
  public isCollapsed2: boolean = false;
  public isCollapsed3: boolean = false;
  public isCollapsed4: boolean = false;
  public isCollapsed5: boolean = false;
  public isCollapsed6: boolean = false;
  public isCollapsed7: boolean = false;
  public isCollapsed8: boolean = false;
  public isCollapsed9: boolean = false;
  public isCollapsed10: boolean = false;
  public isCollapsed11: boolean = false;

  public maxPrixInitial: number;
  public minPrixInitial: number;

  public minKmInitial: number;
  public maxKmInitial: number;

  public minPrix: number;
  public versionValue: string;
  public maxPrix: number;
  public minKm: number;
  public maxKm: number;
  public valPerimetre: number = 0;
  public code: string;
  public codeDep: string = "";
  public flagPrix: boolean = false;
  public flagKm: boolean = false;
  public flagLocalisation: boolean = false;
  public prixStr = "";
  public kmStr = "";
  public flagMarque: boolean = false;
  public flagModel: boolean = false;
  public flagReseachModels: boolean = false;
  public flagReseachMarques: boolean = false;
  public flagReseach: boolean = false;
  public selectedValueMobile: any;

  public searchParam;
  public searchParamSort: string = "";
  public selectedIndex: number = null;
  public carsTotalCount: number;
  public istrue = true;
  public versionList: any = [];
  public keyword = "term";
  public noVersion: boolean;
  public noDepartment: boolean;

  public kmUncheckedFlag: boolean = true;
  public prixUncheckedFlag: boolean = true;

  public fullCodePostal: string;

  public selected_color_Array = [];
  public selected_filters_Array_length: number = 0;
  public flagVersion: boolean = false;
  public checked_version_flag: boolean = false;

  public genericModelsArray = [];
  public genericBoitedeVitesseArray = [];

  public modelListGlobal = [];
  public boitedeVitesseGlobal = [];
  public bvFilteredValuesGlobal = [];
  public bvAutomatiqueGlobal = [];
  public bvManualGlobal = [];

  public uniqTopFilters = [];
  public genericRepeatsList = [];
  public modelExmaple = [];
  public totalCars: number;
  public modelIcon = false;
  public flagUpdateModel: boolean = true;
  public flgLabelTopRenault: boolean = false;
  public flgNegotiablePrice: boolean = false;
  public departementArray: Array<any> = [];

  // public carSubject = new BehaviorSubject({});
  public carSubject: Subject<any> = new Subject<any>();
  public carInput: any;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private topRenaultservice: ToprenaultService,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    sessionStorage.setItem("flagReseach", "false");
    this.flagMarque = JSON.parse(sessionStorage.getItem("flagMarque"));
    this.flagModel = JSON.parse(sessionStorage.getItem("flagModel"));

    if (sessionStorage.getItem("checked-filerts-storage") !== null) {
      this.topRenaultservice.setSelectedFilters(JSON.parse(sessionStorage.getItem("checked-filerts-storage")));
      this.updateFilters();
    }

  }

  updateFilters() {
    if (
      sessionStorage.getItem("searchFilters") !== null &&
      sessionStorage.getItem("checked-filerts-storage") !== null
    ) {
      let filtersFromSessionStrorage = JSON.parse(
        sessionStorage.getItem("checked-filerts-storage")
      );

      let tempArray = filtersFromSessionStrorage;
      this.checkedFiltersArray = tempArray;


      if ('couleurArray' in sessionStorage) {
        this.couleurArray = JSON.parse(sessionStorage.getItem("couleurArray"));
        this.selected_color_Array = this.couleurArray;
      }

      if ('version' in sessionStorage) {
        this.checkedFiltersArray.push(sessionStorage.getItem('version'));
      }

      if ('departementArray' in sessionStorage) {
        this.departementArray = JSON.parse(sessionStorage.getItem("departementArray"));
      }

      if ('modelArray' in sessionStorage) {
        this.modelArray = JSON.parse(sessionStorage.getItem('modelArray'));
      }

      if ('marqueArray' in sessionStorage) {
        this.marqueArray = JSON.parse(sessionStorage.getItem('marqueArray'));
      }

      if ('boiteVitesseArray' in sessionStorage) {
        this.boiteVitesseArray = JSON.parse(sessionStorage.getItem('boiteVitesseArray'));
      }

      if ('energieArray' in sessionStorage) {
        this.energieArray = JSON.parse(sessionStorage.getItem('energieArray'));
      }

      if (JSON.parse(sessionStorage.getItem("isNegotiablePrice")) === true) {
        this.uniqTopFilters.push("Prix à débattre");
        this.flgNegotiablePrice = true;
      }
      if (JSON.parse(sessionStorage.getItem("isTopLabel")) === true) {
        this.uniqTopFilters.push("Label Top Renault");
        this.flgLabelTopRenault = true;
      }
      for (let version of this.checkedFiltersArray) {
        if (this.versionList.includes(version) && !this.modelArray.includes(version)) {
          if ( 'version'in sessionStorage) {
            let versionInSession = sessionStorage.getItem('version');
            if (versionInSession === version) {
              this.versionValue = version;
            }
          }

        }
      }

      let checkboxes = document.querySelectorAll("input") as any;
      for (var checkbox of checkboxes) {
        for (var term of this.selected_color_Array) {
          if (term === checkbox.value) {
            $(checkbox).prop("checked", true);
          }
        }
      }


      this.checkedFiltersArray.forEach(term => {

        if (term.includes("km")) {
          this.flagKm = true;
          term = term.replace("km", "");
          term = term.split("-");
          this.sliderForm.value.sliderControl[0] = term[0];
          this.sliderForm.value.sliderControl[1] = term[1];
        }
      });

      this.checkedFiltersArray.forEach(term => {

        if (term.includes("€")) {
          this.flagPrix = true;
          term = term.replace("€", "");
          term = term.split("-");
          this.prixForm.value.prixControl[0] = term[0];
          this.prixForm.value.prixControl[1] = term[1];
        }
      });


      checkboxes.forEach(checkbox => {
        this.checkedFiltersArray.forEach(term => {
          if (term === checkbox.value) {
            if (this.modelArray.includes(term) || this.marqueArray.includes(term) || this.energieArray.includes(term) || this.boiteVitesseArray.includes(term) )
            checkbox.checked = true;
          }
        });
      });
    }


  }

  ngAfterContentChecked() {
    this.updateFilters();
    this.cdRef.detectChanges();

  }

  async ngOnInit() {
    // this.topRenaultservice.currentParamCarFilters.subscribe(async param => {
    this.browserRefresh = browserRefresh;
    this.topRenaultservice.changeIsLoading(true);

    // this.carsData = this.activeRoute.snapshot.data["dataCarsList"];
    await this.getCarData()
    this.carInput = this.carsData;
    // this.topRenaultservice.changeCurrentParamCarFilters(this.carsData);
    // sessionStorage.setItem('onglet', "vehicule-collaborateur");
    this.carSubject.next(this.carsData);
    if (sessionStorage.getItem("facette") == undefined) {
      sessionStorage.setItem("facette", JSON.stringify(this.carsData.facets));
    }

    this.topRenaultservice.getCodeDep().subscribe(list => {
      this.codeDepLists = list;
      if (this.codeDepLists.length == 0) {
        this.codeDepLists = listCodeDepart;
      }
    })
    this.topRenaultservice.carsCountNext$.subscribe(val => {
      this.carsTotalCount = val;
    });

    let totalElement = this.carsData.totalElements;
    this.carsTotalCount = Number(sessionStorage.getItem("carsDataLength"));

    this.facets = JSON.parse(sessionStorage.getItem("facette"));
    this.genereDynamicOption(this.facets);

    if (
      !(totalElement !== 0 &&
        sessionStorage.getItem("flagReseachHomePage") !== "true")
    ) {
      // sessionStorage.get("facette",JSON.stringify(this.carsData.facets));
      // this.facets =JSON.parse(sessionStorage.get("facette"));
      // this.genereDynamicOption(this.facets);
      this.topRenaultservice
        .getfilteredList("")
        .subscribe(data => {
          this.carsData = data;
          this.carsTotalCount = Number(
            sessionStorage.getItem("carsDataLength")
          );
          // this.facets = this.carsData.facets;
          // console.log('facets', this.facets);
          // this.genereDynamicOption(this.facets);
        });
    }
    // else {
    //   // this.topRenaultservice.getfilteredList("locale=fr-FR&channel=main"+ENV.AGGREGATION)

    //   this.topRenaultservice
    //     .getfilteredList("")
    //     .subscribe(data => {
    //       this.carsData = data;
    //       this.carsTotalCount = Number(
    //         sessionStorage.getItem("carsDataLength")
    //       );
    //       // this.facets = this.carsData.facets;
    //       // console.log('facets', this.facets);
    //       // this.genereDynamicOption(this.facets);
    //     });
    // }


    if (
      sessionStorage.getItem("codePostal") !== undefined &&
      sessionStorage.getItem("codePostal") !== "undefined" &&
      sessionStorage.getItem("codePostal") !== null
    ) {
      let codeDepfromHome = JSON.parse(sessionStorage.getItem("codePostal"));
      this.departementArray.push(codeDepfromHome);
      this.uniqTopFilters.push(codeDepfromHome);
      this.checkedFiltersArray.push(codeDepfromHome);
      sessionStorage.setItem("codePostal", undefined);
    }
    let readerCheckedFilterArray = sessionStorage.getItem(
      "checked-filerts-storage"
    );

    if (
      sessionStorage.getItem("searchFilters") == "undefined" &&
      sessionStorage.getItem("searchFilters") !== null &&
      readerCheckedFilterArray !== null &&
      readerCheckedFilterArray[0] !== undefined
    ) {
      this.marqueArray = JSON.parse(sessionStorage.getItem("marqueArray"));
      this.modelArray = JSON.parse(sessionStorage.getItem("modelArray"));
      this.energieArray = JSON.parse(sessionStorage.getItem("energieArray"));
      this.boiteVitesseArray = JSON.parse(
        sessionStorage.getItem("boiteVitesseArray")
      );

      this.couleurArray = JSON.parse(sessionStorage.getItem("couleurArray"));
      this.bvAutomatiqueGlobal = JSON.parse(
        sessionStorage.getItem("bvAutomatiqueGlobal")
      );
      this.bvManualGlobal = JSON.parse(
        sessionStorage.getItem("bvManualGlobal")
      );
    }

    if (
      sessionStorage.getItem("uniqTopFilters") !== undefined &&
      sessionStorage.getItem("uniqTopFilters") !== "undefined" &&
      sessionStorage.getItem("uniqTopFilters") !== null
    ) {
      this.uniqTopFilters = JSON.parse(
        sessionStorage.getItem("uniqTopFilters")
      );
    }

    this.runJS();

    this.flagMarque = JSON.parse(sessionStorage.getItem("flagMarque"));
    this.flagModel = JSON.parse(sessionStorage.getItem("flagModel"));

    if (
      sessionStorage.getItem("flagInventoryGtm") === undefined ||
      sessionStorage.getItem("flagInventoryGtm") === null ||
      sessionStorage.getItem("flagInventoryGtm") === "false"
    ) {
      window.dataLayer.push({
        googleAccount: "UA-19376323-1",
        businessType: "used-car",
        pageType: "inventory",
        optinStatus: undefined,
        loginStatus: undefined,
        countryCode: "FR",
        languageCode: "FR",
        searchFilters: undefined,
        searchTerm: undefined,
        resultNumber: undefined,
        brand: undefined,
        pageName: "top-renault | inventory"
      });
    }

    // if (
    //   sessionStorage.getItem("flagReseach") !== "true" &&
    //   sessionStorage.getItem("flagReseachHomePage") !== "true"
    // ) {
    //   sessionStorage.setItem("flagReseachHomePage", JSON.stringify(false));
    //   this.router.navigate(["liste/updating", { q: "&q=" }]);
    // } else {
    //   this.router.navigate([
    //     "liste/updating",
    //     { q: sessionStorage.getItem("query") }
    //   ]);
    // }

    this.topRenaultservice.filterTerm$.subscribe(term => {
      if (term !== undefined && term !== '') {
        this.uncheckList(term);
      }
    })


    this.topRenaultservice.changeIsLoading(false);
    // })
  }


  removeAllfilters() {
    //Reset All the filters to its inital position
    let q = sessionStorage.getItem("query");
    // sessionStorage.removeItem("query");
    let rediretedArray: string[] = [];
    rediretedArray = JSON.parse(
      sessionStorage.getItem("checked-filerts-storage")
    );
    // sessionStorage.clear();

    this.modelArray = [];
    this.marqueArray = [];
    this.energieArray = [];
    this.boiteVitesseArray = [];
    this.couleurArray = [];
    this.selected_color_Array = [];
    this.departementArray = [];
    this.versionValue = "";

    sessionStorage.removeItem("marqueArray");
    sessionStorage.removeItem("modelArray");
    sessionStorage.removeItem("energieArray");
    sessionStorage.removeItem("couleurArray");
    sessionStorage.removeItem("boiteVitesseArray");
    sessionStorage.removeItem("checked-filerts-storage");
    sessionStorage.removeItem("searchFilters");
    sessionStorage.removeItem("pinCodeFromSession");
    sessionStorage.removeItem("fullCodePostlFromSession");
    sessionStorage.removeItem("genericModels");
    sessionStorage.removeItem("departementArray");



    this.prixForm.value.prixControl[0] = this.minPrixInitial;
    this.prixForm.value.prixControl[1] = this.maxPrixInitial;
    this.optionsPrix.floor = this.minPrixInitial;
    this.optionsPrix.ceil = this.maxPrixInitial;

    this.sliderForm.value.sliderControl[0] = this.minKmInitial;
    this.sliderForm.value.sliderControl[1] = this.maxKmInitial;
    this.optionsKm.floor = this.minKmInitial;
    this.optionsKm.ceil = this.maxKmInitial;
    this.flagKm = true;
    this.flagPrix = true;
    this.checkedFiltersArray = [];
    rediretedArray = [];
    let checkboxes = document.querySelectorAll("input") as NodeListOf<
      HTMLInputElement
    >;

    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });
    this.uniqTopFilters = [];
    this.flgLabelTopRenault = false;
    this.flgNegotiablePrice = false;
    this.filter();
    // this.router.navigate(["liste/updating", { q: "" }]);
  }

  prixForm: FormGroup = new FormGroup({
    prixControl: new FormControl([0, 400000])
  });

  optionsPrix: Options = {
    floor: 0,
    ceil: 50000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<span class="min">min : </span> ' + value + " € ";
        case LabelType.High:
          return '<span class="max">max : </span>' + value + " € ";
        default:
          return value + "€";
      }
    }
  };

  sliderForm: FormGroup = new FormGroup({
    sliderControl: new FormControl([0, 800000])
  });

  optionsKm: Options = {
    floor: 0,
    ceil: 800000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<span class="min">min : </span> ' + value + " km ";
        case LabelType.High:
          return '<span class="max">max : </span>' + value + " km ";
        default:
          return value + " km";
      }
    }
  };

  getCodePostal() {
    if (!this.noDepartment) {
      let searchinput = document.getElementById("searchloc") as HTMLInputElement;
      searchinput.blur();
      this.search(this.codeDep);

    }
  };

  closeTabs() {
    let elt = document.getElementById("Section1");
    let li = document.getElementById("mobile-nav-tab1");
    let alink = document.getElementById("mobile-nav-a1");
    alink.classList.remove("active");
    let changeIcon = document.getElementById("mobile-icon1");
    changeIcon.classList.remove("rotate");
    elt.classList.remove("active");
    elt.classList.remove("in");
    li.classList.remove("active");
  }

  priceChange(changeContext: ChangeContext, type) {

    this.getChangeContextString(changeContext, type);

    this.filter();
  }

  genereDynamicOption(facets) {
    // console.log('facets', facets);
    for (let facet of facets) {
      // this.genereDynamicCodeDep(facet);
      if (
        facet.name === "topRenaultAd.mileage_min" &&
        this.flagReseach === false
      ) {
        if (
          JSON.parse(sessionStorage.getItem("minKm")) !== undefined &&
          JSON.parse(sessionStorage.getItem("minKm")) !== null
        ) {
          this.minKm = JSON.parse(sessionStorage.getItem("minKm"));
        } else {
          this.minKm = facet.min.value;
        }
        this.optionsKm.floor = this.minKm;
        this.minKmInitial = this.minKm;
        sessionStorage.setItem("minPrix", JSON.stringify(null));
      } else if (
        facet.name === "topRenaultAd.mileage_max" &&
        this.flagReseach === false
      ) {
        if (
          JSON.parse(sessionStorage.getItem("maxKm")) !== undefined &&
          JSON.parse(sessionStorage.getItem("maxKm")) !== null
        ) {
          this.maxKm = JSON.parse(sessionStorage.getItem("maxKm"));
        } else {
          this.maxKm = facet.max.value;
        }
        this.optionsKm.ceil = this.maxKm;
        this.maxKmInitial = this.maxKm;
        sessionStorage.setItem("maxKm", JSON.stringify(null));
      } else if (
        facet.name === "prices.discountedPriceWithTaxes_min" &&
        this.flagReseach === false
      ) {
        if (
          JSON.parse(sessionStorage.getItem("minPrix")) !== undefined &&
          JSON.parse(sessionStorage.getItem("minPrix")) !== null
        ) {
          this.minPrix = JSON.parse(sessionStorage.getItem("minPrix"));
        } else {
          this.minPrix = facet.min.value;
        }
        this.optionsPrix.floor = this.minPrix;
        this.minPrixInitial = this.minPrix;
        sessionStorage.setItem("minPrix", JSON.stringify(null));
      } else if (
        facet.name === "prices.discountedPriceWithTaxes_max" &&
        this.flagReseach === false
      ) {
        if (
          JSON.parse(sessionStorage.getItem("maxPrix")) !== undefined &&
          JSON.parse(sessionStorage.getItem("maxPrix")) !== null
        ) {
          this.maxPrix = JSON.parse(sessionStorage.getItem("maxPrix"));
        } else {
          this.maxPrix = facet.max.value;
        }
        this.optionsPrix.ceil = this.maxPrix;
        this.maxPrixInitial = this.maxPrix;
        sessionStorage.setItem("maxPrix", JSON.stringify(null));
      } else if (
        facet.name === "brand.label.raw_terms" &&
        this.flagUpdateModel === true &&
        this.flagReseach === false
      ) {
        this.marqueList = facet.terms;
      } else if (
        facet.name === "model.label.raw_terms" &&
        this.flagReseachModels === false
      ) {
        // if (
        //   sessionStorage.getItem("searchFilters") === "undefined" &&
        //   sessionStorage.getItem("searchFilters") !== null
        // ) {
        //   this.genericModelsArray = JSON.parse(
        //     sessionStorage.getItem("genericModels")
        //   );
        // }
        this.modeleList = facet.terms;
        console.log('modele list', this.modeleList);
        this.modelListGlobal = facet.terms;
        let tempGenericModelArray = [];
        this.modeleList.forEach(modelObj => {
          if (
            modelObj.term.toUpperCase().includes("NOUVEAU") ||
            modelObj.term.toUpperCase().includes("NOUVELLE")
            || modelObj.term.toUpperCase().includes("NOUVEL")
          ) {
            let model = modelObj.term.split(" ");
            let newModelObj = {
              count: modelObj.count,
              term: model[1]
            };
            tempGenericModelArray.push(model[1]);
          } else {
            tempGenericModelArray.push(modelObj.term);
          }
        });

        tempGenericModelArray = [...new Set(tempGenericModelArray)];

        tempGenericModelArray = tempGenericModelArray.sort();
        this.genericModelsArray = tempGenericModelArray;
        console.log('list', this.genericModelsArray);
        // this.genericModelsArray = this.genericModelsArray.map(model => model.toUpperCase());
        this.genericModelsArray = [...new Set(this.genericModelsArray)];

        sessionStorage.setItem(
          "genericModels",
          JSON.stringify(this.genericModelsArray)
        );
        this.genericRepeatsList = this.modeleList.filter(
          modelObj =>
            modelObj.term.toUpperCase().includes("NOUVEAU") ||
            modelObj.term.toUpperCase().includes("NOUVELLE")
            || modelObj.term.toUpperCase().includes("NOUVEL")
        );
        let tempGenericRpeatsArray = this.genericRepeatsList.map(modelObj =>
          modelObj.term.toUpperCase()
        );
        this.genericRepeatsList = [...tempGenericRpeatsArray];
        console.log('this.genericRepeatsList ', this.genericRepeatsList);
      } else if (
        facet.name === "colorMarketing.hexaCode_terms" &&
        this.flagReseach === false
      ) {
        this.getColorListInFacette(facet.terms);
      } else if (
        facet.name === "energy.label.raw_terms" &&
        this.flagReseach === false
      ) {
        this.energieList = facet.terms;
      } else if (
        facet.name === "transmission.label.raw_terms" &&
        this.flagReseach === false
      ) {
        this.boiteVitesseList = facet.terms;
        let tempBoitedeVitesseArray = [];
        this.boiteVitesseList.forEach(bvType => {
          if (
            (bvType.term.toUpperCase().includes("AUTOMATIQUE") ||
              bvType.term.toUpperCase().includes("MANUELLE")) && !bvType.term.toUpperCase().includes("E-TECH")
          ) {
            tempBoitedeVitesseArray.push(bvType);
          }
        });
        this.genericBoitedeVitesseArray = [...tempBoitedeVitesseArray];
        console.log("gear box array", this.genericBoitedeVitesseArray);
      } else if (facet.name === "version.label.raw_terms") {
        this.getVersionListInfacette(facet.terms);
      }
    }
    this.flagVersion = false;
    sessionStorage.setItem("flagReseachHomePage", "false");
  }

  toggleModeles() {
    this.modelIcon = !this.modelIcon;
  }
  runJS() {
    if (document.getElementById("topRenault")) {
      document.getElementById("topRenault").remove();
    }

    let topRenault = document.createElement("script");
    topRenault.setAttribute("id", "topRenault");
    topRenault.setAttribute("src", "assets/js/script.js");
    document.body.appendChild(topRenault);
  }

  checkedFunction(e, type) {
    //Marques
    this.topRenaultservice.changeIsLoading(true);
    if (e.target.checked && type === "marque") {
      sessionStorage.setItem("flagMarque", "true");
      if (this.modelArray.indexOf(e.target.value) === -1) {
        this.uniqTopFilters.push(e.target.value);
        this.marqueArray.push(e.target.value);
      }
    }
    //Models
    if (e.target.checked && type === "model") {
      sessionStorage.setItem("flagModel", "true");
      this.uniqTopFilters.push(e.target.value);
      // if (this.modelArray.indexOf(e.target.value) === -1) {
      this.modelListGlobal.forEach(globalModel => {
        if (e.target.value != undefined &&
          ((globalModel.term.toUpperCase().includes(e.target.value.toUpperCase())
            && (globalModel.term.toUpperCase().includes("NOUVEAU") ||
              globalModel.term.toUpperCase().includes("NOUVELLE")
              || globalModel.term.toUpperCase().includes("NOUVEL"))
          ) || globalModel.term.toUpperCase() == e.target.value.toUpperCase())
        ) {
          this.modelArray.push(globalModel.term);
          this.modelArray.push(e.target.value);
          console.log('modelArray', this.modelArray);
        }
      });
      this.modelArray = [...new Set(this.modelArray)];
      // }
    }
    //Energie
    if (e.target.checked && type === "energie") {
      this.energieArray = [...new Set(this.energieArray)];
      if (this.energieArray.indexOf(e.target.value) === -1) {
        this.uniqTopFilters.push(e.target.value);
        return this.energieArray.push(e.target.value);
      }
    }
    //Couleur
    if (e.target.checked && type === "couleur") {
      if (this.couleurArray.indexOf(e.target.value) === -1) {
        this.uniqTopFilters.push(e.target.value);
        return this.couleurArray.push(e.target.value);
      }
    }
    // Boite de vitesse
    if (e.target.checked && type === "boiteVitesse") {
      console.log('checked boit de vitesse', this.boiteVitesseArray);
      this.uniqTopFilters.push(e.target.value);

      if (this.boiteVitesseArray.indexOf(e.target.value) === -1) {
        let tempBvArray = [];

        if (e.target.value.toLowerCase().includes("automatique")) {
          this.boiteVitesseList.forEach(bvObj => {
            if (!bvObj.term.toLowerCase().includes("manuelle")) {
              tempBvArray.push(bvObj.term);
              this.bvAutomatiqueGlobal.push(bvObj.term);
            }
          });
          this.bvAutomatiqueGlobal = [...new Set(this.bvAutomatiqueGlobal)];
        }
        if (e.target.value.toLowerCase().includes("manuelle")) {
          this.boiteVitesseList.forEach(bvObj => {
            if (bvObj.term.toLowerCase().includes("manuelle")) {
              tempBvArray.push(bvObj.term);
              this.bvManualGlobal.push(bvObj.term);
            }
          });
          this.bvManualGlobal = [...new Set(this.bvManualGlobal)];
        }
        this.boiteVitesseArray = this.boiteVitesseArray.concat(tempBvArray);
        this.boiteVitesseArray = [...new Set(this.boiteVitesseArray)];
      }
    }
    //Label top Renault
    if (e.target.checked && type === "Label Top Renault") {
      this.flgLabelTopRenault = true;
      this.uniqTopFilters.push(e.target.value);
      sessionStorage.setItem('isTopLabel', JSON.stringify(true))
      return this.flgLabelTopRenault;
    }
    //Negotiable price
    if (e.target.checked && type === "NegotiablePrice") {
      this.flgNegotiablePrice = true;
      this.uniqTopFilters.push(e.target.value);
      sessionStorage.setItem('isNegotiablePrice', JSON.stringify(true))
      return this.flgNegotiablePrice;
    }
    let array = this.modelArray.concat(this.marqueArray).concat(this.boiteVitesseArray);
    this.topRenaultservice.setSelectedFilters(array);
  }

  uncheckedFunction(e, type) {
    //Remove unchecked marques
    this.topRenaultservice.changeIsLoading(true);
    if (!e.target.checked && type === "marque") {
      if (this.marqueArray.length <= 1) {
        sessionStorage.setItem("flagMarque", "false");
        this.flagReseachMarques = false;
        this.flagReseachModels = false;
      }
      this.uniqTopFilters = this.uniqTopFilters.filter(
        marque => marque !== e.target.value
      );
      let index = this.marqueArray.indexOf(e.target.value);
      if (index > -1) {
        return this.marqueArray.splice(index, 1);
      }
    }
    //Remove unchecked models
    if (!e.target.checked && type === "model") {
      if (this.modelArray.length <= 1) {
        sessionStorage.setItem("flagModel", "false");
      }
      let index = this.modelArray.indexOf(e.target.value);
      let modelinRepeats = this.genericRepeatsList.find(repeatedModel =>
        repeatedModel.includes(e.target.value)
      );
      if (index > -1) {
        // this.modelArray.splice(index, 1);
        this.modelArray = this.modelArray.filter(model => !
          (
            (model.toUpperCase().includes(e.target.value.toUpperCase())
              && (model.toUpperCase().toUpperCase().includes("NOUVEAU") ||
                model.toUpperCase().toUpperCase().includes("NOUVELLE")
                || model.toUpperCase().toUpperCase().includes("NOUVEL"))
              || (model.toUpperCase() == e.target.value.toUpperCase()))))
        this.uniqTopFilters = this.uniqTopFilters.filter(
          model => model !== e.target.value
        );

        if (modelinRepeats != undefined && modelinRepeats != "undefined") {
          this.modelArray = this.modelArray.filter(
            model => model.toUpperCase() !== modelinRepeats
          );
          this.uniqTopFilters = this.uniqTopFilters.filter(
            model => model !== modelinRepeats
          );
        }
      }
    }
    //Remove unchecked energie types
    if (!e.target.checked && type === "energie") {
      let index = this.energieArray.indexOf(e.target.value);
      if (index > -1) {
        this.uniqTopFilters = this.uniqTopFilters.filter(
          energieType => energieType !== e.target.value
        );
        return this.energieArray.splice(index, 1);
      }
    }
    //Remove unchecked couleurs
    if (!e.target.checked && type === "couleur") {
      let index = this.couleurArray.indexOf(e.target.value);
      if (index > -1) {
        return this.couleurArray.splice(index, 1);
      }
    }
    //Remove unchecked boiteVitesse types
    if (!e.target.checked && type === "boiteVitesse") {
      let tempArray = [];
      if (e.target.value.toLowerCase().includes("automatique")) {
        this.boiteVitesseArray.forEach(term => {
          if (!this.bvAutomatiqueGlobal.includes(term)) {
            tempArray.push(term);
          }
        });
        this.boiteVitesseArray = tempArray;
        this.uniqTopFilters = this.uniqTopFilters.filter(
          bvType => bvType !== e.target.value
        );
      }

      if (e.target.value.toLowerCase().includes("manuelle")) {
        this.boiteVitesseArray.forEach(term => {
          if (!this.bvManualGlobal.includes(term)) {
            tempArray.push(term);
          }
        });
        this.boiteVitesseArray = tempArray;
        this.uniqTopFilters = this.uniqTopFilters.filter(
          bvType => bvType !== e.target.value
        );
      }
    }

    //Label top Renault
    if (!e.target.checked && type === "Label Top Renault") {
      this.flgLabelTopRenault = false;
      this.uniqTopFilters = this.uniqTopFilters.filter(
        label => label !== e.target.value
      );
      sessionStorage.setItem('isTopLabel', JSON.stringify(false))
      return this.flgLabelTopRenault;
    }
    //Negotiable price
    if (!e.target.checked && type === "NegotiablePrice") {
      this.flgNegotiablePrice = false;
      this.uniqTopFilters = this.uniqTopFilters.filter(
        prix => prix !== e.target.value
      );
      sessionStorage.setItem('isNegotiablePrice', JSON.stringify(false))
      return this.flgNegotiablePrice;
    }

  }

  checkedSearchQueryParamHomePage() {
    if (
      (JSON.parse(sessionStorage.getItem("minPrix")) !== undefined &&
        JSON.parse(sessionStorage.getItem("minPrix")) !== null) ||
      (JSON.parse(sessionStorage.getItem("maxPrix")) !== undefined &&
        JSON.parse(sessionStorage.getItem("maxPrix")) !== null)
    ) {
      this.optionsPrix.floor = JSON.parse(sessionStorage.getItem("minPrix"));
      this.optionsPrix.ceil = JSON.parse(sessionStorage.getItem("maxPrix"));
    }
    if (
      (JSON.parse(sessionStorage.getItem("minKm")) !== undefined &&
        JSON.parse(sessionStorage.getItem("minKm")) !== null) ||
      (JSON.parse(sessionStorage.getItem("maxKm")) !== undefined &&
        JSON.parse(sessionStorage.getItem("maxKm")) !== null)
    ) {
      this.optionsKm.floor = JSON.parse(sessionStorage.getItem("minKm"));
      this.optionsKm.ceil = JSON.parse(sessionStorage.getItem("maxKm"));
    }
    if (
      JSON.parse(sessionStorage.getItem("codePostal")) !== undefined &&
      JSON.parse(sessionStorage.getItem("codePostal")) !== null
    ) {
      this.code_postale = JSON.parse(sessionStorage.getItem("codePostal"));
    }
  }

  uncheckListDivided(array, term) {
    if (array.includes(term) && term !== null) {
      let index = array.indexOf(term);
      if (index > -1) {
        array.splice(index, 1);
        let checkboxes = document.querySelectorAll(
          "#filter-form input:checked"
        ) as NodeListOf<HTMLInputElement>;
        checkboxes.forEach(checkBox => {
          if (checkBox.value === term && checkBox.checked === true) {
            checkBox.checked = false;
          }
        });
        this.cdRef.detectChanges();
        this.filter();
      }
    }
  }

  removeModelsfromArray(term) {
    let tempModelsArray = [];
    this.modelArray.forEach(modelName => {
      let upperCasedTerm = modelName.toUpperCase();
      if (!upperCasedTerm.includes(term)) {
        tempModelsArray.push(modelName);
      }
    });

    this.modelArray = [...tempModelsArray];
    let checkboxes = document.querySelectorAll(
      "#filter-form input:checked"
    ) as NodeListOf<HTMLInputElement>;

    checkboxes.forEach(checkBox => {
      if (checkBox.value === term && checkBox.checked === true) {
        checkBox.checked = false;
      }
    });

    this.cdRef.detectChanges();
    this.filter();
  }

  removeMaquesfromArray(term) {
    let tempArray = [];
    this.marqueArray.forEach(marqueName => {
      if (!marqueName.includes(term)) {
        tempArray.push(marqueName);
      }
    });
    this.marqueArray = [...tempArray];
    let checkboxes = document.querySelectorAll(
      "#filter-form input:checked"
    ) as NodeListOf<HTMLInputElement>;
    checkboxes.forEach(checkBox => {
      if (checkBox.value === term && checkBox.checked === true) {
        checkBox.checked = false;
      }
    });
    this.cdRef.detectChanges();
    this.filter();
  }


  //function to Remove filters value when clicked on top croix(X ICON)
  uncheckList(term) {
    // remove if the departments
    if (this.departementArray.includes(term)) {
      this.departementArray = this.departementArray.filter(dept => term !== dept);
      this.uniqTopFilters = this.uniqTopFilters.filter(dept => dept !== term);
    };
    if (this.modelArray.includes(term)) {
      console.log('model term', term);
      //find the index of the model in the array
      let index = this.modelArray.indexOf(term);

      //find the modelName assosicated with NOUVELLE OR NOUVEAU
      console.log('index', index);
      console.log('modelsRepeatsList', this.genericRepeatsList);
      let modelinRepeats = this.genericRepeatsList.find(repeatedModel =>
        repeatedModel.includes(term.toUpperCase())
      );
      console.log('modelArray', this.modelArray);
      console.log('modelInrepeats', modelinRepeats);
      if (index > -1) {
        this.modelArray.splice(index, 1);
        this.uniqTopFilters = this.uniqTopFilters.filter(
          model => model !== term
        );

        // if there any modelName Associated with NOUVELLE OR NOUVEAU delete it.
        if (modelinRepeats != undefined && modelinRepeats != "undefined") {
          this.modelArray = this.modelArray.filter(
            model => model.toUpperCase() !== modelinRepeats
          );
          this.uniqTopFilters = this.uniqTopFilters.filter(
            model => model !== modelinRepeats
          );
        }
      }

      //get all the checked filters from the DOM
      let checkboxes = document.querySelectorAll(
        "#filter-form input:checked"
      ) as NodeListOf<HTMLInputElement>;

      //Iterrate the checked checkboxes and uncheck the model input checkbox
      checkboxes.forEach(checkBox => {
        if (checkBox.value === term && checkBox.checked === true) {
          checkBox.checked = false;
        }
      });
    }

    //Remove MARQUES from the top filters clicked on top croix
    if (this.marqueArray.includes(term)) {
      this.removeMaquesfromArray(term);
    } else {
      this.removeMaquesfromArray(term);
    }

    //Remove BV from the top filters clicked on top croix
    if (this.boiteVitesseArray.includes(term)) {
      let tempArray = [];
      if (term.toLowerCase().includes("automatique")) {
        this.boiteVitesseArray.forEach(term => {
          if (!this.bvAutomatiqueGlobal.includes(term)) {
            tempArray.push(term);
          }
        });
        this.boiteVitesseArray = tempArray;

        let checkboxes = document.querySelectorAll(
          "#filter-form input:checked"
        ) as NodeListOf<HTMLInputElement>;

        checkboxes.forEach(checkBox => {
          if (checkBox.value === term && checkBox.checked === true) {
            checkBox.checked = false;
          }
        });
        this.cdRef.detectChanges();
        this.filter();
      }

      if (term.toUpperCase().includes("MANUELLE")) {
        this.boiteVitesseArray.forEach(term => {
          if (!this.bvManualGlobal.includes(term)) {
            tempArray.push(term);
          }
        });
        this.boiteVitesseArray = tempArray;

        let checkboxes = document.querySelectorAll(
          "#filter-form input:checked"
        ) as NodeListOf<HTMLInputElement>;

        checkboxes.forEach(checkBox => {
          if (checkBox.value === term && checkBox.checked === true) {
            checkBox.checked = false;
          }
        });
        this.cdRef.detectChanges();
        this.filter();
      }
    }

    this.uncheckListDivided(this.energieArray, term);
    // this.uncheckListDivided(this.boiteVitesseArray,term);
    this.uncheckListDivided(this.couleurArray, term);

    if (
      term.includes("€") &&
      this.prixUncheckedFlag &&
      term !== this.fullCodePostal &&
      term !== this.versionValue &&
      term !== null
    ) {
      this.flagPrix = true;
      // let index = this.checkedFiltersArray.includes(term);
      //  if (index) {
      this.minPrix = this.minPrixInitial;
      this.maxPrix = this.maxPrixInitial;
      this.prixForm.value.prixControl[0] = this.minPrixInitial;
      this.prixForm.value.prixControl[1] = this.maxPrixInitial;
      this.checkedFiltersArray = this.checkedFiltersArray.filter(
        x => x !== term
      );
      this.prixUncheckedFlag = false;
      this.filter();
      this.prixUncheckedFlag = true;
      // }
    } else if (
      term.includes("km") &&
      this.kmUncheckedFlag &&
      term !== this.fullCodePostal &&
      term !== this.versionValue &&
      term !== null
    ) {
      this.flagKm = true;
      // let index = this.checkedFiltersArray.indexOf(term);
      // if (index > -1) {
      this.minKm = this.minKmInitial;
      this.maxKm = this.maxKmInitial;
      this.sliderForm.value.sliderControl[0] = this.minKmInitial;
      this.sliderForm.value.sliderControl[1] = this.maxKmInitial;
      this.checkedFiltersArray = this.checkedFiltersArray.filter(
        x => x !== term
      );
      this.kmUncheckedFlag = false;
      this.filter();
      this.kmUncheckedFlag = true;
      // }
    } else if (
      term === this.versionValue &&
      term !== this.fullCodePostal &&
      term !== null
    ) {
      this.unselectVersion("desktop");
    } else if (term === "Label Top Renault" && this.uniqTopFilters.includes(term)) {
      this.flgLabelTopRenault = false;
      this.uniqTopFilters = this.uniqTopFilters.filter(
        label => label !== term
      );
      sessionStorage.setItem('isTopLabel', JSON.stringify(false));
      this.filter();
    } else if (term === "Prix à débattre" && this.uniqTopFilters.includes(term)) {
      this.flgNegotiablePrice = false;
      this.uniqTopFilters = this.uniqTopFilters.filter(
        prix => term !== prix
      );
      sessionStorage.setItem('isNegotiablePrice', JSON.stringify(false));
      this.filter();
    }
  }


  unselectDepartment(departement) {
    this.departementArray = this.departementArray.filter(dept => departement !== dept);
    this.uniqTopFilters = this.uniqTopFilters.filter(dept => dept !== departement);
    console.log(this.departementArray);
    this.filter();
  }

  searchByDepartment(val): void {
    if (!this.noDepartment) {
      this.departementArray.push(val);
      this.departementArray = [...new Set(this.departementArray)];
      this.uniqTopFilters.push(val);
      this.filter();
      this.codeDep = "";
      let searchinput = document.getElementById("searchloc") as HTMLInputElement;
      let searchinputmobile = document.getElementById("searchlocmobile") as HTMLInputElement;
      if (searchinput != null) {
        searchinput.blur();
      }
      if (searchinputmobile! = null) {
        searchinputmobile.blur();
      }
    }
  }

  hideIcon(type) {
    let locationIconDesktop = document.getElementById("loc-desktop-icon");
    let locationIconMobile = document.getElementById("loc-mobile-icon");
    if (type === "location-desktop") {
      locationIconDesktop.style.display = "none";
    } else {
      locationIconMobile.style.display = "none";
    }
  }
  showIcon(type) {
    let iconDesktop = document.getElementById("loc-desktop-icon");
    let iconMobile = document.getElementById("loc-mobile-icon");
    if (type === "location-desktop" && iconDesktop) {
      if (this.codeDep !== undefined && this.codeDep.length) {
        iconDesktop.style.display = "none";
      } else {
        iconDesktop.style.display = "block";
      }
    }

    if (type === "location-mobile" && iconMobile) {
      if (this.codeDep !== undefined && this.codeDep.length) {
        iconMobile.style.display = "none";
      } else {
        iconMobile.style.display = "block";
      }
    }
  }
  hideIconVersion(type) {
    let versionIconDesktop = document.getElementById("version-desktop-icon");
    let versionIconMobile = document.getElementById("version-mobile-icon");
    if (type === "version-desktop") {
      versionIconDesktop.style.display = "none";
    } else {
      versionIconMobile.style.display = "none";
    }
  }

  showIconVersion(type) {
    let iconDesktop = document.getElementById("version-desktop-icon");
    let iconMobile = document.getElementById("version-mobile-icon");

    if (type === "version-desktop") {
      if (this.versionValue !== undefined && this.versionValue.length) {
        iconDesktop.style.display = "none";
      } else {
        iconDesktop.style.display = "block";
      }
    }

    if (type === "version-mobile") {
      if (this.versionValue !== undefined && this.versionValue.length) {
        iconMobile.style.display = "none";
      } else {
        iconMobile.style.display = "block";
      }
    }
  }

  toggleClass(val) {
    switch (val) {
      case "one":
        this.isCollapsed1 = !this.isCollapsed1;
        break;
      case "two":
        this.isCollapsed2 = !this.isCollapsed2;
        break;
      case "three":
        this.isCollapsed3 = !this.isCollapsed3;
        break;
      case "four":
        this.isCollapsed4 = !this.isCollapsed4;
        break;
      case "five":
        this.isCollapsed5 = !this.isCollapsed5;
        break;
      case "six":
        this.isCollapsed6 = !this.isCollapsed6;
        break;
      case "seven":
        this.isCollapsed7 = !this.isCollapsed7;
        break;
      case "eight":
        this.isCollapsed8 = !this.isCollapsed8;
        break;
      case "nine":
        this.isCollapsed9 = !this.isCollapsed9;
        break;
      case "ten":
        this.isCollapsed10 = !this.isCollapsed10;
        break;
      case "eleven":
        this.isCollapsed11 = !this.isCollapsed11;
        break;
      default:
        this.isCollapsed1 = !this.isCollapsed1;
    }
  }
  filterList(e, type) {
    this.topRenaultservice.changeIsLoading(true);
    //checked function push to array
    this.checkedFunction(e, type);
    //unchecked function remove from array
    this.uncheckedFunction(e, type);
    this.filter();
  }

  search(codeDep) {
    // this.departementArray.push(this.codeDep);
    this.codeDep = "";
    this.codeDep = codeDep;
    this.filter();
  }
  removeFullTextSearchQuery() {
    if (sessionStorage.removeItem("full-Text") != undefined) {
      sessionStorage.removeItem("full-Text")
    };
  }

  filter() {
    this.removeFullTextSearchQuery();
    this.topRenaultservice.setPageSize(9);
    this.isLoading = true;
    let params = "&q=";
    let paramsFilter = "&q=";
    let codeDep = "";
    let filterGTM = "";
    let version = "";
    this.flagReseachMarques = true;
    let flagModelNotUpdate = true;
    // this.checkedFiltersArray.length>0 ? this.available === true :  this.available === false;
    //Storage the Array of the filter

    sessionStorage.setItem("marqueArray", JSON.stringify(this.marqueArray));
    sessionStorage.setItem("departementArray", JSON.stringify(this.departementArray));
    sessionStorage.setItem("modelArray", JSON.stringify(this.modelArray));
    sessionStorage.setItem("energieArray", JSON.stringify(this.energieArray));
    sessionStorage.setItem("couleurArray", JSON.stringify(this.couleurArray));
    sessionStorage.setItem("boiteVitesseArray", JSON.stringify(this.boiteVitesseArray));
    sessionStorage.setItem("bvAutomatiqueGlobal", JSON.stringify(this.bvAutomatiqueGlobal));
    sessionStorage.setItem("bvManualGlobal", JSON.stringify(this.bvManualGlobal));
    this.uniqTopFilters = [...new Set(this.uniqTopFilters)];
    sessionStorage.setItem("uniqTopFilters", JSON.stringify(this.uniqTopFilters));

    //conditions sur le model
    if (this.marqueArray.length > 1) {
      sessionStorage.setItem("flagMarque", JSON.stringify(true));
      filterGTM += this.marqueArray + "|";

      for (let elt of this.marqueArray) {
        this.marqueValues = this.marqueArray.join(",");
      }
      this.marqueStr = "brand.label.raw=in=(" + this.marqueValues + ")" + ";";
      this.flagReseachModels = false;
      this.flagReseach = false;
      this.flagUpdateModel = false;
      // flagModelNotUpdate = true;
    } else if (this.marqueArray.length === 1) {
      sessionStorage.setItem("flagMarque", JSON.stringify(true));
      this.marqueStr = "brand.label.raw==" + this.marqueArray[0] + ";";
      this.flagReseachModels = false;
      filterGTM += this.marqueArray[0] + "|";
      this.flagReseach = false;
      // flagModelNotUpdate = true;
      this.flagUpdateModel = false;
    } else {
      this.marqueStr = "";
      // flagModelNotUpdate = true;
      this.flagUpdateModel = false;
    }
    //conditions sur le model
    if (this.modelArray.length > 1) {
      console.log('slecetd model', )
      filterGTM += this.modelArray + "|";
      sessionStorage.setItem("flagModel", JSON.stringify(true));
      let models = this.prepareQuery(this.modelArray);
      this.modelStr = "model.label.raw=in=(" +models+ ")" + ";";
      this.flagReseachModels = true;
      this.flagReseach = false;
      flagModelNotUpdate = false;
    } else if (this.modelArray.length === 1) {
      filterGTM += this.modelArray[0] + "|";
      sessionStorage.setItem("flagModel", JSON.stringify(true));
       let model = `'${this.modelArray[0]}'`;
      this.modelStr = "model.label.raw==" + encodeURI(model) + ";";
      this.flagReseachModels = true;
      this.flagReseach = false;
      flagModelNotUpdate = false;
    } else {
      this.modelStr = "";
      flagModelNotUpdate = false;
    }

    //conditions sur l'energy
    if (this.energieArray.length > 1) {
      filterGTM += this.energieArray + "|";
      sessionStorage.setItem("flagEnergy", JSON.stringify(true));
      let energies = this.prepareQuery(this.energieArray);
      this.energieStr = "energy.label.raw=in=(" + energies + ")" + ";";
      this.flagReseach = true;
      flagModelNotUpdate = false;
    } else if (this.energieArray.length === 1) {
      filterGTM += this.energieArray[0] + "|";
      sessionStorage.setItem("flagEnergy", JSON.stringify(true));
      let elt = this.energieArray[0].trim();
      this.energieStr = "energy.label.raw==" + "'" + elt + "'" + ";";

      this.flagReseach = true;
      flagModelNotUpdate = false;
    } else {
      this.energieStr = "";
      flagModelNotUpdate = false;
    }

    //conditions sur le couleur
    if (this.couleurArray.length > 1) {
      sessionStorage.setItem("flagColor", JSON.stringify(true));
      for (let elt of this.couleurArray) {
        this.couleurValues = this.couleurArray.join(",");
      }
      this.couleurStr =
        "colorMarketing.hexaCode=in=(" + this.couleurValues + ")" + ";";
      this.flagReseach = true;
      flagModelNotUpdate = false;
    } else if (this.couleurArray.length === 1 && this.couleurArray[0] !== "") {
      sessionStorage.setItem("flagColor", JSON.stringify(true));
      this.couleurStr =
        "colorMarketing.hexaCode==" + this.couleurArray[0] + ";";
      this.flagReseach = true;
      flagModelNotUpdate = false;
    } else {
      this.couleurStr = "";
      flagModelNotUpdate = false;
    }

    //conditions sur le boite à vitesse
    if (this.boiteVitesseArray.length > 1) {
      filterGTM += this.boiteVitesseArray + "|";
      sessionStorage.setItem("flagBoiteVitesse", JSON.stringify(true));
      let boiteVitesse = this.prepareQuery(this.boiteVitesseArray);
      this.boiteVitesseStr =
        "transmission.label.raw=in=(" + boiteVitesse + ")" + ";";
      this.flagReseach = true;
      flagModelNotUpdate = false;
    } else if (
      this.boiteVitesseArray.length === 1 &&
      this.boiteVitesseArray[0] !== ""
    ) {
      filterGTM += this.boiteVitesseArray[0] + "|";
      sessionStorage.setItem("flagBoiteVitesse", JSON.stringify(true));
      let boiteVitesse = this.prepareQueryFullText(this.boiteVitesseArray[0]);
      this.boiteVitesseStr = "transmission.label.raw==" + boiteVitesse + ";";
      this.flagReseach = true;
      flagModelNotUpdate = false;
    } else {
      this.boiteVitesseStr = "";
      flagModelNotUpdate = false;
    }
    if (
      this.flagPrix === true &&
      (this.prixForm.value.prixControl[0] !== 0 ||
        this.prixForm.value.prixControl[1] !== 50000)
    ) {
      filterGTM +=
        this.prixForm.value.prixControl[0] +
        "-" +
        this.prixForm.value.prixControl[1] +
        "€ |";
      sessionStorage.setItem("flagPrix", JSON.stringify(true));
      this.prixStr =
        "prices.discountedPriceWithTaxes>=" +
        this.prixForm.value.prixControl[0] +
        ";prices.discountedPriceWithTaxes<=" +
        this.prixForm.value.prixControl[1] +
        ";";
      this.flagReseach = true;
      flagModelNotUpdate = false;
      let filters = this.topRenaultservice.selectedFilters.getValue();
      console.log('filters', filters);
      let price = this.prixForm.value.prixControl[0] + "-" +
        this.prixForm.value.prixControl[1] + " € ";

      if (filters.includes('€')) {
        filters = filters.filter(term => !term.includes('€'));
        filters.push(price);
        this.topRenaultservice.setSelectedFilters(filters);
      } else {
        filters.push(price);
        this.topRenaultservice.setSelectedFilters(filters);
      }

    }

    if (
      this.flagKm === true &&
      (this.sliderForm.value.sliderControl[0] !== 0 ||
        this.sliderForm.value.sliderControl[1] !== 800000)
    ) {
      filterGTM +=
        this.sliderForm.value.sliderControl[0] +
        "-" +
        this.sliderForm.value.sliderControl[1] +
        " km |";
      sessionStorage.setItem("flagKm", JSON.stringify(true));
      this.kmStr =
        "topRenaultAd.mileage>=" +
        this.sliderForm.value.sliderControl[0] +
        ";topRenaultAd.mileage<=" +
        this.sliderForm.value.sliderControl[1] +
        ";";
      this.flagReseach = true;
      flagModelNotUpdate = false;


      let filters = this.topRenaultservice.selectedFilters.getValue();
      console.log('filters', filters);
      let price = this.sliderForm.value.sliderControl[0] + "-" +
        this.sliderForm.value.sliderControl[1] + " km ";

      if (filters.includes('km')) {
        filters = filters.filter(term => !term.includes('km'));
        filters.push(price);
        this.topRenaultservice.setSelectedFilters(filters);
      } else {
        filters.push(price);
        this.topRenaultservice.setSelectedFilters(filters);
      }
    }

    //conditions sur le code departement
    if (this.departementArray.length > 1) {
      filterGTM += this.departementArray + "|";
      sessionStorage.setItem("flagCodeDep", JSON.stringify(true));
      let codeDepartements = this.prepareQuery(this.departementArray);
      codeDep = "topRenaultAd.department=in=(" + codeDepartements + ")" + ";";
      this.flagReseachCodeDep = true;
      this.flagReseach = false;
      flagModelNotUpdate = false;
    } else if (this.departementArray.length === 1) {
      filterGTM += this.departementArray[0] + "|";
      sessionStorage.setItem("flagCodeDep", JSON.stringify(true));
      let codeDepartements = this.prepareQueryFullText(this.departementArray[0]);
      codeDep = "topRenaultAd.department==" + codeDepartements + ";";
      this.flagReseachCodeDep = true;
      this.flagReseach = false;
      flagModelNotUpdate = false;
    } else {
      codeDep = "";
      flagModelNotUpdate = false;
    }


    if (
      this.versionValue !== undefined &&
      this.versionValue !== "undefined" &&
      this.versionValue !== null &&
      this.versionValue !== ""
    ) {
      console.log("enetred inside version")
      filterGTM += this.versionValue + "|";
      version = this.prepareQueryFullText(this.versionValue);
      sessionStorage.setItem("flagCodeDep", JSON.stringify(true));
      sessionStorage.setItem("version", version);
      version = "version.label.raw==" + version + ";";
      this.flagReseach = true;
      flagModelNotUpdate = false;
    }

    //conditions sur le Contruction de la requete
    if (
      (this.kmStr !== undefined && this.kmStr !== "") ||
      (this.prixStr !== undefined && this.prixStr !== "") ||
      (this.modelStr !== undefined && this.modelStr !== "") ||
      (this.energieStr !== undefined && this.energieStr !== "") ||
      (this.couleurStr !== undefined && this.couleurStr !== "") ||
      (this.boiteVitesseStr !== undefined && this.boiteVitesseStr !== "") ||
      (this.marqueStr !== undefined && this.marqueStr !== "") ||
      (codeDep !== undefined && codeDep !== "") ||
      (version !== undefined && version !== "")
    ) {
      paramsFilter += this.marqueStr;
      params +=
        this.kmStr +
        this.prixStr +
        this.modelStr +
        this.energieStr +
        this.couleurStr +
        this.boiteVitesseStr +
        this.marqueStr +
        codeDep +
        version + this.getTopLabel() + this.getNegotiablePrice();
    } else {
      params += this.getTopLabel() + this.getNegotiablePrice();
    }
    if (params.endsWith(";")) {
      params = params.slice(0, params.lastIndexOf(";"));
    }
    if (paramsFilter.endsWith(";")) {
      paramsFilter = paramsFilter.slice(0, paramsFilter.lastIndexOf(";"));
    }

    if (filterGTM.endsWith("|")) {
      filterGTM = filterGTM.slice(0, filterGTM.lastIndexOf("|"));
    }
    // params += ENV.AGGREGATION;
    params = params.split("''").join("'");
    //Query ligth for filter
    // paramsFilter += ENV.AGGREGATION;

    //Cet Item c'est pour reinitialise le trie à chque fois qu'on filtre
    let tryItem = sessionStorage.getItem("searchParamSort");
    if (
      tryItem !== undefined &&
      tryItem !== null &&
      tryItem !== "null" &&
      tryItem !== "undefined"
    ) {
      paramsFilter += tryItem;
      params += tryItem;
    }
    this.flagVersion = true;

    // this.topRenaultservice.getfilteredList(paramsFilter).subscribe((cars: any) => {
    //   this.genereDynamicOption(cars.facets);
    // });

    this.topRenaultservice.getfilteredList(params).subscribe(cars => {
      this.carsData = cars;
      this.topRenaultservice.changeCurrentParamCarFilters(this.carsData)
      this.carSubject.next(this.carsData);
      // this.genereDynamicVersion(this.carsData.facets);
    });

    sessionStorage.setItem("isNotLoadedFirstTime", "true");


    sessionStorage.setItem("query", params);
    // Begin add GTM analytics
    sessionStorage.setItem("searchFilters", filterGTM);

    sessionStorage.setItem("flagInventoryGtm", "true");
    sessionStorage.setItem("selectedValue", undefined);
    this.getUncheckFunction();
    this.topRenaultservice.changeIsLoading(false);
    // this.router.navigate(["liste/updating", { q: params }]);
    this.isLoading = false;
  }

  getUncheckFunction() {
    let filterValues = sessionStorage.getItem("searchFilters");
    let newfilterValues = filterValues.replace(",", "|");
    filterValues = newfilterValues;
    let valuesArray = [];
    if (this.flgNegotiablePrice === true && this.uniqTopFilters.includes("Prix à débattre")) {
      valuesArray.unshift("Prix à débattre");
    }

    if (this.flgLabelTopRenault === true && this.uniqTopFilters.includes("Label Top Renault")) {
      valuesArray.unshift("Label Top Renault");
    }


    Array.from(filterValues.split("|")).forEach(term => {
      if (
        term.includes(",") ||
        (term.includes(" ") && !term.includes("€") && !term.includes("km"))
      ) {
        if (term === this.versionValue) {
          valuesArray.push(term);
        }
        term.split(",").forEach(i => {
          if (
            !i.includes("€") &&
            !i.includes("km") &&
            i !== this.codeDep &&
            i !== this.codeDep &&
            this.uniqTopFilters.includes(i)
          ) {
            valuesArray.unshift(i);
          } else if (this.uniqTopFilters.includes(this.codeDep)) {
            valuesArray.unshift(this.codeDep);
          }
        });
      } else {
        if (
          !term.includes("€") &&
          !term.includes("km") &&
          term !== this.codeDep
        ) {
          valuesArray.unshift(term);
        } else if (
          term.includes("€") &&
          !term.includes("km") &&
          this.prixUncheckedFlag &&
          term !== this.codeDep
        ) {
          if (
            this.prixForm.value.prixControl[0] !== this.minPrixInitial ||
            this.prixForm.value.prixControl[1] !== this.maxPrixInitial
          ) {
            let prix = `${this.prixForm.value.prixControl[0]}-${this.prixForm.value.prixControl[1]} €`;
            valuesArray.unshift(prix);
          }
        } else if (
          term.includes("km") &&
          !term.includes("€") &&
          this.kmUncheckedFlag &&
          term !== this.codeDep
        ) {
          if (
            this.sliderForm.value.sliderControl[0] !== this.minKmInitial ||
            this.sliderForm.value.sliderControl[1] !== this.maxKmInitial
          ) {
            let km = `${this.sliderForm.value.sliderControl[0]}-${this.sliderForm.value.sliderControl[1]} km`;
            valuesArray.unshift(km);
          }
        } else if (this.uniqTopFilters.includes(this.codeDep)) {
          valuesArray.unshift(this.codeDep);
          sessionStorage.setItem(
            "fullCodePostlFromSession",
            this.codeDep
          );
        }
      }
    });

    let colors = JSON.parse(sessionStorage.getItem("couleurArray"));

    this.selected_color_Array = [...colors];
    this.topRenaultservice.setColorFilters(this.selected_color_Array);
    let tempArray = [...new Set(valuesArray)];
    this.checkedFiltersArray = tempArray;
    console.log('checked filter test', this.checkedFiltersArray);
    this.topRenaultservice.setSelectedFilters(this.checkedFiltersArray);
    this.checkedFiltersArray = this.checkedFiltersArray.filter(
      x => x !== undefined && x !== ""
    );
    sessionStorage.setItem(
      "checked-filerts-storage",
      JSON.stringify(this.checkedFiltersArray)
    );
  }

  getChangeContextString(changeContext: ChangeContext, type) {
    if (type === "prix") {
      this.flagPrix = true;
      sessionStorage.setItem("maxPrix", JSON.stringify(changeContext.highValue));
      sessionStorage.setItem("minPrix", JSON.stringify(changeContext.value));
      this.prixForm.value.prixControl[0] = changeContext.value;
      this.prixForm.value.prixControl[1] = changeContext.highValue;
      let filters = this.topRenaultservice.selectedFilters.getValue();

    } else if (type === "km") {
      this.flagKm = true;
      this.minKm = changeContext.value;
      this.maxKm = changeContext.highValue;
      sessionStorage.setItem("maxKm", JSON.stringify(this.maxKm));
      sessionStorage.setItem("minKm", JSON.stringify(this.minKm));
      this.sliderForm.value.sliderControl[0] = this.minKm;
      this.sliderForm.value.sliderControl[1] = this.maxKm;

    } else {
      this.flagLocalisation = true;
    }
  }

  typeaheadNoResults(event: boolean, type): void {
    if (type === 'code') {
      this.noDepartment = event;

    }
    if (type === 'version') {
      this.noVersion = event;
    }

  }

  searchByVersion(): void {
    if (!this.noVersion) {
      this.filter();
      let versionDesktop = document.getElementById("version-input-desktop") as HTMLInputElement;
      let versionMobile = document.getElementById("version-input-mobile") as HTMLInputElement;
      if (versionMobile != null) {
        versionMobile.blur();
      }
      if (versionDesktop != null) {
        versionDesktop.blur();
      }
    }
  }


  async getTriervalue(val, i) {
    this.selectedIndex = i;
    this.sortCars(val);
    let elt = document.getElementById("Section2");
    let li = document.getElementById("mobile-nav-tab2");
    let alink = document.getElementById("mobile-nav-a2");
    let changeIcon = document.getElementById("mobile-icon2");
    changeIcon.classList.remove("rotate");
    alink.classList.remove("active");
    elt.classList.remove("active");
    elt.classList.remove("in");
    li.classList.remove("active");
  }

  async sortCars(val) {
    this.topRenaultservice.changeIsLoading(true);
    this.selectedValueMobile = sessionStorage.setItem(
      "selectedValueMobile",
      val
    );
    this.searchParam = sessionStorage.getItem("query");
    if (
      this.searchParam !== undefined &&
      this.searchParam !== null &&
      this.searchParam.includes("&sort=")
    ) {
      let text = this.searchParam.substring(this.searchParam.indexOf("&sort="));
      this.searchParam = this.searchParam.replace(text, "");
    }
    if (val === "Date de disponibilité croissante") {
      this.searchParamSort = '&sort=%2BtopRenaultAd.availabilityDate';
    } else if (val === "Date de disponibilité  décroissante") {
      this.searchParamSort = '&sort=%2DtopRenaultAd.availabilityDate';
    } else if (val === "Prix décroissant") {
      this.searchParamSort = '&sort=%2Bprices.discountedPriceWithTaxes';
    } else if (val === "Prix croissant") {
      this.searchParamSort = "&sort=%2Dprices.discountedPriceWithTaxes";
    } else if (val === "Kilométrage croissant") {
      this.searchParamSort =  "&sort=%2BtopRenaultAd.mileage";
    } else if (val === "Kilométrage décroissant") {
      this.searchParamSort = "&sort=%2DtopRenaultAd.mileage";
    } else if (val === "Version croissante") {
      this.searchParamSort = "&sort=%2Bversion.label.raw";
    } else if (val === "Version décroissante") {
      this.searchParamSort =  "&sort=%2Dversion.label.raw";
    }
    sessionStorage.setItem("sort", JSON.stringify(true));
    sessionStorage.setItem("searchParamSort", this.searchParamSort);


    sessionStorage.setItem("flagInventoryGtm", "true");
    // this.router.navigate(["liste/updating", { q: this.searchParam }]);
    this.carsData = await this.topRenaultservice.getfilteredList(this.searchParam).toPromise() as any;
    this.carSubject.next(this.carsData);
    this.topRenaultservice.changeIsLoading(false);
    // this.topRenaultservice.changeCurrentParamCarFilters(this.carsData)
  }
  prepareQuery(para) {
    let result = "";
    for (let elt of para) {
      if (elt.includes(" ")) {
        result = result + "'" + elt + "',";
      } else {
        result = result + elt + ",";
      }
    }
    if (result.endsWith(",")) {
      result = result.slice(0, result.lastIndexOf(";"));
    }
    return encodeURI(result);
  }


  prepareQueryFullText(para) {
    let result = "";
    if (para.includes(" ")) {
      result = "'" + para + "'";
    } else {
      result = para;
    }
    return encodeURI(result);
  }


  unselectVersion(type) {
    let iconDesktop = document.getElementById("version-desktop-icon");
    let iconMobile = document.getElementById("version-mobile-icon");
    this.versionValue = "";
     sessionStorage.setItem('version',JSON.stringify(this.versionValue));
    this.checkedFiltersArray = this.checkedFiltersArray.filter(filterTerm => filterTerm !== this.versionValue);
    if (type === "mobile") {
      iconMobile.style.display = "block";
    } else {
      iconDesktop.style.display = "block";
    }
     this.filter();
  }
  unSelect(type) {
    let iconDesktop = document.getElementById("loc-desktop-icon");
    let iconMobile = document.getElementById("loc-mobile-icon");
    this.code_postale = "";
    this.search(undefined);
    if (type === "mobile" && iconMobile) {
      iconMobile.style.display = "block";
    } else if (type === "desktop" && iconDesktop) {
      iconDesktop.style.display = "block";
    }
  }

  getVersionListInfacette(termsVersion) {
    this.versionList = [];
    let FirstTermOfVersionList = [];
    for (let term of termsVersion) {
      this.versionList.push(term.term);
    }
    // this.versionList.forEach(version=>{
    //   let versionTerms = version.split(" ");
    //     FirstTermOfVersionList.push(versionTerms[0]);
    // })
    // this.versionList = new Set([...FirstTermOfVersionList]);
  }

  // genereDynamicCodeDep(facet) {

  //   let codeDepartementList = [];
  //   if (facet.name === "topRenaultAd.department_terms") {
  //     for (let codeDep of facet.terms) {
  //       if (codeDep.term !== undefined && codeDep.term !== null && codeDep.term.trim() !== "") {
  //         codeDepartementList.push(codeDep.term);
  //       }
  //     }
  //     this.codeDepList = codeDepartementList;
  //   }

  // }


  getColorListInFacette(listColors) {
    let colors = [];
    for (let color of listColors) {
      if (
        color.term !== undefined &&
        color.term !== null &&
        color.term.trim() !== ""
      ) {
        colors.push(color);
      }
    }
    this.couleurList = colors;
  }

  genereDynamicVersion(facets) {
    for (let facet of facets) {
      if (facet.name === "version.label.raw_terms") {
        this.getVersionListInfacette(facet.terms);
      }
    }
  }

  genereDynamicModel(facets) {
    for (let facet of facets) {
      if (facet.name === "model.label.raw_terms") {
        this.modeleList = facet.terms;
      }
    }
  }

  getTopLabel() {

    if (this.flgLabelTopRenault != undefined && this.flgLabelTopRenault) {
      let topLabel = "topRenaultAd.topRenaultLabelFlag==" + this.flgLabelTopRenault + ";";
      return topLabel;
    } else {
      return ""
    }
  }

  getNegotiablePrice() {

    if (this.flgNegotiablePrice != undefined && this.flgNegotiablePrice) {
      let negotiablePrice = "topRenaultAd.negotiablePrice==" + this.flgNegotiablePrice + ";";
      return negotiablePrice;
    } else {
      return ""
    }
  }


  async getCarData() {
    let mainQuery;
    let filterRequestquery = sessionStorage.getItem("query");

    if (filterRequestquery !== "" && filterRequestquery !== undefined && filterRequestquery !== null
      && this.browserRefresh == false && filterRequestquery !== 'undefined') {

      mainQuery = filterRequestquery;
    } else {
      sessionStorage.setItem('searchParamSort', "&sort=-topRenaultAd.topRenaultLabelFlag,%2DtopRenaultAd.adPublicationDate");
      mainQuery = "";
    }
    this.initRequestData();
    this.carsData = await this.topRenaultservice.getfilteredList(mainQuery).toPromise();
  }
  initRequestData() {
    if (this.browserRefresh) {
      this.modelArray = [];
      this.uniqTopFilters = [];
      this.marqueArray = [];
      this.energieArray = [];
      this.couleurArray = [];
      this.bvAutomatiqueGlobal = [];
      this.bvManualGlobal = [];
      this.boiteVitesseArray = []
      sessionStorage.setItem('isTopLabel', JSON.stringify(false))
      sessionStorage.setItem('isNegotiablePrice', JSON.stringify(false))
      // this.updateFilters();
      sessionStorage.removeItem("query");
      this.topRenaultservice.setSelectedFilters([]);
      sessionStorage.removeItem("marqueArray");
      sessionStorage.removeItem("modelArray");
      sessionStorage.removeItem("energieArray");
      sessionStorage.removeItem("couleurArray");
      sessionStorage.removeItem("boiteVitesseArray");
      sessionStorage.removeItem("checked-filerts-storage");
      sessionStorage.removeItem("uniqTopFilters");
      sessionStorage.removeItem("searchFilters");
      sessionStorage.removeItem("pinCodeFromSession");
      sessionStorage.removeItem("fullCodePostlFromSession");
      sessionStorage.removeItem("genericModels");
      sessionStorage.removeItem("departementArray");
    }
  }
}
