import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { CarList } from '../models/car-list';
import { ToprenaultService } from '../services/toprenault.service';

@Component({
  selector: 'app-reload-list',
  templateUrl: './reload-list.component.html',
  styleUrls: ['./reload-list.component.css']
})
export class ReloadListComponent implements OnInit {

  public isLoading: boolean = true;
  constructor(private router: Router, private route: ActivatedRoute, private toprenaultService: ToprenaultService) {

    // this.route.params.subscribe(params => {
    //   if (params != undefined && params.q != undefined) {
    //     this.donnees = params.q;
    //   }
    // });
  }

  ngOnInit() {
    // this.router.navigate(['liste', { query: this.donnees }]);
    this.toprenaultService.isLoadingFilters.subscribe(isLoading =>
      this.isLoading = isLoading
    )
  }

}
