<div class="auto-container" style="max-width:1198px !important;">
    <div class="bg-showcase">

        <div class="row justify-content-center align-self-center">
            <div id="main-wrapper" class="col-md-5 col-sm-5 col-xs-5 col-lg-5 card mt-5 rounded-0">
                <div class="container mt-5" *ngIf='!isPasswordUpdated'>
                    <h3 class="heading text-center">Saisissez votre nouveau mot de passe</h3>
                    <form class="text-center" [formGroup]="passwordForm">
                            <ng-template #popTemplate>
                                <div class="text-left" *ngIf="fields_signUp_form.password.errors || disPlayErrors">
                                    <span>
                                        <strong class="text-header">Votre mot de passe doit comporter :</strong>
                                    </span>
                                    <span
                                        *ngIf="(fields_signUp_form.password.touched && fields_signUp_form.password.invalid) || (fields_signUp_form.password.touched && fields_signUp_form.password.errors?.pattern) || disPlayErrors "
                                        class="error-msg">
                            
                                        <br>
                            
                                        <span
                                            [ngClass]="passwordForm.get('password').hasError('hasCapitalCase') || passwordForm.get('password').hasError('required') ? 'text-dark' : 'text-success'">
                                            <i class="far"
                                                [ngClass]="{'fa-times-circle': passwordForm.get('password').hasError('hasCapitalCase') || passwordForm.get('password').hasError('required'),'fa-check-circle':!passwordForm.get('password').hasError('hasCapitalCase')}"></i>
                                            1
                                            Majuscule
                                        </span>
                                        <br>
                                        <span
                                            [ngClass]="passwordForm.get('password').hasError('hasSmallCase') || passwordForm.get('password').hasError('required') ? 'text-dark' : 'text-success'">
                                            <i class="far"
                                                [ngClass]="{'fa-times-circle': passwordForm.get('password').hasError('hasSmallCase') || passwordForm.get('password').hasError('required') ,'fa-check-circle':!passwordForm.get('password').hasError('hasSmallCase')}"></i>
                                            1 Miniscule
                                        </span>
                                        <br>
                                        <span
                                            [ngClass]="passwordForm.get('password').hasError('hasNumber') || passwordForm.get('password').hasError('required') ? 'text-dark' : 'text-success'">
                                            <i class="far"
                                                [ngClass]="{'fa-times-circle': passwordForm.get('password').hasError('hasNumber') || passwordForm.get('password').hasError('required'),'fa-check-circle':!passwordForm.get('password').hasError('hasNumber')}"></i>
                                            1 Chiffre
                                        </span>
                                        <br>
                                        <span
                                            [ngClass]="passwordForm.get('password').hasError('hasSpecialCharacters') || passwordForm.get('password').hasError('required') ? 'text-dark' : 'text-success'">
                                            <i class="far"
                                                [ngClass]="{'fa-times-circle': passwordForm.get('password').hasError('hasSpecialCharacters') || passwordForm.get('password').hasError('required'),'fa-check-circle':!passwordForm.get('password').hasError('hasSpecialCharacters')}"></i>
                                            1 Caractère spécial
                                        </span>
                                        <br>
                                        <span
                                            [ngClass]="passwordForm.get('password').hasError('minlength') || passwordForm.get('password').hasError('required') ? 'text-dark' : 'text-success'">
                                            <i class="far"
                                                [ngClass]="{'fa-times-circle': passwordForm.get('password').hasError('minlength') || passwordForm.get('password').hasError('required'),'fa-check-circle':!passwordForm.get('password').hasError('minlength')}"></i>
                                            Minimum de 8 caractères
                                        </span>
                            
                                    </span>
                            
                                </div>
                            </ng-template>
                        <div class="form-group m-3">
                            <i id="eye-show" *ngIf="!show_button" class="far fa-eye" (click)="showPassword()"></i>
                            <i id="eye-hide" *ngIf="show_button" class="far fa-eye-slash" (click)="showPassword()"></i>
                            <input [type]="show_button ? 'text' : 'password'" name="password" formControlName="password" (focus)="disPlayErrors=true" (focusout)="disPlayErrors=false" [popover]="popTemplate"  triggers="focus" placement="top"
                                [ngClass]="{ 'error':fields_signUp_form.password.touched && fields_signUp_form.password.invalid ,'success':fields_signUp_form.password.touched && fields_signUp_form.password.valid }"
                                required autocomplete="nope" />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label for="password">Mot de passe <sup>*</sup></label>
                            <div class="text-left error-container" *ngIf="fields_signUp_form.password.errors || disPlayErrors">
                                <small *ngIf="fields_signUp_form.password.touched && fields_signUp_form.password.invalid" class="error-msg">
                                    <span *ngIf="fields_signUp_form.password.errors?.required">Mot de passe
                                        obligatoire</span>
                                </small>
                                <small class="error-msg">
                                    <span
                                        *ngIf="(passwordForm.get('password').hasError('hasCapitalCase') || passwordForm.get('password').hasError('hasSmallCase') || passwordForm.get('password').hasError('hasNumber') || passwordForm.get('password').hasError('hasSpecialCharacters') || passwordForm.get('password').hasError('minlength')) && fields_signUp_form.password.touched">
                                        Le mot de passe ne respecte pas les conditions de sécurité
                                    </span>
                                </small>
                            
                            </div>
                        
                        </div>
                        <div class="text-center captcha-container" style="margin-left: 5em;margin-top: 5em;">
                            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()"
                                (expire)="handleExpire()" (load)="handleLoad()" (success)="handleSuccess($event)"
                                [useGlobalDomain]="false" [size]="size" [hl]="lang" [theme]="theme" [type]="type"
                                formControlName="recaptcha">
                            </ngx-recaptcha2>
                        </div>

                        <div class="mt-5 mb-3">
                            <button id="password-reset-btn" 
                                [disabled]="passwordForm.invalid" (click)="changePassword()"><span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span>CHANGER MON MOT DE PASSE</button>
                        </div>
                        
                    </form>
                </div>

                <div *ngIf='isPasswordUpdated' class="m-2 p-3 text-center" style="margin:auto 0;">
                    <div class="w-100 text-center mt-3 mb-3">
                        <img src="../../assets/images/lock.png" alt="email sent" width="100" height="100" srcset="">
                    </div>
                    <div class="reinit-text pt-3 pb-3 border-bottom">
                        Votre nouveau mot de passe a bien été enregistré. Vous pouvez maintenant accéder à Top Renault.
                    </div>
                    
                    <div class="mt-5 mb-3">
                        <button id="password-reset-btn" (click)="loginPage()">
                        <span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span> Accéder à Top Renault
                            </button>
                    </div>
                </div>
   
            </div>
        </div>
    </div>
