import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-legal',
  templateUrl: './info-legal.component.html',
  styleUrls: ['./info-legal.component.css']
})
export class InfoLegalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
