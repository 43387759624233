import { Component, OnInit, NgZone } from '@angular/core';
import { ToprenaultService } from '../services/toprenault.service';
import { Options, LabelType, ChangeContext, PointerType } from 'ng5-slider';
import { Router, ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { ENV } from "../core/env.config";
import { Observable } from 'rxjs';
import listCodeDepart from "../codeDep.json";
import { Vignette } from '../models/car';
import { CockpitCmsService } from '../services/cockpitCms.service';

declare global {
  interface Window { dataLayer: any[] }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public minPrix: number;
  public maxPrix: number;
  public minKm: number;
  public maxKm: number;
  public valPerimetre: number = 0;
  public code: string;
  public prix: string = "Prix";
  public km: string = "Km";
  public localisation: string = "Localisation";
  public flagPrix: boolean = false;
  public flagKm: boolean = false;
  public flagLocalisation: boolean = true;
  public codeDepLists: Array<any> = [];
  public codeDep: string;
  public carList: any = [];
  public carRetrieve: any;
  public geoCoder;
  public code_postale;
  public dataEditos;
  public noDepartment: boolean;
  public isInFavorites: boolean = false;
  public today: any;
  public vignette = {} as Vignette;
  public nosOffreData: any;
  public favoritesList = [];

  constructor(private activeRoute: ActivatedRoute, private router: Router,
    private topRenaultservice: ToprenaultService,
  ) {

    sessionStorage.setItem('onglet', "home");
    // sessionStorage.clear();
    this.today = new Date().toISOString().split("T")[0];

    let facets = [];

    this.topRenaultservice.getListHome().subscribe(data => {
      this.carRetrieve = data;
      facets = this.carRetrieve.facets;
      if (this.carRetrieve !== undefined) {
        for (let facet of facets) {
          // this.genereDynamicCodeDep(facet);
          if (facet.name === 'topRenaultAd.mileage_min') {
            this.minKm = facet.min.value;
            this.optionsKm.floor = this.minKm;
          } else if (facet.name === 'topRenaultAd.mileage_max') {
            this.maxKm = facet.max.value;
            this.optionsKm.ceil = this.maxKm;
          } else if (facet.name === 'prices.discountedPriceWithTaxes_min') {
            this.minPrix = facet.min.value;
            this.optionsPrix.floor = this.minPrix;
          }
          else if (facet.name === 'prices.discountedPriceWithTaxes_max') {
            this.maxPrix = facet.max.value;
            this.optionsPrix.ceil = this.maxPrix;
          }
        }
      }
    }
    );
  }
  // genereDynamicCodeDep(facet) {

  //   let codeDepartementList = [];
  //   if (facet.name === "topRenaultAd.department_terms") {
  //     for (let codeDep of facet.terms) {
  //       if (codeDep.term !== undefined && codeDep.term !== null && codeDep.term.trim() !== "") {
  //         codeDepartementList.push(codeDep.term);
  //       }
  //     }
  //     this.codeDepList = codeDepartementList;
  //   }
  // }

  optionsPerimetre: Options = {
    floor: 0,
    ceil: 350,
    translate: (value: number, label: LabelType): string => {
      return value + ' km';
    }
  };
  optionsPrix: Options = {
    floor: 0,
    ceil: 50000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<span class="min">Min :</span> ' + value + ' €';
        case LabelType.High:
          return '<span class="max">Max :</span> ' + value + ' €';
        default:
          return value + ' €';
      }
    }
  };

  optionsKm: Options = {
    floor: 0,
    ceil: 25000,

    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<span class="min">Min :</span> ' + value + ' ' + ' km';
        case LabelType.High:
          return '<span class="max">Max :</span> ' + value + ' ' + ' km';
        default:
          return value + ' km';
      }
    }
  };


  async ngOnInit() {
    this.runJS();

    let topcarData = this.activeRoute.snapshot.data['topcars'];

    let carListInit = topcarData['dataTOR'];

    for (let car of carListInit.data) {
      // if (car != null && car['topRenaultAd']['topRenaultLabelFlag'] == true) {
      //   this.carList.unshift(car);
      // } else if (car != null && car['topRenaultAd']['topRenaultLabelFlag'] == false) {
      this.carList.push(car);
      // }
    }

    if (sessionStorage.getItem("facette") == undefined) {
      var carListData = await this.topRenaultservice.getfilteredList("").toPromise() as any;
      sessionStorage.setItem("facette", JSON.stringify(carListData.facets));
    }

    for (let car of carListInit.data) {
      if ('favoritesList' in localStorage) {
        let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
        let index = favoritesInLocalStorage.indexOf(car.productId);
        if (index !== -1) {
          car = Object.assign(car, { 'isInFavorites': true }, { 'isNotInFavorites': false });
        } else {
          car = Object.assign(car, { 'isInFavorites': false }, { 'isNotInFavorites': true });
        }
      } else {
        car = Object.assign(car, { 'isInFavorites': false }, { 'isNotInFavorites': true });
      }
    };

    // this.carList.sort((car) => (car['topRenaultAd']['topRenaultLabelFlag'] ? -1 : 1));
    // this.carList.sort((car, car1) => (car['topRenaultAd']['topRenaultLabelFlag'] === car1['topRenaultAd']['topRenaultLabelFlag'] ? 0 : car['topRenaultAd']['topRenaultLabelFlag'] ? -1 : 1));
     this.dataEditos = topcarData['dataEditos']['entries'][0];
     this.nosOffreData = topcarData['dataOffres']['entries'][0];
    // let vignette = topcarData['vignette']['entries'][0];
    // this.vignette.titre = vignette['Title'],
    //   this.vignette.description = vignette['Description'],
      //this.vignette.description = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    //   this.vignette.link = vignette['Link'],
    //   this.vignette.bouton = vignette['Bouton'],
    //   this.vignette.position = Number(vignette['Position']) - 1;
    // this.carList.splice(this.vignette.position, 0, this.vignette);

    // sessionStorage.setItem("searchFilters", undefined);
    registerLocaleData(fr);
    //Begin For GTM work: 
    if (sessionStorage.getItem("flagHomePageGtm") === undefined || sessionStorage.getItem("flagHomePageGtm") === null
      || sessionStorage.getItem("flagHomePageGtm") === 'false') {
      window.dataLayer.push({
        googleAccount: 'UA-19376323-1',
        businessType: 'used-car',
        pageType: "homepage",
        optinStatus: undefined,
        loginStatus: undefined,
        countryCode: 'FR',
        languageCode: 'FR',
        searchFilters: undefined,
        searchTerm: undefined,
        pageName: 'top-renault | homepage'
      })
    } else {
      window.dataLayer.push({
        event: 'virtualPageview',
        googleAccount: 'UA-19376323-1',
        businessType: 'used-car',
        pageType: 'homepage',
        leadID: undefined,
        formType: undefined,
        vehicleCategory: undefined,
        vehicleModel: undefined,
        inventoryId: undefined,
        optinStatus: undefined,
        loginStatus: undefined,
        countryCode: 'FR',
        languageCode: 'FR',
        formCategory: undefined,
        formName: undefined,
        searchFilters: undefined,
        searchTerm: undefined,
        resultsNumber: undefined,
        brand: undefined,
        pageName: 'top-renault | homepage'
      })
    }
    sessionStorage.setItem("flagEngagementGtm", 'true');
    sessionStorage.setItem("flagHomePageGtm", 'false')
    sessionStorage.setItem('flagInventoryGtm', 'true');
    sessionStorage.setItem('flagDetailsGtm', 'true');
    //End For GTM work: 

    this.topRenaultservice.getCodeDep().subscribe(list => {

      this.codeDepLists = list;
      if (this.codeDepLists == undefined || this.codeDepLists == null || this.codeDepLists.length == 0) {
        this.codeDepLists = listCodeDepart;
      }
    })


  }

  addToFavorite(id, e) {
    e.stopPropagation();
    if ('favoritesList' in localStorage) {
      let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
      let index = favoritesInLocalStorage.indexOf(id);
      if (index == -1) {
        favoritesInLocalStorage.push(id);
        for (let car of this.carList) {
          if (car.productId == id) {
            car.isNotInFavorites = false;
            car.isInFavorites = true;
          }

        }
        localStorage.setItem('favoritesList', JSON.stringify(favoritesInLocalStorage));
        this.isInFavorites = true;
        this.topRenaultservice.setFavorisCount(favoritesInLocalStorage.length);
      }
    } else {
      this.favoritesList.push(id);
      for (let car of this.carList) {
        if (car.productId == id) {
          car.isNotInFavorites = false;
          car.isInFavorites = true;
        }

      }
      localStorage.setItem('favoritesList', JSON.stringify(this.favoritesList));
      this.topRenaultservice.setFavorisCount(this.favoritesList.length);

    }
  }


  removeFromFavorite(id, e) {
    e.stopPropagation()
    let favoritesInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
    favoritesInLocalStorage = favoritesInLocalStorage.filter(idInLocalStorage => id !== idInLocalStorage);
    localStorage.setItem('favoritesList', JSON.stringify(favoritesInLocalStorage));
    for (let car of this.carList) {
      if (car.productId == id) {
        car.isNotInFavorites = true;
        car.isInFavorites = false;
      }

    }
    this.topRenaultservice.setFavorisCount(favoritesInLocalStorage.length);

  };

  getimageLink(path) {
    let chemin = ""
    if (path != undefined) {
      chemin = ENV.COCKPIT_URL + path.path;
    }
    return chemin;
  }


  runJS() {
    if (document.getElementById("topRenault")) {
      document.getElementById("topRenault").remove();
    }

    let topRenault = document.createElement("script");
    topRenault.setAttribute("id", "topRenault");
    topRenault.setAttribute("src", "assets/js/script.js");
    document.body.appendChild(topRenault);
  }

  playOrPauseVideo() {
    let video: any = <HTMLVideoElement>document.getElementById("video-element");
    let btn = document.getElementById("play-Pause-btn");
    let overlay: any = document.getElementById("home-top-video");
    let Icon: any = document.getElementById("video-icon");
    let text = document.getElementById("text");
    if (video.paused) {
      video.play();
      btn.innerHTML = "pause";
      overlay.classList.remove('home-top-video-class');
      text.style.display = "none";
      btn.style.display = "none";
      Icon.style.display = "none";
      video.style.zIndex = 8;
      // video.setAttribute("controls", "true");
    } else {
      video.pause();
      btn.innerHTML = "play";
      text.style.display = "block";
      btn.style.display = "block";
      Icon.style.display = "block";
      overlay.style.zIndex = 2;
      overlay.classList.add('home-top-video-class');
      // video.setAttribute("controls", "false") ;

    }

  }

  recordShown() {
    let element = document.getElementsByClassName("popover").item(0);
  }

  selectCar(id) {

    window.dataLayer.push({
      event: "virtualPageview",
      googleAccount: "UA-19376323-1",
      businessType: undefined,
      pageType: 'top-renault | details',
      leadID: undefined,
      formType: undefined,
      vehicleCategory: undefined,
      vehicleModel: undefined,
      inventoryId: undefined,
      optinStatus: undefined,
      loginStatus: undefined,
      countryCode: "FR",
      languageCode: "FR",
      formCategory: undefined,
      formName: undefined,
      searchFilters: undefined,
      searchTerm: undefined,
      resultsNumber: undefined,
      project: undefined,
      brand: undefined,
      usedBrand: undefined,
      pageName: "details",
    });

    sessionStorage.setItem("id", id);
    sessionStorage.setItem('flagDetailsGtm', 'true')
    this.router.navigate(['/details']);
  }

  onshownPop(id) {
    if (id == "widget_loc") {
      // this.initMap();
      // this.codeDep=this.topRenaultservice.initMap(this.geoCoder,this.code_postale,this.codeDep);
    }

    document.getElementById(id).style.borderBottomColor = "#ffce33";
    let element = document.getElementById(id + "_chev");
    element.classList.remove("icofont-thin-down");
    element.classList.add("icofont-thin-up");
  }


  typeaheadNoResults(event: boolean, type): void {
    this.noDepartment = event;
  }
  onHiddenPop(id) {
    document.getElementById(id).style.borderBottomColor = "#ebebeb";
    let element = document.getElementById(id + "_chev");
    element.classList.remove("icofont-thin-up");
    element.classList.add("icofont-thin-down");

    if (this.flagPrix === true) {
      this.prix = this.minPrix + ' € - ' + this.maxPrix + ' €';
    }
    if (this.flagKm === true) {
      this.km = this.minKm + ' km - ' + this.maxKm + ' km';
    }
    if (this.flagLocalisation === true && !this.noDepartment) {
      this.localisation = this.codeDep;
      console.log("onHiddenPop code Dep :", this.codeDep)
      if (this.localisation === "" || this.localisation === undefined) {
        this.localisation = "Localisation"

      }
    }
  }

  reseach() {
    let query = '&q=';
    //This key is for GTM using 
    let searchFilters = undefined;
    console.log("this.codeDep :", this.codeDep)
    sessionStorage.setItem("flagReseachHomePage", "true");
    if (this.flagPrix === true && this.flagKm === true) {
      searchFilters = this.minPrix + "-" + this.maxPrix + " € |" + this.minKm + "-" + this.maxKm + " km |";
      query = query + 'prices.discountedPriceWithTaxes' + '>=' + this.minPrix + ';'
        + 'prices.discountedPriceWithTaxes' + '<=' + this.maxPrix + ';'
        + 'topRenaultAd.mileage' + '>=' + this.minKm + ';' + 'topRenaultAd.mileage' + '<=' + this.maxKm;
      sessionStorage.setItem("minPrix", JSON.stringify(this.minPrix));
      sessionStorage.setItem("maxPrix", JSON.stringify(this.maxPrix));
      sessionStorage.setItem("minKm", JSON.stringify(this.minKm));
      sessionStorage.setItem("maxKm", JSON.stringify(this.maxKm));
    } else if (this.flagKm === true) {
      searchFilters = this.minKm + "-" + this.maxKm + " km |";
      query = query + 'topRenaultAd.mileage' + '>=' + this.minKm + ';' + 'topRenaultAd.mileage' + '<=' + this.maxKm;
      sessionStorage.setItem("minKm", JSON.stringify(this.minKm));
      sessionStorage.setItem("maxKm", JSON.stringify(this.maxKm));
    } else if (this.flagPrix === true) {
      searchFilters = this.minPrix + "-" + this.maxPrix + " € |"
      query = query + 'prices.discountedPriceWithTaxes' + '>=' + this.minPrix + ';'
        + 'prices.discountedPriceWithTaxes' + '<=' + this.maxPrix;
      sessionStorage.setItem("minPrix", JSON.stringify(this.minPrix));
      sessionStorage.setItem("maxPrix", JSON.stringify(this.maxPrix));
    }
    if ((this.codeDep !== undefined && this.codeDep !== null) && (this.flagPrix === true || this.flagKm === true)) {
      searchFilters += this.codeDep;
      query = query + ';' + 'topRenaultAd.department==' + this.codeDep;
      sessionStorage.setItem("codePostal", JSON.stringify(this.codeDep));
    } else if (this.codeDep !== undefined && this.codeDep !== null && this.flagPrix === false && this.flagKm === false) {
      searchFilters += this.codeDep;
      query = query + 'topRenaultAd.department==' + this.codeDep;
      sessionStorage.setItem("codePostal", JSON.stringify(this.codeDep));
    }
    if (this.code !== undefined && this.code !== null) {
      query = query + '&' + 'fulltext=' + this.code;
      let fullText = this.code.split('"').join('');
      sessionStorage.setItem("full-Text", JSON.stringify(fullText));
    }
    if (query.endsWith(";")) {
      query = query.slice(0, query.lastIndexOf(";"));
    }

    if (searchFilters !== undefined && searchFilters.endsWith("|")) {
      searchFilters = searchFilters.slice(0, searchFilters.lastIndexOf("|"));
    }
    sessionStorage.setItem("query", query);
    sessionStorage.setItem("searchFilters", searchFilters);
    let searchTerm = this.code;
    //Datalayer for GTM
    window.dataLayer.push({
      event: "virtualPageview",
      googleAccount: "UA-19376323-1",
      businessType: undefined,
      pageType: 'top-renault | inventory',
      leadID: undefined,
      formType: undefined,
      vehicleCategory: undefined,
      vehicleModel: undefined,
      inventoryId: undefined,
      optinStatus: undefined,
      loginStatus: undefined,
      countryCode: "FR",
      languageCode: "FR",
      formCategory: undefined,
      formName: undefined,
      searchFilters: searchFilters,
      searchTerm: searchTerm,
      resultsNumber: undefined,
      project: undefined,
      brand: undefined,
      usedBrand: undefined,
      pageName: "inventory",
    })



    //Begin For GTM work 
    sessionStorage.setItem('flagInventoryGtm', 'true');
    sessionStorage.setItem("searchFilters", searchFilters);
    //End For GTM work
    this.router.navigate(['liste']);
  }

  annuler(type, cls, containerId) {
    if ((type === "prix" || containerId === "widget_prix") && cls === 'customPoprange') {
      let elt = document.getElementsByClassName(cls)[0] as HTMLElement;
      elt.style.display = "none";
      this.onHiddenPop(containerId);
      this.minPrix = 0;
      this.maxPrix = 40000;
      this.flagPrix = false;
      this.prix = "Prix";

    } else if ((type === "km" || containerId === "widget_km") && cls === 'customPopKMrange') {
      let elt = document.getElementsByClassName(cls)[0] as HTMLElement;
      elt.style.display = "none";
      this.onHiddenPop(containerId);
      this.minKm = 0;
      this.maxKm = 200000;
      this.flagKm = false;
      this.km = "km";

    } else if ((type === "localisation" || containerId === "widget_loc") && cls === 'customPopGeorange') {
      // this.valPerimetre = 0;
      let elt = document.getElementsByClassName(cls)[0] as HTMLElement;
      elt.style.display = "none";
      this.onHiddenPop(containerId);
      this.flagLocalisation = false;
      this.localisation = "Localisation";

    }
  }

  onUserChange(changeContext: ChangeContext, type): void {
    this.getChangeContextString(changeContext, type);
  }

  onUserChangeStart(changeContext: ChangeContext, type): void {
    this.getChangeContextString(changeContext, type);
  }
  onUserChangeEnd(changeContext: ChangeContext, type): void {
    this.getChangeContextString(changeContext, type);
  }

  getChangeContextString(changeContext: ChangeContext, type) {

    if (type === 'prix') {
      this.flagPrix = true;
    } else if (type === 'km') {
      this.flagKm = true;
    } else {
      this.flagLocalisation = true;
    }
  }

  hidePop(popclassname, containerId) {
    let elt = document.getElementsByClassName(popclassname)[0] as HTMLElement;
    elt.style.display = "none";
    this.onHiddenPop(containerId);
  }

  goToPage() {
    this.router.navigate([this.vignette.link]);
  }
  goToPage1(type) {
    this.router.navigate(['/engagements'], { queryParams: { page: type } });
  }
}
