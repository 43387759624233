

<!-- <div style="position: absolute;top:0; left:0%">
        <ngb-alert #selfClosingAlert *ngIf="successMessage" type="success" (closed)="successMessage = ''">{{ successMessage }}
        </ngb-alert>
</div> -->
<div class="auto-container" style="max-width:1198px !important; position: relative;" *ngIf="userObj">
    <section class="page-title" style="background-color:#1f2532">
        <div class="auto-container">
            <h3 class="text-white user-name"> <i class="far fa-user mr-2"></i> {{userObj.family_name}} {{userObj.first_name}} </h3>
            <div class="page-subtitle">
                <h4 style="color:#fff !important;font-size: 18px!important;"> </h4>
            </div>
        </div>
        <ul class="nav nav-tabs" id="nav-menu" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="mes-information-tab" data-toggle="tab" href="#mes-information" role="tab"
                    aria-controls="home" aria-selected="true">Mes Informations</a>
            </li>
            <!-- <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
                        aria-selected="false">Profile</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact"
                        aria-selected="false">Contact</a>
                </li> -->
        </ul>
    </section>

    <div class="tab-content" id="myTabContent">
        <ngb-alert #selfClosingAlert *ngIf="successMessage" type="success" (closed)="successMessage = ''">{{ successMessage }}
        </ngb-alert>
        <div class="tab-pane fade show active" id="mes-information" role="tabpanel" aria-labelledby="mes-information-tab">
         <div class="container">
          
            <div class="row m-2">
                <div class="col-md-6 col-lg-6 col-xs-12 col-xs-12 col-sm-12 pl-0">
                    <h4 class="heading text-left">Mon Identifiant</h4>
                    <h4 class="user-label">Email <sup>*</sup></h4>
                    <p class="user-info" >{{userObj.user_email}}</p>
                </div>
                <div class="col-md-6 col-lg-6 col-xs-12 col-xs-12 col-sm-12 pl-md-5 pl-0">
                    <div class="d-flex justify-content-between border-bottom">
                        <h4 class="heading border-0">Mot de passe</h4>
                        <h4 class="heading1" data-toggle="modal" data-target="#section-1" > <u >Modifiez</u> </h4>
                    </div>
                   
                    <h4 class="user-label">Mot de passe <sup>*</sup></h4>
                    <p class="user-info">{{createPasswordStars(userObj.password_length)}}</p>
                </div>
                <div class="modal" id="section-1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title ml-auto" id="exampleModalLongTitle">CHANGER DE MOT DE PASSE</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" (click)="clearForm(1)">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body ">
                                <form [formGroup]="passwordForm">
                                    <div class="form-group m-3 mb-5">

                                        <i id="eye-show-1" *ngIf="!show_button_1" class="far fa-eye" (click)="showPassword(1)"></i>
                                        <i id="eye-hide-1" *ngIf="show_button_1" class="far fa-eye-slash" (click)="showPassword(1)"></i>
                                        <input [type]="show_button_1 ? 'text' : 'password'" name="newPassword"
                                            formControlName="prevPassword"
                                            [ngClass]="{ 'error':fields_passwordChange_form.prevPassword.touched && fields_passwordChange_form.prevPassword.invalid ,'success':fields_passwordChange_form.prevPassword.touched && fields_passwordChange_form.prevPassword.valid }"
                                            required autocomplete="nope" />
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label for="newPassword">Entrez ici votre mot de passe actuel <sup>*</sup></label>
                                        <div class="text-left error-container" *ngIf="fields_passwordChange_form.prevPassword.errors">
                                            <small *ngIf="fields_passwordChange_form.prevPassword.touched && fields_passwordChange_form.prevPassword.invalid"
                                                class="error-msg">
                                                <span *ngIf="fields_passwordChange_form.prevPassword.errors?.required">Mot de passe
                                                    obligatoire</span>
                                            </small>
                                        </div>
                                    </div>
                                    <ng-template #popTemplate>
                                        <strong class="text-header">Votre nouveau mot de passe doit comporter :</strong>
                                    <span
                                        *ngIf="(fields_passwordChange_form.newPassword.touched && fields_passwordChange_form.newPassword.invalid) || (fields_passwordChange_form.newPassword.touched && fields_passwordChange_form.newPassword.errors?.pattern) || disPlayErrors  "
                                        class="error-msg ">
                                    
                                    <br>
                                    
                                        <span
                                            [ngClass]="passwordForm.get('newPassword').hasError('hasCapitalCase') || passwordForm.get('newPassword').hasError('required') ? 'text-dark' : 'text-success'">
                                            <i class="far"
                                                [ngClass]="{'fa-times-circle': passwordForm.get('newPassword').hasError('hasCapitalCase') || passwordForm.get('newPassword').hasError('required'),'fa-check-circle':!passwordForm.get('newPassword').hasError('hasCapitalCase')}"></i>
                                                 1 Majuscule
                                        </span>
                                        <br>
                                        <span
                                            [ngClass]="passwordForm.get('newPassword').hasError('hasSmallCase') || passwordForm.get('newPassword').hasError('required') ? 'text-dark' : 'text-success'">
                                            <i class="far"
                                                [ngClass]="{'fa-times-circle': passwordForm.get('newPassword').hasError('hasSmallCase') || passwordForm.get('newPassword').hasError('required'),'fa-check-circle':!passwordForm.get('newPassword').hasError('hasSmallCase')}"></i>
                                            1 Miniscule
                                        </span>
                                        <br>
                                        <span
                                            [ngClass]="passwordForm.get('newPassword').hasError('hasNumber') || passwordForm.get('newPassword').hasError('required') ? 'text-dark' : 'text-success'">
                                            <i class="far"
                                                [ngClass]="{'fa-times-circle': passwordForm.get('newPassword').hasError('hasNumber') || passwordForm.get('newPassword').hasError('required'),'fa-check-circle':!passwordForm.get('newPassword').hasError('hasNumber')}"></i>
                                            1 Chiffre
                                        </span>
                                        <br>
                                        <span
                                            [ngClass]="passwordForm.get('newPassword').hasError('hasSpecialCharacters') || passwordForm.get('newPassword').hasError('required') ? 'text-dark' : 'text-success'">
                                            <i class="far"
                                                [ngClass]="{'fa-times-circle': passwordForm.get('newPassword').hasError('hasSpecialCharacters') || passwordForm.get('newPassword').hasError('required'),'fa-check-circle':!passwordForm.get('newPassword').hasError('hasSpecialCharacters')}"></i>
                                            1 Caractère spécial
                                        </span>
                                        <br>
                                        <span
                                            [ngClass]="passwordForm.get('newPassword').hasError('minlength') || passwordForm.get('newPassword').hasError('required') ? 'text-dark' : 'text-success'">
                                            <i class="far"
                                                [ngClass]="{'fa-times-circle': passwordForm.get('newPassword').hasError('minlength') || passwordForm.get('newPassword').hasError('required'),'fa-check-circle':!passwordForm.get('newPassword').hasError('minlength')}"></i>
                                             Minimum de 8 caractères
                                        </span>
                                    
                                    </span>
                                    </ng-template>
                
                                    <div class="form-group m-3 mb-5">
                                        <i id="eye-show-2" *ngIf="!show_button_2" class="far fa-eye" (click)="showPassword(2)"></i>
                                        <i id="eye-hide-2" *ngIf="show_button_2" class="far fa-eye-slash" (click)="showPassword(2)"></i>
                                        <input [type]="show_button_2 ? 'text' : 'password'" name="newPassword"
                                            formControlName="newPassword" (focus)="disPlayErrors=true" (focusout)="disPlayErrors=false"  [popover]="popTemplate"  triggers="focus" placement="top"
                                            [ngClass]="{ 'error':fields_passwordChange_form.newPassword.touched && fields_passwordChange_form.newPassword.invalid ,'success':fields_passwordChange_form.newPassword.touched && fields_passwordChange_form.newPassword.valid }"
                                            required autocomplete="nope" />
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label for="newPassword">Entrez ici votre nouveau mot de passe <sup>*</sup></label>
                         
                                        <div class="text-left error-container" *ngIf="fields_passwordChange_form.newPassword.errors || disPlayErrors">
                                            <small
                                                
                                                class="error-msg">
                                                <span *ngIf="fields_passwordChange_form.newPassword.errors && fields_passwordChange_form.newPassword.touched ">
                                                    <span *ngIf="fields_passwordChange_form.newPassword.errors?.required">Mot de passe
                                                        obligatoire</span>
                                                   </span>
                                            </small>
                                            <small class="error-msg">
                                                <span *ngIf="(passwordForm.get('newPassword').hasError('hasCapitalCase') || passwordForm.get('newPassword').hasError('hasSmallCase') || passwordForm.get('newPassword').hasError('hasNumber') || passwordForm.get('newPassword').hasError('hasSpecialCharacters') || passwordForm.get('newPassword').hasError('minlength')) && fields_passwordChange_form.newPassword.touched  ">
                                                Le mot de passe ne respecte pas les conditions de sécurité
                                                </span>
                                            </small>
                                            <br/>
                                          
                
                                        </div>
                                    </div>
                                </form>
                
                            </div>
                
                            <div class="modal-footer border-0 mt-4">
                                <button type="button" class="cancel-btn" (click)="clearForm(1)" data-dismiss="modal">Annulez</button>
                                <button type="button" class="register-btn" (click)="modifyPassword()" [disabled]="passwordForm.invalid"> <span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span> enregistrez</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-2 border-bottom">
                <div class="col-9 pl-0">
                    <h4 class="heading text-left border-0">Mes informations personnelles</h4>
                </div>
                 <div class="col-3">
                    <h4 class="heading1 text-right" data-toggle="modal" data-target="#section-2" (click)="setFormValues(2)"> <u>Modifiez</u> </h4>
                 </div>
            </div>
            <div class="row m-2">
                <div class="col pl-0">
                    <h4 class="user-label">Civilité <sup>*</sup></h4>
                    <p class="user-info">{{userObj.civility}}</p>
                </div>
            </div>
           <div class="row m-2">
              <div class="col-md-3 col-lg-3 col-xs-12 col-sm-12 pl-0">
                <h4 class="user-label">Nom <sup>*</sup></h4>
                <p class="user-info">{{userObj.family_name}}</p>

              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 col-sm-12 pl-0 ">
                    <h4 class="user-label">Prénom <sup>*</sup></h4>
                    <p class="user-info">{{userObj.first_name}}</p>

              </div>
           </div>
            <div class="modal" id="section-2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title ml-auto" id="exampleModalLongTitle">MODIFIER MES INFORMATIONS</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" (click)="clearForm(2)">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form class="text-center sign-up-form" [formGroup]="personalInfoForm">
                                <div class="mb-2 text-left">
                                    <select id="civility" class="m-3" formControlName="civility" [(ngModel)]="defaultValue"
                                        (change)="onCivilitychange($event)"
                                        [ngClass]="{ 'error': (!isValidGender && fields_personalInfoForm_form.civility.touched) || (fields_personalInfoForm_form.civility.touched && defaultValue==='Sélectionner'),'success':fields_personalInfoForm_form.civility.dirty && isValidGender}">
                                        <option *ngFor="let gender of genders" [selected]="gender.label === 'Sélectionner'"
                                            [value]="gender.value">
                                            {{ gender.label }}
                                        </option>
                                    </select>
                                    <div class="error-container">
                                        <small
                                            *ngIf="fields_personalInfoForm_form.civility.touched && defaultValue==='Sélectionner'"
                                            class="error-msg text-center ml-3">
                                            Civilité obligatoire
                                        </small>
                                        <small
                                            *ngIf="!isValidGender && fields_personalInfoForm_form.civility.touched && defaultValue==='Sélectionner'"
                                            class="error-msg text-center">
                                            veuillez renseigner votre civilité
                                        </small>
                                    </div>
            
                                </div>
                                <div class="form-group m-3">
                                    <input type="text" name="lastName" formControlName="nom"
                                        [ngClass]="{ 'error': fields_personalInfoForm_form.nom.touched && fields_personalInfoForm_form.nom.invalid,'success':fields_personalInfoForm_form.nom.touched && fields_personalInfoForm_form.nom.valid }"
                                        required autocomplete="disabled" spellcheck="false" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="lastName">Nom <sup>*</sup></label>
                                    <div class="text-left error-container">
                                        <small
                                            *ngIf="fields_personalInfoForm_form.nom.touched && fields_personalInfoForm_form.nom.invalid"
                                            class="error-msg">
                                            <span *ngIf="fields_personalInfoForm_form.nom.errors.required">Nom obligatoire</span>
                                        </small>
                                        <small
                                            *ngIf="fields_personalInfoForm_form.nom.errors?.minlength && fields_personalInfoForm_form.nom.touched"
                                            class="error-msg">
                                            <span>Nom doit être de 2 caractères minimum</span>
                                        </small>
                                    </div>
                                </div>
            
                                <div class="form-group m-3">
                                    <input type="text" name="prenom" id="prenom" formControlName="prenom" required
                                        autocomplete="disabled"
                                        [ngClass]="{ 'error': fields_personalInfoForm_form.prenom.touched && fields_personalInfoForm_form.prenom.invalid,'success':fields_personalInfoForm_form.prenom.touched && fields_personalInfoForm_form.prenom.valid }" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="prenom">Prénom <sup>*</sup></label>
                                    <div class="text-left error-container">
                                        <small
                                            *ngIf="fields_personalInfoForm_form.prenom.touched && fields_personalInfoForm_form.prenom.invalid"
                                            class="error-msg">
                                            <span *ngIf="fields_personalInfoForm_form.prenom.errors?.required">Prénom
                                                obligatoire</span>
                                        </small>
                                        <small
                                            *ngIf="fields_personalInfoForm_form.prenom.errors?.minlength && fields_personalInfoForm_form.prenom.touched"
                                            class="error-msg">
                                            <span>Prénom doit être de 2 caractères minimum</span>
                                        </small>
            
                                    </div>
                                </div>
                            </form>
            
            
                        </div>
            
                        <div class="modal-footer border-0">
                            <button type="button" class="cancel-btn" (click)="clearForm(2)" data-dismiss="modal">Annulez</button>
                            <button type="button" class="register-btn" (click)="updatePersonalInfo()"><span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span>enregistrez</button>
                        </div>
                    </div>
                </div>
            </div>
            
            
            <div class="row m-2 border-bottom">
                <div class="col-9 pl-0">
                    <h4 class="heading text-left border-0">Mes Moyens de communication</h4>
                </div>
                <div class="col-3">
                    <h4 class="heading1 text-right" data-toggle="modal" data-target="#section-3"> <u>Modifiez</u> </h4>
                </div>
            </div>
            <div class="modal" id="section-3" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title ml-auto" id="exampleModalLongTitle">MODIFIER MES INFORMATIONS</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" (click)="clearForm(3)">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form class="text-center sign-up-form" [formGroup]="communicationForm">
                                <div class="form-group m-3">
                                    <input type="tel" formControlName="mobilePhone" maxlength="10" (keypress)="validateNumber($event)"
                                        [ngClass]="{ 'error': (fields_communicationForm_form.mobilePhone.touched && fields_communicationForm_form.mobilePhone.invalid) || (fields_communicationForm_form.mobilePhone.errors?.minlength && fields_communicationForm_form.mobilePhone.touched ) || (fields_communicationForm_form.mobilePhone.errors?.pattern && fields_communicationForm_form.mobilePhone.touched ) ,'success':fields_communicationForm_form.mobilePhone.touched && fields_communicationForm_form.mobilePhone.valid}"
                                        name="telephone" required autocomplete="nope">
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="prenom">Téléphone Mobile <sup>*</sup></label>
                                    <div class="text-left error-container">
                                        <small *ngIf="fields_communicationForm_form.mobilePhone.errors?.minlength && fields_communicationForm_form.mobilePhone.touched"
                                            class="error-msg text-left">
                                            <span>Numéro de téléphone doit être de 10 chiffres minimum</span>
                                        </small>
                                        <small *ngIf="fields_communicationForm_form.mobilePhone.errors?.maxlength && fields_communicationForm_form.mobilePhone.touched"
                                            class="error-msg text-left">
                                            <span>Numéro de téléphone doit de 10 chiffres maximum</span>
                                        </small>
                                        <small *ngIf="fields_communicationForm_form.mobilePhone.touched && fields_communicationForm_form.mobilePhone.invalid"
                                            class="error-msg text-left">
                                            <span *ngIf="fields_communicationForm_form.mobilePhone.errors.required">Numéro de téléphone
                                                mobile obligatoire</span>
                                        </small>
                                        <small
                                            *ngIf="fields_communicationForm_form.mobilePhone.touched && fields_communicationForm_form.mobilePhone.errors?.pattern && !fields_communicationForm_form.mobilePhone.errors?.minlength"
                                            class="error-msg text-left">
                                            <span>Numéro de téléphone mobile doit commencer par 06 ou 07</span>
                                        </small>
                                    </div>
                                </div>
                                    <div class="form-group m-3">
                                        <input type="email" name="email" formControlName="contact_email" required
                                            [ngClass]="{ 'error':fields_communicationForm_form.contact_email.touched && fields_communicationForm_form.contact_email.invalid ,'success':fields_communicationForm_form.contact_email.touched && fields_communicationForm_form.contact_email.valid }"
                                            required autocomplete="nope" />
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label for="email">Email <sup>*</sup></label>
                                        <div class="text-left error-container">
                                            <div *ngIf="fields_communicationForm_form.contact_email.errors">
                                                <p class="text-left mb-0">
                                                    <span *ngIf="fields_communicationForm_form.contact_email.touched && fields_communicationForm_form.contact_email.errors?.required"
                                                        class="error-msg">Email
                                                        obligatoire
                                                    </span>
                                                    <!-- <span *ngIf="fields_communicationForm_form.contact_email.touched && communicationForm.get('contact_email').hasError('emailExists')"
                                                        class="error-msg">
                                                        Un compte existe avec cet e-mail
                                                    </span> -->
                                                    <span
                                                        *ngIf="(fields_communicationForm_form.contact_email.touched && fields_communicationForm_form.contact_email.invalid) && (fields_communicationForm_form.contact_email.touched && fields_communicationForm_form.contact_email.errors?.pattern) "
                                                        class="error-msg">
                                                        Entrer un email valide
                                                    </span>
                                                </p>
                                    
                                            </div>
                                        </div>
                                    </div>
                            </form>
            
            
                        </div>
            
                        <div class="modal-footer border-0">
                            <button type="button" class="cancel-btn" (click)="clearForm(3)" data-dismiss="modal">Annulez</button>
                            <button type="button" class="register-btn" (click)="updateCommuncationInfo()"><span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span>enregistrez</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column m-2">
                <div class="col pl-0">
                    <h4 class="user-label">Téléphone Mobile(example:0600000000)</h4>
                    <p class="user-info">{{userObj.mobile_number}}</p>
                </div>
                <div class="col pl-0">
                    <h4 class="user-label">E-mail de conatct</h4>
                    <p class="user-info">{{userObj.contact_email}}</p>
                </div>
            </div>
            <div class="row m-2 border-bottom">
                <div class="col-9 pl-0">
                    <h4 class="heading text-left border-0">Mes communications</h4>
                </div>
                <!-- <div class="col-3">
                    <h4 class="heading1 text-right"> <u>Modifiez</u> </h4>
                </div> -->
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 m-2">
                    <h4 class="user-label text-dark">
                        Je consens à l’utilisation de mes données personnelles pour recevoir des offres personnalisées de la part de
                        Renault SAS
                        et des membres de son réseau commercial Par:
                    </h4>
            
                </div>
            </div>
            <div class="general-conditions">
                <!-- <form [formGroup]="marketingForm"> -->
                <div class="col-md-6 col-sm-12 ">
                    <div class="question" *ngFor="let marketOption of marketingOptions">
                        <span>{{marketOption.label}}<span class="star">*</span></span>
                        <span>
                            <span   *ngFor="let option of marketOption.options" >
                                <input type="radio" [name]="option.formControlName" class="mr-1"(change)="onChange(option.formControlName,option.value)"  [checked]="option.isChecked" [value]="option.value" >{{option.label}}
                            </span>
                           
                        </span>
                    </div>
                </div>
                <div class="text-center" *ngIf="isMktOptionTouched">
                    <button type="button" class="valider-btn" (click)="updateMarketingOptions()"> <span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span>validez</button>
                </div>
                
                <!-- </form> -->
            </div>
            <div class="row m-2 border-bottom">
                <div class="col-9 pl-0">
                    <h4 class="heading text-left border-0">Supprimer mon compte</h4>
                </div>
                <div class="col-3">
                    <h4 class="heading1 text-right" data-toggle="modal" data-target="#section-4"> <u>Supprimer</u> </h4>
                </div>
            </div>
            <div class="modal" id="section-4" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered col-4" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title ml-auto" id="exampleModalLongTitle">Supprimer mon compte</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" (click)="clearForm(4)">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form class="text-center sign-up-form" [formGroup]="deleteForm">
                                <div class="form-group mb-4">
                                    <i id="eye-show-1" *ngIf="!show_button_1" class="far fa-eye" (click)="showPassword(1)"></i>
                                    <i id="eye-hide-1" *ngIf="show_button_1" class="far fa-eye-slash" (click)="showPassword(1)"></i>
                                    <input [type]="show_button_1 ? 'text' : 'password'" name="password" formControlName="password"
                                        [ngClass]="{ 'error':fields_deleteForm_form.password.touched && fields_deleteForm_form.password.invalid ,'success':fields_deleteForm_form.password.touched && fields_deleteForm_form.password.valid }"
                                        required autocomplete="nope" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="newPassword">Saisissez votre mot de passe <sup>*</sup></label>
                                    <div class="text-left error-container" *ngIf="fields_deleteForm_form.password.errors">
                                        <small
                                            *ngIf="fields_deleteForm_form.password.touched && fields_deleteForm_form.password.invalid"
                                            class="error-msg">
                                            <span *ngIf="fields_deleteForm_form.password.errors?.required">Mot de passe
                                                obligatoire</span>
                                        </small>
                                    </div>
                                </div> 
                            </form>
                        </div>
            
                        <div class="modal-footer border-0" >
                            <button type="button" class="cancel-btn" (click)="clearForm(4)" data-dismiss="modal">Annulez</button>
                            <button type="button" class="register-btn" (click)="DeleteAccount()" [disabled]="deleteForm.invalid"><span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span>supprimer</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col m-2">
                    <h4 class="user-label text-dark">
                        Voulez-vous vraiment supprimer votre espace Top Renault ? Vous ne pourrez plus bénéficier des services
                        personnalisés pour
                        votre Renault.
                    </h4>
            
                </div>
            </div>
            </div>
               
            
        </div>
    </div>
        <!-- <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div> -->
    
  

</div>
