import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { RouterExtService } from '../services/router-ext.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(private routerExtService: RouterExtService,private router: Router ) { }

  ngOnInit() {
  }

  revenir(){
    let previous = this.routerExtService.getPreviousUrl();
    console.log("revenir previous",JSON.stringify(previous));
    if(previous)
   
      this.routerExtService.router.navigateByUrl(previous);
  }
}
