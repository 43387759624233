<div class="auto-container" style="max-width:1170px !important;"  @show>
    <section class="page-title" style="background-image:url(assets/images/background/home.jpg);">
        <div class="auto-container">

            <h1 class="text-uppercase">Top Renault</h1>
            <div class="page-subtitle">
                <h4 style="color:#fff !important;font-size: 18px!important;">le choix en ligne, le service en plus </h4>
            </div>
        </div>
    </section>
 <mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedTabValue" (selectedTabChange)="pushDataLayer()">
        <!--Tab1-->
        <mat-tab label="votre Renault/Dacia au meilleur tarif" >
            <div class="flex-container-one" style="margin:50px 0" >
                <div class="flex-item-one-left text-left">
                    <img src="{{getimageLink(tab_1.image1)}}">
                </div>
                <div class="flex-item-one-right">
                    <h4 style="padding:0 0 5px 0 !important;">
                        <strong> {{tab_1.Title1}}</strong>
                    </h4>
                    <h5>{{tab_1.Subtitle1}}</h5>
                    <p>
                        {{tab_1.Description1}}
                    </p>
                    <p style="line-height:1.6em !important">
                    </p>
                </div>
            </div>

            <div class="flex-container-two" style="margin:50px 0">
                <div class="flex-item-two-left">
                    <h4 style="padding:0 0 5px 0  !important;">
                        <strong> {{tab_1.Title2}}</strong>
                    </h4>
                    <h5>{{tab_1.Subtitle2}}</h5>
                    <p>
                        {{tab_1.Description2}}
                    </p>
                </div>
                <div class="flex-item-two-right text-right">
                    <img src="{{getimageLink(tab_1.image2)}}">
                </div>
            </div>

            <div class="flex-container-three" style="margin:50px 0">
                <div class="flex-item-three-left">
                    <img src="{{getimageLink(tab_1.image3)}}">
                </div>
                <div class="flex-item-three-right">
                    <h4 style="padding:0 0 5px 0 !important;">
                        <strong> {{tab_1.Title3}}</strong>
                    </h4>
                    <h5>{{tab_1.Subtitle3}}</h5>
                    <p>
                        {{tab_1.Description3}}
                    </p>
                </div>
            </div>
           
            <div style="text-align: center;text-transform:uppercase;margin-bottom:30px">
                <a class="decouvrir-btn" routerLink="/liste">Découvrir les véhicules</a>
            </div>
        </mat-tab>


        <!--Tab2-->
        <!-- <mat-tab label="label Top Renault" >
            <h4 id="aquirir" class="text-center" style="margin:30px 0;text-transform:Normal;padding-bottom: 20px;" >
                <strong> {{tab_2.Title}} </strong>
            </h4>
            <h5 id="unachat" class="text-center" style="margin:20px 0;font-weight:500;">
                {{tab_2.Subtitle}}
            </h5>

            <div class="row" style="margin:50px 0 10px 0">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-left">
                    <img src="{{getimageLink(tab_2.image)}}">
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" id="label-top-renault-content">
                    <p style="margin-bottom:0 !important;"> {{tab_2.liste1.intro}}</p>
                    <ul class="label-list">
                        <li>{{tab_2.liste1.valeur1}}</li>
                        <li>{{tab_2.liste1.valeur2}}</li>
                        <li>{{tab_2.liste1.valeur3}}</li>
                    </ul>
                    <p style="margin-bottom:0 !important;">{{tab_2.liste2.intro}}</p>
                    <ul class="label-list">
                        <li>{{tab_2.liste2.valeur1}}</li>
                        <li>{{tab_2.liste2.valeur2}}</li>
                        <li>{{tab_2.liste2.valeur3}}</li>
                    </ul>
                    <p style="margin-bottom:0 !important;">{{tab_2.fin}}</p>
                    <div style="text-align: center;text-transform:uppercase;margin-bottom:30px">
                        <a id="decouvrir-link-mobile" routerLink="/liste">Découvrir les véhicules</a>
                    </div>
                    <div style="text-align: center;margin-bottom:30px;margin-top:30px;">
                        <a id="Rechercher-un-vehicule" routerLink="/liste">Rechercher un véhicule</a>
                    </div>
                </div>
            </div>
            <section id="wrapper-questinaire">
                    <div class="panel panel-default">
                            <div [ngClass]="isCollapsed1 ? 'panel-heading1 isCollapsed' : 'panel-heading'" role="tab" id="heading-1">
                                <h4 class="text-left">
                                    <a class="collapsed" (click)="toggleClass('first')" role="button" data-toggle="collapse"  href="#collapse-1"
                                        aria-expanded="false" aria-controls="collapse-1">
                                            <strong>
                                                <span [ngClass]="isCollapsed1 ? 'isCollpsed-Color' : ''"> {{tab_2.collapse1.intro}}</span>
                                                <span style="float: right;font-size:18px;">
                                                    <i [ngClass]="isCollapsed1 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                                                </span>
                                            </strong>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse-1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-1">
                                <div class="panel-body">
                                    <ul class="label-list" style="padding: 15px !important">
                                        <li> {{tab_2.collapse1.valeur1}}</li>
                                        <li> {{tab_2.collapse1.valeur2}}</li>
                                        <li> {{tab_2.collapse1.valeur3}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
            
                        <div class="panel panel-default">
                            <div [ngClass]="isCollapsed2 ? 'panel-heading1 isCollapsed ' : 'panel-heading'" role="tab" id="heading-2">
                                <h4 class="text-left">
                                    <a class="collapsed" (click)="toggleClass('second')" role="button" data-toggle="collapse"  href="#collapse-2"
                                        aria-expanded="false" aria-controls="collapse-2">
                                            <strong>
                                                <span [ngClass]="isCollapsed2 ? 'isCollpsed-Color' : ''"> {{tab_2.collapse2.intro}}</span>
                                                <span style="float:right;font-size:18px;">
                                                    <i [ngClass]="isCollapsed2 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                                                </span>
                                            </strong>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse-2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-2">
                                <div class="panel-body" style="padding: 15px !important">
                                    {{tab_2.collapse2.valeur}}
                                </div>
                            </div>
                        </div>
            
                        <div class="panel panel-default">
                            <div [ngClass]="isCollapsed3 ? 'panel-heading1 isCollapsed ' : 'panel-heading'" role="tab" id="heading-3">
                                <h4 class="text-left">
                                    <a class="collapsed" (click)="toggleClass('third')" role="button" data-toggle="collapse"  href="#collapse-3"
                                        aria-expanded="false" aria-controls="collapse-3">
                                            <strong>
                                                <span [ngClass]="isCollapsed3 ? 'isCollpsed-Color' : ''">{{tab_2.collapse3.intro}} </span>
                                                <span style="float:right;font-size:18px;">
                                                    <i [ngClass]="isCollapsed3 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'"></i>
                                                </span>
                                            </strong>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse-3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-3">
                                <div class="panel-body" style="padding: 15px !important">
                                    {{tab_2.collapse3.valeur}}
                                </div>
                            </div>
                        </div>
            
                        <div class="panel panel-default">
                            <div [ngClass]="isCollapsed4 ? 'panel-heading1 isCollapsed ' : 'panel-heading'" role="tab" id="heading-4" style="border-bottom: 1px solid #ddd !important;">
                                    <h4 class="text-left">
                                    <a class="collapsed" (click)="toggleClass('fourth')" role="button" data-toggle="collapse"  href="#collapse-4"
                                        aria-expanded="false" aria-controls="collapse-4">
                                             <strong>
                                                <span [ngClass]="isCollapsed4 ? 'isCollpsed-Color' : ''"> {{tab_2.collapse4.intro}}</span>
                                                <span style="float: right;font-size:18px;">
                                                    <i [ngClass]="isCollapsed4 ? 'fas fa-chevron-up text-warning isCollapsed' : 'fas fa-chevron-down'" ></i>
                                                </span>
                                            </strong>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse-4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-4">
                                <div class="panel-body" style="padding: 15px !important">
                                    {{tab_2.collapse4.valeur}}
                                </div>
                            </div>
                        </div>

            </section>
            <div style="text-align: center;text-transform:uppercase;margin-bottom:30px">
                <a id="decouvrir-btn-label-top-renault" routerLink="/liste">Découvrir les véhicules</a>
            </div>
        </mat-tab> -->

        <!--Tab3-->
        <mat-tab label="vos contrats de services" >

            <div class="flex-container-one" style="margin:50px 0" >
                <div class="flex-item-one-left text-left">
                    <img src="{{getimageLink(tab_3.image1)}}" width="572" height="307">
                </div>
                <div class="flex-item-one-right">
                    <h4 style="padding:0 0 5px 0 !important;">

                        <strong> {{tab_3.Title1}}</strong>
                    </h4>
                    <h5>{{tab_3.Subtitle1}}</h5>
                        <ul class="label-list">
                            <li>{{tab_3.Description1.valeur1}}</li>
                            <li>{{tab_3.Description1.valeur2}}</li>
                        </ul>
                    <p style="line-height:1.6em !important">
                    </p>
                </div>
            </div>

            <div class="flex-container-two" style="margin:50px 0">
                <div class="flex-item-two-left">
                    <h4 style="padding:0 0 5px 0  !important;">
                        <strong> {{tab_3.Title2}}</strong>
                    </h4>
                    <h5>{{tab_3.Subtitle2}}</h5>
                        <ul class="label-list">
                            <li>{{tab_3.Description2.valeur1}}</li>
                            <li>{{tab_3.Description2.valeur2}}</li>
                            <li>{{tab_3.Description2.valeur3}}</li>
                            <li>{{tab_3.Description2.valeur4}}</li>
                            <li>{{tab_3.Description2.valeur5}}</li>
                        </ul>
                    
                </div>
                <div class="flex-item-two-right text-right">
                    <img src="{{getimageLink(tab_3.image2)}}">
                </div>
            </div>

            <!-- <div class="flex-container-three" style="margin:50px 0">
                <div class="flex-item-three-left">
                    <img src="{{getimageLink(tab_3.image3)}}">
                </div>
                <div class="flex-item-three-right">
                    <h4 style="padding:0 0 5px 0 !important;">
                        <strong> {{tab_3.Title3}}</strong>
                    </h4>
                    <h5>{{tab_3.Subtitle3}}</h5>
                    <p style="padding-top:15px;line-height:1.6em !important"></p>
                        <ul class="label-list">
                            <li>{{tab_3.Description3.valeur1}}</li>
                            <li>{{tab_3.Description3.valeur2}}</li>
                            <li>{{tab_3.Description3.valeur3}}</li>
                        </ul>
                </div>
            </div> -->
            <div style="text-align: center;text-transform:uppercase;margin-bottom:30px;margin-top:30px;">
                <a class="decouvrir-btn" routerLink="/liste">Découvrir les véhicules</a>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>