

<div class="auto-container" style="max-width:1170px !important;padding: 0 0 !important;">
 <div class="controls-container pt-3">
     <div class="control" (click)='backClicked()'>
        <span class="icon flaticon-left-arrow-2" style="cursor: pointer;"></span>
            <span class="retour-text" style="cursor: pointer;">
                Retour
            </span>
     </div>
     <div  class="control">
       <p class="vos-favoris-text">Vos Favoris</p> 
     </div>
 </div>
<div id="dekstop-view" *ngIf="carsList" class="row ml-0 mr-0 pt-4">
<div *ngFor="let car of carsList;let i=index;"
    class="car-block col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <div *ngIf="car!==undefined && car.productId!==undefined" class="inner-box"
      (click)="selectCar(car.productId)"  >

        <div class="img-container layerd"
            *ngIf="car.assets!==undefined && car.assets.length > 0 && car.assets[0].renditions!==undefined">
            <a>
                <img src="{{car.assets[0].renditions[0]?.url}}" alt="car-image" />
            </a>
            <div class="tgas-container">
                <div class="tags">
                    <div class="tag">
                        <div
                            *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined
                                                                                                                && car.prices[0].discounts.length > 0">
                            - {{car.prices[0].discounts[0]?.discountAmount | numberPipe | number:'':'fr'}} €

                        </div>
                    </div>
                    <div class="tag">
                        <!-- <div [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                            <span>Label top Renault </span>
                        </div> -->
                    </div>
                </div>
            </div>

        </div>
        <div class="wrapper">
            <div class="brand-wrapper">
                <p class="model">
                    {{car.model?.label | titlecase }}
                </p>
                <p class="version">
                    {{car.version?.label}}
                </p>
            </div>
            <div class="fuel-gear-wrapper">
                <span class="fuel">{{car.energy?.label }} </span>
                <span class="gear">{{car.transmission?.label | titlecase}}</span>
            </div>
            <div class="year-kms">
                <!-- <span> <span style="color:#cccccc"> | </span> {{car.topRenaultAd?.mileage}} km</span>
                                <span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy'}}</span> -->

                <span> {{car.topRenaultAd?.mileage}} km <span style="color:#cccccc"> | </span></span>
                <span class="date-dispo-future" *ngIf="today < car.topRenaultAd?.availabilityDate">
                    <span class="dispo-text">dispo</span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy' }}
                </span>
                <span class="date-dispo-passed" *ngIf="today > car.topRenaultAd?.availabilityDate">
                    disponible
                </span>

            </div>
            <div class="location">
                <span class="icon flaticon-location-pin"
                    style="font-size: 15px !important;font-weight: 700 !important;color: #777777c4 !important;"
                    aria-hidden="true"></span>
                <span class="department">département - {{car.topRenaultAd?.department}}</span>
            </div>
            <div class="price" *ngIf="car.prices!==undefined && car.prices!==null">
                <p>
                    <span class="original-price mb-1">prix public : {{car.originalPrices[0]?.priceWithTaxes | numberPipe
                        |
                        number:'':'fr'}}
                        € TTC</span>
                </p>
                <p class="prix-net">

                    prix net : {{car.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} €
                </p>
            </div>
            <div class="price-negotialble" [hidden]="!car.topRenaultAd?.negotiablePrice">
                <span>( prix à débattre )</span>
            </div>
            <div class="desktop-favoris">
               
                <div  class="retirer-favoris mr-1"
                    (click)='removeFromFavorite(car.productId)'> <span
                        class="bg-white p-2 retirer-favoris-inner pl-3 pr-3">
                        retirer des favoris <i class="fas fa-star"></i></span>
                </div>
            </div>
        </div>
    </div>
    <div class="inner-box" *ngIf="car.hasOwnProperty('error_reference')"
        style="background-color: #f3f5f6 !important;background-color: #f3f5f6;box-shadow: 0 1.5px 5px 0 rgb(0 0 0 / 10%);cursor: not-allowed;">
        <div>
            <div>
                <div class="layerd">
                    <a>
                        <img src="../../assets/images/placeholder-image.png" alt="" />
                    </a>
                </div>
            </div>
            <div >
                <div>
                    <p class="m-5 text-center" style="color: #999;">
                        désolé ce véhicule n'est pas disponible
    
                    </p>
                    <p class="mobile-version" style="color: #999;">
    
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
    <div id="mobile-view">
        <div *ngFor="let car of carsList ;let i=index;"
            class="car-block col-xs-12 m-2">
            <div  class="inner-box" *ngIf="car!==undefined && car.productId!==undefined && !car.hasOwnProperty('isError')" >
                <div class="flex-wrapper">
                    <div class="flex-item-1">
                        <div class="mobile-image layerd"
                            *ngIf="car.assets!==undefined && car.assets.length > 0 && car.assets[0].renditions!==undefined">
                            <a>
                                <img src="{{car.assets[0].renditions[0]?.url}}" alt="car-image" />
                            </a>
                            <!-- [hidden]="!car.topRenaultAd?.topRenaultLabelFlag" -->
                            <!-- <div class="mobile-label-topRenault" [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                                <span>Label top Renault </span>
                            </div> -->
                        </div>
    
                        <div class="mobile-price" *ngIf="car.prices!==undefined && car.prices.length > 0">
    
                            <p> <span class="mobile-original-price">{{car.originalPrices[0]?.priceWithTaxes | numberPipe |
                                    number:'':'fr'}}
                                    € </span>
                                {{car.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} €</p>
                        </div>
                        <div class="mobile-price-negotialble" style="padding:-10px 0 !important;"
                            [hidden]="!car.topRenaultAd?.negotiablePrice">
                            <span style="padding-left: 5%;font-size: 12px;">( prix à débattre )</span>
                        </div>
                    </div>
                    <div class="flex-item-2">
                        <div class="mobile-brand-wrapper">
                            <p class="mobile-model">
                                {{car.model?.label | titlecase }}
                            </p>
                            <p class="mobile-version">
                                {{car.version?.label}}
                            </p>
                        </div>
                        <div class="mobile-fuel-gear-wrapper">
                            <span class="mobile-fuel">{{car.energy?.label }} </span>
                            <span class="mobile-gear">{{car.transmission?.label | popPipe | titlecase}}</span>
                        </div>
                        <div class="mobile-year-kms">
                            <span>{{car.topRenaultAd?.mileage}} km <span style="color:#cccccc"> |</span></span>
                            <span class="date-dispo-future" *ngIf="today < car.topRenaultAd?.availabilityDate">
                                <span class="dispo-text">dispo</span>{{car.topRenaultAd?.availabilityDate |
                                date:'dd-MM-yyyy' }}
                            </span>
                            <span class="date-dispo-passed" *ngIf="today > car.topRenaultAd?.availabilityDate">
                                disponible
                            </span>
                            <!-- <span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy'}} 
                                                                 <span style="font-size: 12px !important;color: #777777c4 !important;">(Date dispo) </span> 
                                                            </span> -->
    
                        </div>
                        <div class="mobile-remise"
                            *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined ">
                            - {{car.prices[0].discounts[0]?.discountAmount | numberPipe | number:'':'fr'}} €
                        </div>
                        <div class="mobile-location" style="padding: 0 0 5px 0 !important;">
                            <span class="mobile-icon flaticon-location-pin"
                                style="font-size: 12px !important;font-weight: 700 !important;color: #777777c4 !important;"
                                aria-hidden="true "></span>
                            <span class="mobile-department">{{car.brand?.label}} - département -
                                {{car.topRenaultAd?.department}}</span>
                                <div  class="retirer-favoris text-left mr-1 mt-3"
                                    (click)='removeFromFavorite(car.productId)'> <span class="bg-white p-2 retirer-favoris-inner pl-3 pr-3 border rounded-pill">
                                        retirer des favoris <i class="fas fa-star"></i></span>
                                </div>
                        </div>
    
                    </div>
                </div>
            </div>
                <div class="inner-box" *ngIf="car.hasOwnProperty('error_reference')" style="background-color: #f3f5f6 !important;background-color: #f3f5f6;box-shadow: 0 1.5px 5px 0 rgb(0 0 0 / 10%);">
                    <div class="flex-wrapper">
                        <div class="flex-item-1">
                            <div class="mobile-image layerd"
                                >
                                <a>
                                    <img src="../../assets/images/placeholder-image.png" alt="" />
                                </a>
                                <div class="mobile-label-topRenault" style="background-color: #f3f5f6" [hidden]="car.is_negotiable">
                                    <span style="color: #999;background-color: #f3f5f6"> </span>
                                </div>
                            </div>
                
                            <div class="mobile-price">
                
                                <p> <span class="mobile-original-price" style="color: #999 ;">
                                      </span>
                                  <span style="color: #999;"></span>  </p>
                            </div>
                            <div class="mobile-price-negotialble" style="padding:-10px 0 !important;"
                                [hidden]="!car.is_negotiable">
                                <span style="padding-left: 5%;font-size: 12px;color: #999;"></span>
                            </div>
                        </div>
                        <div class="flex-item-2">
                            <div class="mobile-brand-wrapper">
                                <p class="mobile-model" style="color: #999;">
                                    désolé ce véhicule n'est pas disponible
                                   
                                </p>
                                <p class="mobile-version" style="color: #999;">
                                  
                                </p>
                            </div>
                            <div class="mobile-fuel-gear-wrapper">
                                <span class="mobile-fuel" style="color: #999;">{{car.fuel_type }} </span>
                                <span class="mobile-gear" style="color: #999;">{{car.gear_type | popPipe | titlecase}}</span>
                            </div>
                            <div class="mobile-year-kms">
                                <span style="color: #999;">{{car.km }} km <span style="color:#cccccc"> |</span></span>
                                <span class="date-dispo-future" *ngIf="today < car.topRenaultAd?.availabilityDate">
                                    <span class="dispo-text">Dispo</span>{{car.topRenaultAd?.availabilityDate |
                                    date:'dd-MM-yyyy' }}
                                </span>
                                <span style="color: #999;">
                                    Non Disponible
                                </span>
                
                
                            </div>
                            <div class="mobile-remise" style="color: #fff;background-color: #999;">
                                - {{car.discounted_price | numberPipe | number:'':'fr'}} €
                            </div>
                            <div class="mobile-location" style="padding: 0 0 5px 0 !important;">
                                <span class="mobile-icon flaticon-location-pin"
                                    style="font-size: 12px !important;font-weight: 700 !important;color: #777777c4 !important;"
                                    aria-hidden="true "></span>
                                <span class="mobile-department" style="color: #999;">{{car.brand?.label}} - Département -
                                    {{car.dept}}</span>
                                <div class="retirer-favoris text-left mr-1 mt-3" (click)='removeFromFavorite(car.productId)'> <span
                                        class="p-2 retirer-favoris-inner pl-3 pr-3 border rounded-pill" style="background-color: #f3f5f6;color:#999">
                                        retirer des favoris <i class="fas fa-star"></i></span>
                                </div>
                            </div>
                
                        </div>
                    </div>
                </div>
      
  
        </div>
    </div>
<div class="text-center no-result-text pb-4 pt-3 mt-5"  *ngIf='isLoading'>
    <span class="loading text-center mt-3 mb-3">
        <div>
            <img src="../../assets/images/ring-spinner.gif" alt="spinner" width="120" height="120">
        </div>
    chargement...
    </span>
    <span>
       
    </span> 
</div>
<div class="text-center no-result-text pb-4" *ngIf="!carsList.length && !isLoading">
    votre recherche n'a retourné aucun résultat
</div>

</div>
 

