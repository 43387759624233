<div class="container">
        <div class="auto-container-error" >
            <!-- <div class="background">
               <p class="error-number"> 404</p>
            </div>        -->
            <div class="error-number">
                <!-- <p class="error-number"> 500</p> -->
                <p>404</p>
             </div> 
              <div class="image-error">
                        <img src="assets/images/background/Renault_clio_error.png"  alt="Renault_clio_error">                
                        <div ><strong>OUPS ! La page que vous cherchez n'existe pas.</strong>
                        </div>
                    <br/>
                    <br/>
                <button routerLink="" class="btn-number btn-warning-number">REVENIR</button> 
               </div>                                     
        </div>
</div>
