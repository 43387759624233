import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { RouterExtService } from '../services/router-ext.service';

@Component({
  selector: 'app-bad-request',
  templateUrl: './bad-request.component.html',
  styleUrls: ['./bad-request.component.css']
})
export class BadRequestComponent implements OnInit {

  constructor(private routerExtService: RouterExtService,private router: Router ) { }

  ngOnInit() {
  }

  revenir(){
    let previous = this.routerExtService.getPreviousUrl();
    if(previous)
   
      this.routerExtService.router.navigateByUrl(previous);
  }

}
