import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BaseComponent } from './base/base.component';
import { CarListComponent } from './car-list/car-list.component';
import { CarDetailsComponent } from './car-details/car-details.component';
import { HttpClientModule } from '@angular/common/http';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { Ng5SliderModule } from 'ng5-slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MatTabsModule } from '@angular/material/tabs';
import { EngagementsComponent } from './engagements/engagements.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CarListViewComponent } from './car-list-view/car-list-view.component';
import { ReloadListComponent } from './reload-list/reload-list.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopPipePipe } from './car-list/pop-pipe.pipe';
import { NumberPipe } from './home/number.pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ErrorComponent } from './error/error.component';
import { BadRequestComponent } from './bad-request/bad-request.component';
import { InfoLegalComponent } from './info-legal/info-legal.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxCaptchaModule } from 'ngx-captcha';
import { OrderModule } from 'ngx-order-pipe';
import { NosOffresComponent } from './nos-offres/nos-offres.component';
import { UserAuthoriseComponent } from './user-authorise/user-authorise.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { NgbModule, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ToastrModule } from 'ngx-toastr';
import { FavoritesComponent } from './favorites/favorites.component';
import { AccountConfirmComponent } from './account-confirm/account-confirm.component';
import { AccountActivateComponent } from './account-activate/account-activate.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
// <- import OrderModule
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BaseComponent,
    CarListComponent,
    CarDetailsComponent,
    EngagementsComponent,
    CarListViewComponent,
    ReloadListComponent,
    NotFoundComponent,
    PopPipePipe,
    NumberPipe,
    ErrorComponent,
    BadRequestComponent,
    InfoLegalComponent,
    NosOffresComponent,
    UserAuthoriseComponent,
    UserProfileComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    FavoritesComponent,
    AccountConfirmComponent,
    AccountActivateComponent
  ],
  imports: [
    OrderModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    Ng5SliderModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    BrowserAnimationsModule,
    MatTabsModule,
    TypeaheadModule.forRoot(),
    NgSelectModule,
    NgxPaginationModule,
    FilterPipeModule,
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(
      {
        timeOut: 3000,
        positionClass: 'toast-top-full-width',
        preventDuplicates: true
      }
    ),
    NgxCaptchaModule,
    NgbModule,
    NgImageFullscreenViewModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [CarListComponent]
})
export class AppModule { }
