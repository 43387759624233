import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationStart } from '@angular/router';
import { NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { ToprenaultService } from '../services/toprenault.service';
declare var jQuery: any;

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {

  public isFirstMenu: Boolean = false;
  public loading: boolean;
  public favorisCount = 12;
  public isUserConnected: boolean = false;
  public menuToggle: boolean = false;
  public userObj;
  public currentYr: number = Number((new Date()).getFullYear());
  public siteDevelopedYr: number = 2017;
  public user = {
    family_name: "",
    first_name: "",
  };

  public userObjInsession;
  constructor(private route: ActivatedRoute,
    private router: Router, private topRenaultservice: ToprenaultService) {

  }



  ngOnInit() {


    this.runJS();
    jQuery("#resp_menu").hide();
    this.toggleMenu();
    this.topRenaultservice.favorisCountNext$.subscribe(count => {
      this.favorisCount = count
    })
  }


  userProfile() {
    jQuery('#menuModal').modal('hide');
    this.router.navigate(['/user-profile']);
  }
  toggleUser() {
    this.menuToggle = !this.menuToggle;
  }
  ngAfterViewInit() {
    this.router.events.subscribe((event: Event) => {
      setTimeout(() => {
        if (event instanceof NavigationStart) {
          this.loading = true;
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          this.loading = false;
        }

      }, 0);
    });
  }

  goToPage(type) {
    this.router.navigate(['/engagements'], { queryParams: { page: type } });
  }



  goToPageCars() {
    let query = sessionStorage.getItem("query");
    if (query === null || query === undefined || query === '') {
      query = "";
    }
    this.router.navigate(['liste']);
  }

  favorites() {
    if ('userObj' in sessionStorage || 'userObj' in localStorage) {
      this.router.navigate(['favorites']);
    } else {
      this.router.navigate(['/user-authorise']);
    }
  }

  toggleMenu() {
    if (this.isFirstMenu) {
      document.getElementById('lev1menu').classList.remove('hidelmenu');
      document.getElementById('lev1menu').classList.add('showlmenu');
      document.getElementById('lev2menu').classList.remove('showmenu');
      document.getElementById('lev2menu').classList.add('hidelmenu');
      document.getElementById('chevronmenu').className = 'icofont-thin-up';
      this.isFirstMenu = false;
      return;
    }

    if (!this.isFirstMenu) {
      document.getElementById('lev2menu').classList.remove('hidelmenu');
      document.getElementById('lev2menu').classList.add('showlmenu');
      document.getElementById('lev1menu').classList.remove('showmenu');
      document.getElementById('lev1menu').classList.add('hidelmenu');
      document.getElementById('chevronmenu').className = 'icofont-thin-down';
      this.isFirstMenu = true;
      return;
    }
  }

  showmenu() {
    jQuery("#resp_menu").show(500);
  }

  hidemenu() {
    jQuery("#resp_menu").hide(500);
  }

  runJS() {
    if (document.getElementById("topjquery")) {
      document.getElementById("topjquery").remove();
    }

    if (document.getElementById("topRenault")) {
      document.getElementById("topRenault").remove();
    }

    if (document.getElementById("jQueryui")) {
      document.getElementById("jQueryui").remove();
    }

    let topRenault = document.createElement("topRenault");
    topRenault.setAttribute("id", "topRenault");
    topRenault.setAttribute("src", "assets/js/script.js");

    let jQueryui = document.createElement("jQueryui");
    jQueryui.setAttribute("id", "jQueryui");
    jQueryui.setAttribute("src", "assets/js/jquery-ui.js");

    let topjquery = document.createElement("topjquery");
    topjquery.setAttribute("id", "topjquery");
    topjquery.setAttribute("src", "assets/js/jquery.js");

    document.body.appendChild(topjquery);
    document.body.appendChild(topRenault);
    document.body.appendChild(jQueryui);
  }
  trackRedirectMyRenault() {
    //GTM: On every page without exception example
    window.dataLayer.push({
      googleAccount: "UA-19376323-1",
      businessType: "used-car",
      pageType: "/list",
      leadID: undefined,
      formType: undefined,
      vehicleCategory: undefined,
      vehicleModel: undefined,
      inventoryId: undefined,
      optinStatus: undefined,
      loginStatus: undefined,
      countryCode: "FR",
      languageCode: "FR",
      formCategory: undefined,
      formName: undefined,
      searchFilters: undefined,
      searchTerm: undefined,
      resultsNumber: undefined,
      project: undefined,
      usedBrand: undefined,
      brand: undefined,
      pageName: "redirect-my-renault"
    })
  }

  trackRedirectRenaultOccasion() {
    //GTM: On every page without exception example
    window.dataLayer.push({
      googleAccount: "UA-19376323-1",
      businessType: "used-car",
      pageType: "/list",
      leadID: undefined,
      formType: undefined,
      vehicleCategory: undefined,
      vehicleModel: undefined,
      inventoryId: undefined,
      optinStatus: undefined,
      loginStatus: undefined,
      countryCode: "FR",
      languageCode: "FR",
      formCategory: undefined,
      formName: undefined,
      searchFilters: undefined,
      searchTerm: undefined,
      resultsNumber: undefined,
      project: undefined,
      usedBrand: undefined,
      brand: undefined,
      pageName: "redirect-renault-occasions"
    })
  }
}
