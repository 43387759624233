import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MeshNode } from '../models/mesh-models';
import { ToprenaultService } from '../services/toprenault.service';

@Injectable({
    providedIn: 'root'
})
export class CarFavoritesData implements Resolve<any> {

    constructor(private toprenaultService: ToprenaultService) { }
    resolve(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> {
        let user_id = sessionStorage.getItem('user_id') || localStorage.getItem('user_id');
        let favoritesListInLocalStorage = JSON.parse(localStorage.getItem('favoritesList'));
        let data;
        if (user_id !== null && user_id !== undefined) {
            data = this.toprenaultService.getAllFavorites({ favortitesList: favoritesListInLocalStorage }).toPromise()
                .catch(errorResponse => this.toprenaultService.handleError(next, errorResponse));
            return data;
        } else {
            data = { success: false }
        }

    }
}