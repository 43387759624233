<section @show>
    <div class="bg-lightblue">
        <div class="row">
            <div id="responsive-padding" class="col-xs-12 col-sm-12 col-md-8 col-lg-8 ">
                <div id="responsive-padding-child" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                    <div class="inventory-details">
                        <button class="retour-btn" (click)="returnResearch()">
                            <span class="icon flaticon-left-arrow-2"></span>
                            retour à la recherche
                        </button> <br />
                        <div class="product-carousel-outer">
                            <div id="desktop-lables">
                                <!-- <div [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                                    <span class="label-top-renault">
                                        Label Top Renault
                                    </span>
                                </div> -->
                                <div>
                                    <span>
                                        <div class="discount"
                                            *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined">
                                            <span class="percentage">
                                                <span style="padding-right: 5px;" *ngIf="car.prices !=undefined && car.prices.length>0 &&car.prices[0].discounts!=undefined && car.prices[0].discounts.length>0  && car.prices[0].discounts[0].discountAmount!==undefined && car.prices[0].discounts[0].discountAmount"> -
                                                    {{car.prices[0].discounts[0].discountAmount | numberPipe |
                                                    number:'':'fr'}}
                                                    €</span>
                                                <span style="border-left:1px solid #efefefad; padding-left: 10px;" *ngIf="discountPercentage!=undefined && discountPercentage!==null">- {{
                                                    discountPercentage | numberPipe
                                                    |
                                                    number:'':'fr'}} % </span>
                                            </span>
                                        </div>

                                    </span>
                                </div>

                            </div>


                            <div id="mobile-lables">
                                <div class="mobile-labels-container">

                                    <div class="mobile-lable-top-renault">
                                        <!-- <div [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                                            <div class="label-top-renault">
                                                Label Top Renault
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="mobile-discount-label">
                                        <div class="discount"
                                            *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined">
                                            <span class="percentage">
                                                <span style="padding-right: 5px;"
                                                  *ngIf="car.prices !=undefined && car.prices.length>0 &&car.prices[0].discounts!=undefined && car.prices[0].discounts.length>0  && car.prices[0].discounts[0].discountAmount!==undefined && car.prices[0].discounts[0].discountAmount!==''">
                                                  -
                                                  {{car.prices[0].discounts[0].discountAmount | numberPipe |
                                                  number:'':'fr'}}
                                                  €</span>
                                                <span style="border-left:1px solid #efefefad; padding-left: 10px;"
                                                  *ngIf="discountPercentage!=undefined && discountPercentage!==null && discountPercentage!=0 ">- {{
                                                  discountPercentage | numberPipe
                                                  |
                                                  number:'':'fr'}} % </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="big-image-outer mt-4" *ngIf="car.assets!==undefined && car.assets.length > 0 && car.assets[0].renditions!==undefined
                             && car.assets[1].renditions!==undefined">
                                <img *ngIf="exterieur" src="{{car.assets[0].renditions[0]?.url}}" height="14" width="14"
                                    alt="car image" @image-show>
                                <img *ngIf="interieur" src="{{car.assets[1].renditions[0]?.url}}" height="14" width="14"
                                    alt="car image" @image-show>
                                <div class="light centered view-controls" style="padding-bottom: 30px">

                                    <div class="view-control-container">
                                        <!-- <div class="view-control">
                                            <img src="../../assets/images/background/car-types-citadine.svg" height="30" width="30" alt="door">

                                        </div> -->
                                        <div class="view-control" [ngClass]="{'btnActive':exterieur}"
                                            (click)="toggleImage(1)">
                                            <img src="../../assets/images/background/car-types-citadine-outer.svg"
                                                height="30" width="30" alt="door">
                                        </div>
                                        <div class="view-control" [ngClass]="{'btnActive':interieur}"
                                            (click)="toggleImage(2)">
                                            <img src="../../assets/images/background/car-parts-seat.svg" height="30"
                                                width="30" alt="door">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="prod-thumbs-carousel owl-theme owl-carousel">

                            </div>

                        </div>
                        <div class="vehicle-details">
                            <div class="text-description ">
                                <div class="flex-container">
                                    <div class="flex-item">
                                        <p class="mb-0 pb-1">
                                            <img src="../../assets/images/door.svg" height="30"
                                                width="30" alt="door">
                                        </p>
                                        <p>{{nombrePorte}} Portes </p>
                                    </div>
                                    <div class="flex-item">
                                        <p class="mb-0 pb-1">
                                            <img src="../../assets/images/fuel-station.svg"
                                                height="30" width="30" alt="gas">
                                        </p>
                                        <p>{{car.energy?.label | titlecase }}</p>
                                    </div>
                                    <div class="flex-item">
                                        <p class="mb-0 pb-1">
                                            <img src="../../assets/images/gearbox.svg"
                                                height="30" width="30" alt="gear">
                                        </p>

                                        <p> {{car.transmission?.label}}</p>
                                    </div>
                                    <div class="flex-item">
                                        <p class="mb-0 pb-1">
                                            <img src="../../assets/images/dashboard.svg" height="30"
                                                width="30" alt="dashboard">
                                        </p>
                                        <p>{{car.topRenaultAd?.mileage }} Km </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- commentaire du venduer  -->
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 commentaire"
                            *ngIf="car.topRenaultAd.comment.length>0">
                            <strong> commentaire du vendeur : </strong>
                            <span id="comment" innerHTML={{car.topRenaultAd?.comment}}>
                                {{car.topRenaultAd?.comment}}</span>

                            <!-- <strong>
                                <u>
                                    <span class="text-primary" (click)="toggleComment()">{{btnTxt}}</span>
                                </u>
                            </strong> -->
                        </div>

                        <!--Custom Tabs-->
                        <div class="tabs-container nav-tabs-responsive">
                            <!-- Nav tabs -->

                            <ul class="nav nav-tabs " role="tablist">
                                <li style="width:12%;border-right: 1px solid #ccc;background: #fff !important;"></li>

                                <li class=" col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center tab-link">
                                    <a href="#charactéristques" class="active" role="tab" data-toggle="tab">
                                        CARACTÉRISTIQUES
                                    </a>
                                </li>
                                <li class="col-xs-12 col-sm-12 col-md-3 col-lg-3  text-center tab-link ">
                                    <a href="#équipments" role="tab" data-toggle="tab">
                                        ÉQUIPEMENTS
                                    </a>
                                </li>
                                <li class="col-xs-12 col-sm-12 col-md-3 col-lg-3  text-center tab-link">
                                    <a href="#options" role="tab" data-toggle="tab">
                                        OPTIONS
                                    </a>
                                </li>
                                <li class="tab-link"
                                    style="width:15%;border-left: 1px solid #ccc;background: #fff !important;"></li>


                            </ul>


                            <!-- Tab panes -->
                            <div class="tab-content">
                                <div class="tab-pane active in" id="charactéristques">

                                    <div style="position:relative;">
                                        <div class="panel panel-default"
                                            *ngFor="let technicalspecification of technicalspecifications;let odd=odd; let even=even;let i=index;">

                                            <div class="text-left panel-heading bg-lightblue-accordion" role="tab"
                                                [attr.id]="['heading-'+i]">
                                                <a (click)="toggleChartab(i)" class="collapsed" role="button"
                                                    data-toggle="collapse" [attr.href]="['#collapse-'+i]"
                                                    aria-expanded="false" [attr.aria-controls]="['collapse-'+i]">

                                                    <span>{{technicalspecification?.label | uppercase }}</span>
                                                    <i class="icon flaticon-plus-symbol float-right"></i>
                                                </a>
                                            </div>

                                            <div [attr.id]="['collapse-'+i]" class="panel-collapse collapse"
                                                role="tabpanel" [attr.aria-labelledby]="['heading-'+i]" @show>
                                                <div class="panel-body">
                                                    <div class="row"
                                                        style="font-family: RenaultLife-Regular !important;"
                                                        *ngFor="let presentationsGroupOrganizer of technicalspecification.presentationsGroupOrganizer;let odd=odd; let even=even;"
                                                        [ngClass]="{ odd: odd, even: even }">
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-left "
                                                            style="padding:8px 5px;">
                                                            {{presentationsGroupOrganizer.label}}</div>
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-left"
                                                            style="padding:8px 10px;border-left: 1px solid #fff;">
                                                            <strong>{{presentationsGroupOrganizer?.value}}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--EQUIPMENT TAB -->
                                <div class="tab-pane" id="équipments">
                                    <div style="position:relative;">
                                        <div class="panel panel-default"
                                            *ngFor="let equipement of equipements;let odd=odd; let even=even;let i=index;">

                                            <div class="text-left panel-heading bg-lightblue-accordion" role="tab"
                                                [attr.id]="['equip-heading-'+i]">
                                                <a (click)="toggleEquiptab(i)" class="collapsed" role="button"
                                                    data-toggle="collapse" [attr.href]="['#equipCollapse-'+i]"
                                                    aria-expanded="false" [attr.aria-controls]="['equipCollapse-'+i]">

                                                    <span>{{equipement?.label | uppercase}}</span>
                                                    <i class="icon flaticon-plus-symbol float-right"></i>
                                                </a>
                                            </div>

                                            <div [attr.id]="['equipCollapse-'+i]" class="panel-collapse collapse"
                                                role="tabpanel" [attr.aria-labelledby]="['equip-heading-'+i]">
                                                <div class="panel-body">
                                                    <ul>
                                                        <li style="font-family: RenaultLife-Regular !important;"
                                                            *ngFor="let presentationsGroupOrganize of equipement.presentationsGroupOrganizer;">
                                                            {{presentationsGroupOrganize?.label}} </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane" id="options">
                                    <div style="position:relative;">
                                        <div class="panel panel-default"
                                            *ngFor="let option of options;let odd=odd; let even=even;let i=index;">

                                            <div class="text-left panel-heading bg-lightblue-accordion" role="tab"
                                                [attr.id]="['option-heading-'+i]">
                                                <a (click)="toggleOptiontab(i)" class="collapsed" role="button"
                                                    data-toggle="collapse" [attr.href]="['#optionCollapse-'+i]"
                                                    aria-expanded="false" [attr.aria-controls]="['optionCollapse-'+i]">

                                                    <span>{{option?.label | uppercase}}</span>
                                                    <i class="icon flaticon-plus-symbol float-right"></i>
                                                </a>
                                            </div>

                                            <div [attr.id]="['optionCollapse-'+i]" class="panel-collapse collapse"
                                                role="tabpanel" [attr.aria-labelledby]="['option-heading-'+i]">
                                                <div class="panel-body">
                                                    <ul>
                                                        <li style="font-family: RenaultLife-Regular !important;"
                                                            *ngFor="let presentationsGroupOrganize of option.presentationsGroupOrganizer;">
                                                            {{presentationsGroupOrganize.label}} </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <!--Tabs Ends-->


                    </div>
                </div>

            </div>
            <div id="financement-side-bar" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">

                <div class="model-info-container">
                    <div class="model">
                        {{car.brand?.label}} {{car.model?.label}}

                    </div>
                    <div class="version">
                        {{car.version?.label}}
                    </div>
                    <div class="date-dispo-future-desktop" *ngIf="today < car.topRenaultAd?.availabilityDate">
                        Disponible le {{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy' }}
                    </div>
                    <div class="date-dispo-passed-desktop" *ngIf="today > car.topRenaultAd?.availabilityDate">
                        Disponible
                    </div>
                    <div class="price-container">
                        <div class="price-label">
                            prix net
                        </div>
                        <div class="price">
                            {{car.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} € <span
                                style="font-size: 14px;">TTC</span>
                        </div>
                        <div class="original-price" *ngIf="car.originalPrices!=undefined && car.originalPrices.length>0 && car.originalPrices[0].priceWithTaxes !=undefined && car.originalPrices[0].priceWithTaxes!=''">
                            prix public : {{car.originalPrices[0]?.priceWithTaxes | numberPipe | number:'':'fr'}} € TTC
                        </div>
                    </div>

                    <!-- <div class="row">
                        <div class="col-md-6"></div>
                    </div>
                    <div class="prix text-right" *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined">
                        <h1>
                            <strong style="font-family: 'Read-Bold' !important;"> {{car.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} € </strong>
                            <span>
                                <sup>
                                    <strong> TTC</strong>
                                </sup>
                            </span>
                                        <!-- <div class="price-negotialble" [hidden]="!car.topRenaultAd?.negotiablePrice">
                                            <span>( prix à débattre )</span>
                                        </div>
                        </h1>
                            <span class="prix-gagne" [hidden]="!car.topRenaultAd?.negotiablePrice"><span>( prix à débattre )</span></span>
                    </div> -->
                    <!-- <div class="mobile-prix-wrapper" *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined">
                        <div class="mobile-prix-container">
                          <div class="mobile-percentage-item">
                             <div id="mobile-remise">
                                 <span class="percentage">
                                         <span> - {{car.prices[0].discounts[0].discountAmount | numberPipe | number:'':'fr'}} €</span>
                                         <span style="border-left:1px solid #efefefad; padding-left: 6px;">- {{ discountPercentage | numberPipe | number:'':'fr'}} % </span>
                                 </span>
                             </div>
                         </div>
                         <div class="mobile-prix-neuf-item"  *ngIf="car.originalPrices!==undefined && car.originalPrices.length > 0">
                             <div id="mobile-prix-neuf">
                                  <span class="prix-neuf-libelle">Prix neuf : </span>
                                  <p class="prix-neuf-value">{{car.originalPrices[0]?.priceWithTaxes | numberPipe | number:'':'fr'}} € </p>
                             </div>
                         </div>
                         <div class="mobile-prix-item">
                            <div class="mobile-prix" >
                              <h1><strong> {{car.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} € </strong>
                                    <span><sup><strong> TTC</strong></sup></span>
                              </h1>
                            <span class="mobile-prix-gagne" [hidden]="!car.topRenaultAd?.negotiablePrice"><span>( prix à débattre )</span></span>
                          </div>
                         </div>
                        </div>
                    </div> -->

                    <form [formGroup]="userForm" id="interset-form" [ngClass]="isEmail?'isEmail':'isnotEmail'">
                        <ul class="nav1 nav-tabs1">
                            <li class="active">
                                <a href="#form-tab1" data-toggle="tab"></a>
                            </li>
                            <li>
                                <a href="#form-tab2" data-toggle="tab"></a>
                            </li>
                            <li>
                                <a href="#form-tab3" data-toggle="tab"></a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="form-tab1" @show>
                                <h5 style="color: #1f2532;padding-top:10px;text-align:center;">
                                    <strong id="step-heading-1">1/3 VOS INFORMATIONS DE CONTACT </strong>
                                </h5>
                                <div class="progress">
                                    <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="60"
                                        aria-valuemin="0" aria-valuemax="100" style="width: 33%">
                                    </div>
                                </div>
                                <p class="text-left ml-2" style="margin-top: 1rem !important;">
                                    <small id="champ-content">
                                        Tous les champs sont obligatoires.
                                    </small>
                                </p>

                                <div class="text-left ml-2 mr-2">
                                    <input type="radio" id="monsieur" name="sexe" formControlName="sexe"
                                        value="monsieur" name="sexe">
                                    <label for="monsieur" class="radio-inline">Monsieur</label>
                                    <input type="radio" id="madame" formControlName="sexe" value="madame" name="sexe">
                                    <label for="madame" class="radio-inline">Madame</label>
                                </div>
                                <small *ngIf="isContinue && userForm.controls.sexe.errors" class="error ml-2">
                                    <span *ngIf="userForm.controls.sexe.errors.required">*sexe obligatoire</span>
                                </small>

                                <div class="inputBox-container">
                                    <div class="inputBox ml-2 mr-2">
                                        <div class="inputText">Prénom *:</div>
                                        <input type="text" class="input" id="prenom" formControlName="prenom"
                                            autocomplete="on">

                                        <small *ngIf="isContinue && userForm.controls.prenom.errors"
                                            class="error text-right">
                                            <span *ngIf="userForm.controls.prenom.errors.required"> * prénom
                                                obligatoire</span>
                                        </small>
                                    </div>
                                    <div class="inputBox ml-2 mr-2">
                                        <div class="inputText">Nom *:</div>
                                        <input type="text" class="input" formControlName="nom" id="nom"
                                            autocomplete="on">

                                        <small
                                            *ngIf="isContinue && userForm.controls.nom.errors && userForm.controls.nom.invalid"
                                            class="error">
                                            <span *ngIf="userForm.controls.nom.errors.required"> * Nom
                                                obligatoire</span>
                                        </small>

                                    </div>
                                    <div class="inputBox ml-2 mr-2">
                                        <div class="inputText">Email *:</div>
                                        <input type="email" class="input" formControlName="email" id="email"
                                            autocomplete="on">
                                        <small *ngIf="isContinue && userForm.controls.email.errors"
                                            class="error text-right">
                                            <span *ngIf="userForm.controls.email.errors.required"> * Email
                                                obligatoire</span>
                                        </small>

                                        <small
                                            *ngIf="userForm.controls.email.invalid && userForm.controls.email.touched"
                                            class="error">
                                            <span> votre addresse email n'est pas valide</span>
                                        </small>
                                    </div>
                                    <div class="inputBox ml-2 mr-2">
                                        <div class="inputText">Téléphone *: </div>
                                        <input type="tel" class="input" id="phone" (keypress)="validateNumber($event)"
                                            minlength="10" maxlength="13" formControlName="phone" autocomplete="off">
                                        <small class="error">
                                            <span *ngIf="isContinue && userForm.controls.phone.errors.minlength"> *
                                                Numéro de téléphone min 10 chiffres</span>
                                        </small>
                                        <small class="error">
                                            <span *ngIf="userForm.controls.phone.errors.maxlength"> * Numéro de
                                                téléphone max 13 chiffres</span>
                                        </small>
                                        <small *ngIf="isContinue && userForm.controls.phone.errors.required"
                                            class="error">
                                            <span *ngIf="userForm.controls.phone.errors.required"> * Numéro de téléphone
                                                obligatoire</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="text-left" id="legals">

                                    <div id="legal-info">

                                        <p style="margin-bottom:0 !important;"><strong>Pour Renault, la protection de
                                                vos données personnelles est importante.</strong>
                                                <div>
                                                    Les données collectées sont traitées par Renault SAS, en tant que responsable de traitement, pour répondre à votre
                                                    demande.
                                                    Conformément à la réglementation applicable, vous pouvez demander à obtenir et vérifier les données que Renault détient
                                                    sur vous, rectifier des informations inexactes, effacer les données vous concernant, et emporter une copie de vos
                                                    données pour les réutiliser ailleurs. Vous pouvez également vous opposer à tout moment à ce que certaines de vos données
                                                    soient utilisées, notamment à des fins de prospection commerciale ou de profilage, et demander à geler l’utilisation de
                                                    vos données. Vous pouvez enfin définir des directives relatives au sort de vos données en cas de décès. Vous pouvez
                                                    exercer l’ensemble de vos droits directement auprès de Renault SAS en adressant sa demande via le formulaire
                                                    <a style="text-decoration:underline !important;" target="_blank"
                                                        href="https://www.renault.fr/vos-droits.html">https://www.renault.fr/vos-droits.html</a> ou un courrier postal à l’adresse : Renault SAS Direction juridique – Délégué à
                                                    la protection des données, 13/15 quai le Gallo 92100 Boulogne-Billancourt. A défaut de réponse ou de réponse
                                                    satisfaisante de la part de Renault, vous avez la possibilité d’introduire une réclamation auprès de la Commission
                                                    Nationale Informatique et Libertés (CNIL).
                                                    <p>
                                                        Pour en savoir plus, vous pouvez consulter notre <a style="text-decoration:underline !important ;font-weight:400 !important" target="_blank"
                                                            href="https://www.renault.fr/donnees-personnelles.html">politique de protection des données personnelles</a>
                                                    </p>

                                                </div>
                                    </div>
                                </div>

                                <div class="text-center continue-btn-container">
                                    <button class="continue-btn btnContinue" (click)="onContinue()">CONTINUEZ</button>
                                </div>
                                <div class="text-center annuler-btn-container">
                                    <button type="button" class="annuler-btn"
                                        (click)="onCancel('email');userForm.reset();">ANNULER</button>
                                </div>
                            </div>
                            <div class="tab-pane" id="form-tab2">
                                <h5 style="color: #1f2532;padding-top: 10px;text-align: center">
                                    <strong id="step-heading-2">2/3 FINALISEZ VOTRE DEMANDE </strong>
                                </h5>
                                <div class="progress">
                                    <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="60"
                                        aria-valuemin="0" aria-valuemax="100 " style="width: 66% ">

                                    </div>
                                </div>

                                <!-- <input [ngModelOptions]="{standalone: true}"  [(ngModel)]="apiResponseDescription" value= ngDefaultControl>{{apiResponseDescription}} </div> -->
                                <div class="form-group float-label-control m-2" style="margin-bottom: 0!important;">
                                    <textarea
                                        style="border: 1px solid #e6e6ec !important;padding: 10px !important;border-radius:4px !important; margin-top: 15px !important;"
                                        placeholder="Ecrivez votre message … " rows="5" cols="10" id="message"
                                        formControlName="message">
                                  </textarea>
                                </div>
                                <div class="text-center validate-btn-container" style="margin-top: 30px !important;">
                                    <div style="margin-left: 28px;">
                                        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()"
                                            (expire)="handleExpire()" (load)="handleLoad()"
                                            (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="size"
                                            [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha">
                                        </ngx-recaptcha2>
                                    </div>
                                    <input
                                        style="margin-bottom: 20px !important;text-align: center !important; border-bottom: none !important;font-family: Read-Regular;color:#E91630;"
                                        type="text" formControlName="desc" />
                                    <button [disabled]="!captchaSuccess" type="submit" (click)="onSubmit()"
                                        class="validate-btn btnNext">Validez</button>
                                </div>


                                <div class="text-center" style="margin-bottom: 30px !important">
                                    <button type="button" class="annuler-btn"
                                        (click)="onCancel('email')">ANNULER</button>
                                </div>
                            </div>
                            <div class="tab-pane" id="form-tab3">
                                <h5 style="color: #1f2532;padding-top:10px;text-align: center;">
                                    <strong id="step-heading-3">3/3 VOTRE DEMANDE A ETE TRANSMISE </strong>
                                </h5>
                                <div class="progress">
                                    <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="60"
                                        aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                    </div>
                                </div>
                                <div class="envelope-container text-center">
                                    <img src="../../assets/images/fancybox/email.svg" height="45" width="45"
                                        alt="envelope">
                                </div>
                                <div class="envelope-text">
                                    <p>Demande envoyée </p>
                                    <p>Nous nous efforcerons de vous contacter dans les plus brefs délais.</p>
                                </div>
                                <div class="text-center annuler-btn-container">
                                    <button type="button" class="annuler-btn"
                                        (click)="onCancel('email');userForm.reset();">FERMER</button>
                                </div>
                            </div>

                        </div>
                    </form>

                    <div id="btns-wrapper" class="text-center"
                        *ngIf="car.topRenaultAd?.mobileContact || car.topRenaultAd?.phoneContact || car.topRenaultAd?.emailContact">
                        <div
                            style="font-family: 'RenaultLife-Bold' !important;font-size: 18px !important;color: #000 !important;text-transform: lowercase;">
                            CONTACTEZ LE VENDEUR </div>
                        <div>

                            <button *ngIf="car.topRenaultAd?.mobileContact || car.topRenaultAd?.phoneContact"
                                class="call-btn" (click)="onCall()">
                                <!-- <span *ngIf="!isTelephone"  aria-hidden="true">
                                <img  class="text-left"  src="../../assets/images/phone-call-1.svg" height="20" width="20" alt="phone-call" >
                            </span> -->
                                <span *ngIf="!isTelephone">APPELER</span>

                                <div *ngIf="isTelephone">
                                    <span *ngIf="car.topRenaultAd?.mobileContact"
                                        [ngClass]="{'mobile-contact': car.topRenaultAd.mobileContact.length , 'no-mobile-contact':!car.topRenaultAd.mobileContact.length }"
                                        (click)="$event.stopPropagation();" @show>
                                        <img style="margin-right: 10px;" src="../../assets/images/phone-2.svg"
                                            height="19" width="19" alt="mobile-phone-smart">
                                        <a href="{{'tel:' + car.topRenaultAd.mobileContact}}">{{car.topRenaultAd.mobileContact}}
                                        </a>
                                    </span>
                                    <span *ngIf="car.topRenaultAd?.phoneContact"
                                        [ngClass]="{'phone-contact': car.topRenaultAd.phoneContact.length , 'no-phone-contact':!car.topRenaultAd.phoneContact.length }"
                                        (click)="$event.stopPropagation();" @show>
                                        <img style="margin-right: 10px;" src="../../assets/images/telephone.svg"
                                            height="19" width="19" alt="telephone">
                                        <a href="{{'tel:' + car.topRenaultAd.phoneContact }}">{{car.topRenaultAd.phoneContact}}
                                        </a>
                                    </span>
                                </div>

                            </button>
                        </div>
                        <div>


                            <button *ngIf="car.topRenaultAd?.emailContact" class="interest-btn btn-outline-warning mt-2"
                                (click)="onEmail()" style="text-transform:lowercase;">
                                <span>Je suis intéressé </span>
                            </button>
                        </div>
                    </div>
                    <div >
                        <div *ngIf='!isInFavorites' class="favoris text-center mr-auto ml-auto mb-4"
                            (click)='addToFavorite(car.productId)'> <span class="p-2 favoris-inner pl-3 pr-3">Ajouter
                                aux favoris <i class="far fa-star"></i></span> </div>
                        <div *ngIf='isInFavorites' class="retirer-favoris bg-light text-center mr-auto ml-auto"
                            (click)='removeFromFavorite(car.productId)'> <span
                                class="p-2 retirer-favoris-inner pl-3 pr-3">
                                Retirer des favoris <i class="fas fa-star"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 commentaire-mobile mt-4 mb-4"
                    *ngIf="car.topRenaultAd.comment.length>0">
                    <strong> commentaire du vendeur : </strong>
                    <span id="comment" innerHTML={{car.topRenaultAd?.comment}}> {{car.topRenaultAd?.comment}}</span>

                    <!-- <strong>
                                                <u>
                                                    <span class="text-primary" (click)="toggleComment()">{{btnTxt}}</span>
                                                </u>
                                            </strong> -->
                </div>

                        <section class="mobile-pannel-wrapper">
                            <div class="panel panel-default mobile-panel">
                                <div class="text-left panel-heading bg-lightblue-accordion" id="heading-100">
                                    <a (click)="toggleChartab(100)" class="collapsed" role="button" data-toggle="collapse"
                                        href="#collapse-100" aria-expanded="false" aria-controls="collapse-100">
                                        <span>Caractéristiques</span>
                                        <i style="float: right;font-size:20px;text-align:center;"
                                            class="icon flaticon-plus-symbol"></i>
                                    </a>
                                </div>

                                <div id="collapse-100" class="panel-collapse collapse" role="tabpanel"
                                    aria-labelledby="heading-100">
                                    <div class="char-filter-wrapper">
                                        <input type="text" [(ngModel)]="charFilter.label" placeholder="Recherche...">
                                        <button type="submit" disabled><i style="font-size: 18px;color: #1f2532;"
                                                class="icofont-search"></i></button>
                                    </div>
                                    <div class="panel-body">
                                        <div
                                            *ngFor="let technicalspecification of technicalspecifications;let odd=odd; let even=even;let i=index;">
                                            <div class="row" style="font-family:RenaultLife-Regular !important;"
                                                *ngFor="let presentationsGroupOrganizer of technicalspecification.presentationsGroupOrganizer | filterBy: charFilter;let odd=odd; let even=even;"
                                                [ngClass]="{ odd: odd, even: even }">
                                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                                                    style="padding:8px 5px;width:50% !important">
                                                    {{presentationsGroupOrganizer.label}}</div>
                                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                                                    style="padding:8px 10px;width:50% !important;border-left: 1px solid #fff;">
                                                    <strong>{{presentationsGroupOrganizer.value}}</strong>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default mobile-panel">
                                <div class="text-left panel-heading bg-lightblue-accordion" id="heading-101">
                                    <a (click)="toggleChartab(101)" class="collapsed" role="button" data-toggle="collapse"
                                        href="#collapse-101" aria-expanded="false" aria-controls="collapse-101">
                                        <span>Équipements</span>
                                        <i style="float: right;font-size:20px;text-align:center;"
                                            class="icon flaticon-plus-symbol"></i>
                                    </a>
                                </div>
                                <div id="collapse-101" class="panel-collapse collapse" role="tabpanel"
                                    aria-labelledby="heading-101">
                                    <div class="char-filter-wrapper">
                                        <input type="text" [(ngModel)]="equipFilter.label" placeholder="Recherche...">
                                        <button type="submit" disabled><i style="font-size: 17px;color: #1f2532;"
                                                class="icofont-search"></i></button>
                                    </div>
                                    <div class="panel-body">
                                        <div *ngFor="let equipement of equipements;let odd=odd; let even=even;let i=index;">
                                            <ul>
                                                <li style="font-family:RenaultLife-Regular !important;"
                                                    *ngFor="let presentationsGroupOrganize of equipement.presentationsGroupOrganizer | filterBy: equipFilter;">
                                                    {{presentationsGroupOrganize.label}} </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default mobile-panel">
                                <div class="text-left panel-heading bg-lightblue-accordion" id="heading-102">
                                    <a (click)="toggleChartab(102)" class="collapsed" role="button" data-toggle="collapse"
                                        href="#collapse-102" aria-expanded="false" aria-controls="collapse-102">
                                        <span>Options</span>
                                        <i style="float: right;font-size:20px;text-align:center;"
                                            class="icon flaticon-plus-symbol"></i>
                                    </a>
                                </div>
                                <div id="collapse-102" class="panel-collapse collapse" role="tabpanel"
                                    aria-labelledby="heading-102">
                                    <div class="panel-body">
                                        <div *ngFor="let option of options;let odd=odd; let even=even;let i=index;">
                                            <ul>
                                                <li style="font-family:RenaultLife-Regular !important;"
                                                    *ngFor="let presentationsGroupOrganize of option.presentationsGroupOrganizer;">
                                                    {{presentationsGroupOrganize.label}} </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                <div class="stay-with-top-renault-container">
                    <div class="file-icon">
                        <img style="fill:#fff !important;padding: 50px 10px;" class="mt-2"
                            src="../../assets/images/background/edit-sheet-warrantly-1.svg" alt="business-saving">

                    </div>
                    <div class="stay-text">
                        <p>restez serein sur TOPRENAULT.COM avec des milliers de véhicules collaborateurs Renault de -18
                            mois</p>
                        <p>bénéficiez de la garantie constructeur, mais aussi la garantie d’un véhicule entretenu dans
                            le réseau Renault
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>


</section>
