import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToprenaultService } from '../services/toprenault.service';
import { CustomValidators } from './custom-validators';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

declare var $;


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  private _success = new Subject<string>();
  public successMessage = '';
  public passwordForm: FormGroup;
  public personalInfoForm: FormGroup;
  public communicationForm: FormGroup;
  public deleteForm: FormGroup;
  public show_button_1: boolean = false;
  public show_button_2: boolean = false;
  public show_eye: boolean = false;
  public defaultValue: any;
  public isValidGender: boolean = true;
  public isGenderSelected: boolean = false;
  public userObj: any;
  public isMktOptionTouched: boolean = false;
  public marketingObj: any = { isEmail: "", isTelephone: "" };
  public passwordStars: string = "";
  public isLoading: boolean = false;

  public disPlayErrors: boolean = false;
  public genders: Array<Object> = [
    { label: "Sélectionner", value: "Sélectionner" },
    { label: "Madame", value: "Madame" },
    { label: "Monsieur", value: "Monsieur" }
  ];
  public reasons: Array<Object> = [
    { label: "Sélectionner", value: "Sélectionner" },
    { label: "Compte non utlisié", value: "Compte non utlisié" },
    { label: "Vente de véhicule", value: "Vente de véhicule" },
    { label: "Autre", value: "Autre" },
  ];
  public marketingOptions = [];
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;
  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private topRenaultservice: ToprenaultService, private router: Router, private cdRef: ChangeDetectorRef) {
    if ('userObj' in sessionStorage) {
      this.userObj = JSON.parse(sessionStorage.getItem('userObj'));
    } else if ('userObj' in localStorage) {
      this.userObj = JSON.parse(localStorage.getItem('userObj'));
    } else {
      this.router.navigate(['user-authorise']);
    }
  }

  ngOnInit() {

    this.setMarketingOptions();
    this.marketingObj.isEmail = this.userObj.email_marketing_option;
    this.marketingObj.isTelephone = this.userObj.sms_marketing_option;
    this.defaultValue = "Sélectionner";
    this.passwordForm = this.formBuilder.group({
      prevPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.compose([Validators.required,
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
      Validators.minLength(8)]))
    });

    this.personalInfoForm = this.formBuilder.group({
      civility: [null, Validators.required],
      prenom: [null, [Validators.required, Validators.minLength(2)]],
      nom: [null, [Validators.required, Validators.minLength(2)]]
    });
    this.defaultValue = this.userObj['civility'];

    this.personalInfoForm.patchValue({
      civility: this.userObj['civility'],
      prenom: this.userObj['first_name'],
      nom: this.userObj['family_name']
    });

    this.communicationForm = this.formBuilder.group({
      mobilePhone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^((06)|(07))[0-9]{8}$')]],
      contact_email: [null, [Validators.required, Validators.email, Validators.pattern('^(?:\s)?[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,}(?:\s)?$')]]
    });

    this.communicationForm.patchValue({
      mobilePhone: this.userObj['mobile_number'],
      contact_email: this.userObj['contact_email']
    });
    this.deleteForm = this.formBuilder.group({
      password: new FormControl('', Validators.required)

    });
    this.cdRef.detectChanges();
    this.marketingObj.isEmail = this.userObj.email_marketing_option;
    this.marketingObj.isTelephone = this.userObj.sms_marketing_option;

  }

  setMarketingOptions() {
    this.marketingOptions = [{
      name: 'isEmail', label: '-Email', options: [{ formControlName: 'isEmail', value: 'Y', id: 'isEmailY', label: 'oui', isChecked: this.userObj.email_marketing_option == 'Y' ? true : false, db_column: 'email_marketing_option' }, {
        formControlName: 'isEmail',
        value: 'N',
        id: 'isEmailN',
        label: 'non',
        isChecked: this.userObj.email_marketing_option == 'N' ? true : false,
        db_column: 'email_marketing_option'

      }

      ]
    },
    {
      name: 'isTelephone',
      label: '-SMS',
      options: [
        {
          formControlName: 'isTelephone',
          value: 'Y',
          label: 'oui',
          id: 'isTelephoneY',
          isChecked: this.userObj.sms_marketing_option == 'Y' ? true : false,
          db_column: 'sms_marketing_option'
        },
        {
          formControlName: 'isTelephone',
          value: 'N',
          label: 'non',
          id: 'isTelephoneN',
          isChecked: this.userObj.sms_marketing_option == 'N' ? true : false,
          db_column: 'sms_marketing_option'

        }

      ]
    }
    ];

  }

  validateNumber(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    // this.isTelephoneLength = true;
    return true;
  }
  setFormValues(num) {
    if (num == 2) {
      this.personalInfoForm.patchValue({
        civility: this.userObj['civility'],
        prenom: this.userObj['first_name'],
        nom: this.userObj['family_name']
      });
    }
  }
  get fields_passwordChange_form() {
    return this.passwordForm.controls;
  }
  get fields_personalInfoForm_form() {
    return this.personalInfoForm.controls;
  }
  get fields_communicationForm_form() {
    return this.communicationForm.controls;
  }
  get fields_deleteForm_form() {
    return this.deleteForm.controls;
  }

  clearForm(num) {
    if (num == 1) {
      this.passwordForm.reset();
    } else if (num == 2) {
      this.personalInfoForm.reset();
    } else if (num == 3) {
      this.communicationForm.reset();
    } else if (num == 4) {
      this.deleteForm.reset();
    }

  }

  modifyPassword() {
    this.isLoading = true;
    let user = Object.assign(this.passwordForm.getRawValue(), { user_id: this.userObj.user_id });
    this.topRenaultservice.updatePassword(user).subscribe(res => {
      if (res['success']) {
        let useObj = JSON.parse(sessionStorage.getItem('userObj'));
        useObj.password_length = res['password_length'];
        sessionStorage.setItem('userObj', JSON.stringify(useObj));
        this.userObj = JSON.parse(sessionStorage.getItem('userObj'))
        $('#section-1').modal('toggle');
        this.clearForm(1);
        this.isLoading = false;
        this.toastr.success(res['message']);
      } else {
        this.isLoading = false;
        this.toastr.error(res['message']);
      }
    })
  }

  updatePersonalInfo() {
    this.isLoading = true;
    let user = Object.assign(this.personalInfoForm.getRawValue(), { user_id: this.userObj.user_id });
    this.topRenaultservice.updatePersonalInfo(user).subscribe(res => {
      if (res['success']) {
        this.userObj.civility = user.civility;
        this.userObj.family_name = user.nom;
        this.userObj.first_name = user.prenom;
        this.topRenaultservice.setUser({
          family_name: user.nom,
          first_name: user.prenom
        })
        $('#section-2').modal('toggle');
        this.clearForm(1);
        this.isLoading = false;
        this.toastr.success(res['message']);
        sessionStorage.setItem('userObj', JSON.stringify(this.userObj));
      } else {
        this.isLoading = false;
        this.toastr.error(res['message']);
      }
    });
  }
  updateCommuncationInfo() {
    this.isLoading = true;
    let user = Object.assign(this.communicationForm.getRawValue(), { user_id: this.userObj.user_id });
    this.topRenaultservice.updateCommunicationInfo(user).subscribe(res => {
      if (res['success']) {
        this.userObj.mobile_number = user.mobilePhone;
        this.userObj.contact_email = user.contact_email;
        $('#section-3').modal('toggle');
        this.toastr.success(res['message']);
        sessionStorage.setItem('userObj', JSON.stringify(this.userObj));
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.toastr.error(res['message']);
      }
    });
  }

  updateMarketingOptions() {
    this.isLoading = true;
    let user = Object.assign(this.marketingObj, { user_id: this.userObj.user_id });
    this.topRenaultservice.updateMarketingInfo(user).subscribe(res => {
      if (res['success']) {
        this.userObj.email_marketing_option = this.marketingObj.isEmail;
        this.userObj.sms_marketing_option = this.marketingObj.isTelephone;
        this.isMktOptionTouched = false;
        this.toastr.success(res['message']);
        this.isLoading = false;
        sessionStorage.setItem('userObj', JSON.stringify(this.userObj));
      } else {
        this.isLoading = false;
        this.toastr.error(res['message']);
      }
    });
  }

  DeleteAccount() {
    this.isLoading = true;
    let user = Object.assign(this.deleteForm.getRawValue(), { user_id: this.userObj.user_id });
    console.log(user);
    this.topRenaultservice.deleteUser(user).subscribe(res => {
      if (res['success']) {
        $('#section-4').modal('toggle');
        this.toastr.success(res['message']);
        sessionStorage.removeItem('userObj');
        if ('userObj' in localStorage) {
          localStorage.removeItem('userObj');

        }
        this.topRenaultservice.setUser({
          family_name: '',
          first_name: ''
        });
        this.isLoading = true;
        this.topRenaultservice.changeUserConnection(false);
        this.clearForm(4);
        setTimeout(() => {
          this.router.navigate(['/user-authorise'])
        }, 1500);

      } else {
        this.isLoading = true;
        this.toastr.error(res['message']);
      }

    });

  }

  onChange(label, value) {
    this.isMktOptionTouched = true;
    if (label == 'isEmail') {
      this.marketingObj.isEmail = value;
    }
    if (label == 'isTelephone') {
      this.marketingObj.isTelephone = value;
    }
    console.log("mobj :" + JSON.stringify(this.marketingObj));
  }

  onCivilitychange(e) {
    if (e.target.value === "Sélectionner") {
      this.isValidGender = false;
      this.isGenderSelected = false;
    } else {
      this.isValidGender = true;
      this.isGenderSelected = true;
    }
  }

  showPassword(num) {
    if (num == 1) {
      this.show_button_1 = !this.show_button_1;
    }
    if (num == 2) {
      this.show_button_2 = !this.show_button_2;
    }

  }


  createPasswordStars(len) {
    this.passwordStars = "";
    let length = Number(len);
    for (let i = 0; i < length; i++) {
      this.passwordStars += '*';
    }
    return this.passwordStars;
  }

}
