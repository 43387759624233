import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToprenaultService } from '../services/toprenault.service';

@Component({
  selector: 'app-account-activate',
  templateUrl: './account-activate.component.html',
  styleUrls: ['./account-activate.component.css']
})
export class AccountActivateComponent implements OnInit {

  public signUpForm: FormGroup;
  public isCguValidated: boolean = false;
  public user_id;
  public user = {
    family_name: "",
    first_name: "",
  };
  constructor(private formBuilder: FormBuilder, private topRenaultservice: ToprenaultService, private router: Router, private route: ActivatedRoute) {
    this.user_id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    // this.signUpForm = this.formBuilder.group({
    //   isCguAccepted: [false, Validators.requiredTrue]
    //   // recaptcha: [null, Validators.required]
    // });
    this.topRenaultservice.activateAccount(this.user_id).subscribe(res => {
      sessionStorage.setItem('userObj', JSON.stringify(res));
      this.isCguValidated = true;
      sessionStorage.setItem('user_id', res['user_id']);
      sessionStorage.setItem('userObj', JSON.stringify(res));
      sessionStorage.setItem('favCount', JSON.stringify(0));
      this.topRenaultservice.setFavorisCount(0);
      this.user.family_name = res['family_name'];
      this.user.first_name = res['first_name'];
      this.topRenaultservice.setUser(this.user);
      this.topRenaultservice.changeUserConnection(true);
    });
  }

  submit() {

    this.topRenaultservice.activateAccount(this.user_id).subscribe(res => {
      sessionStorage.setItem('userObj', JSON.stringify(res));
      this.isCguValidated = true;
      sessionStorage.setItem('user_id', res['user_id']);
      sessionStorage.setItem('userObj', JSON.stringify(res));
      sessionStorage.setItem('favCount', JSON.stringify(0));
      this.topRenaultservice.setFavorisCount(0);
      this.user.family_name = res['family_name'];
      this.user.first_name = res['first_name'];
      this.topRenaultservice.setUser(this.user);
      this.topRenaultservice.changeUserConnection(true);
    });

  }

  profile() {
    this.router.navigate(['/user-profile'])
  }
  get fields_signUp_form() {
    return this.signUpForm.controls;
  }

}
