import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { Observable, of } from 'rxjs';
import { map, delay, switchMap } from 'rxjs/operators';
import { ToprenaultService } from '../services/toprenault.service';
import { CustomValidators } from './custom-validators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-authorise',
  templateUrl: './user-authorise.component.html',
  styleUrls: ['./user-authorise.component.css']
})
export class UserAuthoriseComponent implements OnInit {

  public logInForm: FormGroup;
  public signUpForm: FormGroup;
  public readonly siteKey = '6Ldor8cUAAAAAGF54mtqYILJHlf0CAobNHJaymS_';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'fr';
  public type: 'image' | 'audio';
  public isCaptha1: boolean = true;
  public isCaptha2: boolean = false;
  public isValidGender: boolean = true;
  public isGenderSelected: boolean = false;
  public defaultValue: any;
  public show_button: boolean = false;
  public show_eye: boolean = false;
  public isAccountCreated: boolean = false;
  public isCredentialsError: boolean = false;
  public isValidMobileNumber: boolean = false;
  public isEmailOrPasswordError: boolean = false;
  public isNoAccountFound: boolean = false;
  public errorMsg: string = '';
  public isError: boolean = false;
  public disPlayErrors: boolean = false;
  public isStayConnected: boolean = false;
  public isLoading: boolean = false;
  public user = {
    family_name: "",
    first_name: "",
  };
  html: string = `
<span class="btn btn-danger">Never trust not sanitized HTML!!!</span>`;

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  public genders: Array<Object> = [
    { label: "Sélectionner", value: "Sélectionner" },
    { label: "Madame", value: "Madame" },
    { label: "Monsieur", value: "Monsieur" }
  ];
  constructor(private formBuilder: FormBuilder, private router: Router, private topRenaultservice: ToprenaultService, private cdr: ChangeDetectorRef, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.defaultValue = "Sélectionner";

    this.logInForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email, Validators.pattern('^(?:\s)?[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,}(?:\s)?$')]],
      password: [null, Validators.required],
      recaptcha: [null, Validators.required]
    });

    this.signUpForm = this.formBuilder.group({
      civility: [null, Validators.required],
      prenom: [null, [Validators.required, Validators.minLength(2)]],
      password: [null, Validators.compose([Validators.required,
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
      Validators.minLength(8)])],
      nom: [null, [Validators.required, Validators.minLength(2)]],
      email: [null, [Validators.required, Validators.email, Validators.pattern('^(?:\s)?[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,}(?:\s)?$')],
        [this.emailExistsValidator()],
        'blur'
      ],
      mobilePhone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^((06)|(07))[0-9]{8}$')]],
      isEmail: [null, Validators.required],
      isCguAccepted: [false, Validators.requiredTrue],
      isTelephone: [null, Validators.required],
      recaptcha: [null, Validators.required]
    });
  }


  private emailExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return of(control.value).pipe(
        delay(500),
        switchMap((email) => this.topRenaultservice.doesEmailExist(email).pipe(
          map(emailExists =>
            emailExists ? { emailExists: true } : null)
        ))
      );
    };
  }


  onChange($event) {
    if ($event.target.checked) {
      this.isStayConnected = $event.target.checked;
    }
  }
  validateNumber(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    // this.isTelephoneLength = true;
    return true;
  }
  get fields_login_form() {
    return this.logInForm.controls;
  }
  get fields_signUp_form() {
    return this.signUpForm.controls;
  }

  logIn() {
    console.log(this.logInForm.getRawValue());
    this.isLoading = true;
    this.topRenaultservice.userLogin(this.logInForm.getRawValue()).subscribe(res => {
      if (res['code'] == 200) {
        this.isAccountCreated = false;
        this.toastr.success(res['message']);
        //set the user info in local storgae if user checks restez connecté !
        if (this.isStayConnected) {
          localStorage.setItem('user_id', res['user']['user_id']);
          localStorage.setItem('userObj', JSON.stringify(res['user']));
          localStorage.setItem('favCount', JSON.stringify(res['favorisCount']))
        }
        sessionStorage.setItem('user_id', res['user']['user_id']);
        sessionStorage.setItem('userObj', JSON.stringify(res['user']));
        sessionStorage.setItem('favCount', JSON.stringify(res['favorisCount']));
        this.topRenaultservice.setFavorisCount(res['favorisCount']);
        this.user.family_name = res['user']['family_name'];
        this.user.first_name = res['user']['first_name'];
        this.topRenaultservice.setUser(this.user);
        this.topRenaultservice.changeUserConnection(true);
        this.router.navigate(['/user-profile']);
        this.logInForm.reset();
        // this.handleReset();
        this.reset();
        this.isLoading = false;
      } else if (res['code'] == 204) {
        this.isAccountCreated = true;
        this.isEmailOrPasswordError = true;
        this.toastr.error(res['message']);
        // this.handleReset();
        this.reset();
        this.logInForm.markAllAsTouched;
        this.isError = true;
        this.isLoading = false;
      } else {
        this.isNoAccountFound = true;
        this.logInForm.markAllAsTouched;
        this.toastr.error(res['message']);
        this.isError = true;
        this.reset();
        this.isLoading = false;

      }
    })
  }

  gotToForgotPass() {
    this.router.navigate(['/forgot-password']);
  }

  signUp() {
    this.isLoading = true;
    this.topRenaultservice.createUser(this.signUpForm.getRawValue()).subscribe(res => {
      console.log("res", res);
      if (res['success']) {
        this.signUpForm.reset();
        this.toastr.success(res['message']);
        this.defaultValue = "Sélectionner";
        this.handleReset();
        this.isAccountCreated = true;
        this.reset();
        this.isLoading = false;
        this.router.navigate(['/account-confirm', { email: res['email'], id: res['user_id'] }]);
      } else {
        this.isLoading = false;
        this.toastr.error(res['message']);
      }
    })

  }




  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
  onCivilitychange(e) {
    if (e.target.value === "Sélectionner") {
      this.isValidGender = false;
      this.isGenderSelected = false;
    } else {
      this.isValidGender = true;
      this.isGenderSelected = true;
    }
  }

  onPasswordChange(e) {
    console.log(e.target.value);
  }

  resetErrors() {
    if (this.isNoAccountFound) {
      this.isNoAccountFound = false;
    } else if (this.isEmailOrPasswordError) {
      this.isEmailOrPasswordError = false;
    }
  }
  onChangeTab(num) {
    if (num == 1) {
      this.isCaptha1 = true;
      this.isCaptha2 = false;
    } else {
      this.isCaptha1 = false;
      this.isCaptha2 = true;
    }
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
    // this.captchaElem2.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset() {
    this.captchaElem.resetCaptcha();
    this.cdr.detectChanges();
    // this.captchaElem2.resetCaptcha();
  }
}


