import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ENV } from "../core/env.config";
import { Observable } from 'rxjs';
import { animate, state, transition, trigger, style, keyframes } from '@angular/animations';

@Component({
  selector: 'app-engagements',
  templateUrl: './engagements.component.html',
  styleUrls: ['./engagements.component.css'],
  animations: [
    trigger('show', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})

export class EngagementsComponent implements OnInit {
  public isCollapsed: boolean = true;
  public isCollapsed1: boolean = false;
  public isCollapsed2: boolean = false;
  public isCollapsed3: boolean = false;
  public isCollapsed4: boolean = false;
  public dataCms: any;
  public tab_1: any;
  public tab_2: any;
  public tab_3: any;
  public tabtype: any;
  public selectedTabValue: number;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private cdref: ChangeDetectorRef) {

    this.activeRoute.queryParams
      .subscribe(params => {
        this.tabtype = params.page;
      });
    sessionStorage.setItem('onglet', "engagements");
  }
  ngAfterContentChecked() {
    if (this.tabtype === "ltr") {
      this.selectedTabValue = 1;
      this.cdref.detectChanges();
    } else if (this.tabtype === "contrats") {
      this.selectedTabValue = 2;
      this.cdref.detectChanges();
    } else {
      this.selectedTabValue = 0;
      this.cdref.detectChanges();
    }


  }

  ngAfterViewChecked() {
    document.getElementsByClassName("mat-tab-header")[0].classList.remove("mat-tab-header-pagination-controls-enabled");

  }



  toggleClass(val) {

    switch (val) {
      case 'first':
        this.isCollapsed1 = !this.isCollapsed1;
        break;
      case 'second':
        this.isCollapsed2 = !this.isCollapsed2;
        break;
      case 'third':
        this.isCollapsed3 = !this.isCollapsed3;
        break;
      case 'fourth':
        this.isCollapsed4 = !this.isCollapsed4;
        break;
      default:
        this.isCollapsed1 = !this.isCollapsed1;
    }
  }

  ngOnInit() {
    let donnees = this.activeRoute.snapshot.data['cmsdata'] as Observable<any>;
    donnees.subscribe(tabs => {
      if (tabs === []) {
        this.router.navigate(['error']);
      }
      this.tab_1 = tabs['tab_1'][0];
      this.tab_2 = tabs['tab_2'][0];
      this.tab_3 = tabs['tab_3'][0];
    });




    //GTM: 
    if (sessionStorage.getItem("flagEngagementGtm") === undefined
      || sessionStorage.getItem("flagEngagementGtm") === null
      || sessionStorage.getItem("flagEngagementGtm") === 'false') {
      window.dataLayer.push({
        googleAccount: 'UA-19376323-1',
        businessType: 'used-car',
        pageType: 'Nos engagements',
        optinStatus: undefined,
        loginStatus: undefined,
        countryCode: 'FR',
        languageCode: 'FR',
        searchFilters: undefined,
        searchTerm: undefined,
        resultsNumber: undefined,
        brand: undefined,
        pageName: 'top-renault | Nos engagements'
      })
    } else {
      window.dataLayer.push({
        event: 'virtualPageview',
        googleAccount: 'UA-19376323-1',
        businessType: 'used-car',
        pageType: 'Nos engagements',
        leadID: undefined,
        formType: undefined,
        vehicleCategory: undefined,
        vehicleModel: undefined,
        inventoryId: undefined,
        optinStatus: undefined,
        loginStatus: undefined,
        countryCode: 'FR',
        languageCode: 'FR',
        formCategory: undefined,
        formName: undefined,
        searchFilters: undefined,
        searchTerm: undefined,
        resultsNumber: undefined,
        brand: undefined,
        pageName: 'top-renault | Nos engagements'
      })
    }
    sessionStorage.setItem("flagEngagementGtm", 'false');
    sessionStorage.setItem("flagHomePageGtm", 'true');
    sessionStorage.setItem('flagInventoryGtm', 'true');
  }

  getimageLink(path) {
    let chemin = ""
    if (path != undefined) {
      chemin = ENV.COCKPIT_URL + path.path;
    }

    return chemin;

  }


  pushDataLayer() {
    //GTM PageView
    window.dataLayer.push({
      event: 'virtualPageview',
      googleAccount: 'UA-19376323-1',
      businessType: 'used-car',
      pageType: 'Nos engagements',
      leadID: undefined,
      formType: undefined,
      vehicleCategory: undefined,
      vehicleModel: undefined,
      inventoryId: undefined,
      optinStatus: undefined,
      loginStatus: undefined,
      countryCode: 'FR',
      languageCode: 'FR',
      formCategory: undefined,
      formName: undefined,
      searchFilters: undefined,
      searchTerm: undefined,
      resultsNumber: undefined,
      brand: undefined,
      pageName: 'top-renault | Nos engagements'
    })
  }

}
