import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './base/base.component';
import { HomeComponent } from './home/home.component';
import { CarListComponent } from './car-list/car-list.component';
import { CarDetailsComponent } from './car-details/car-details.component';
import { EngagementsComponent } from './engagements/engagements.component';
import { HomeResolve } from './home/home-resolve';
import { EngagementsResolve } from './engagements/engagements-resolve';
import { CarListResolve } from './car-list/car-list-resolve';
import { CarDetailsResolve } from './car-details/car-details-resolve';
// import { CarListViewComponent } from './car-list-view/car-list-view.component';
// import { CarListViewResolve } from './car-list-view/car-list-view-resolve';
// import { ReloadListComponent } from './reload-list/reload-list.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ErrorComponent } from './error/error.component';
import { BadRequestComponent } from './bad-request/bad-request.component';
import { InfoLegalComponent } from './info-legal/info-legal.component';
import { NosOffresComponent } from './nos-offres/nos-offres.component';
import { NosOffresResolve } from './nos-offres/nos-offres.resolve';
import { UserAuthoriseComponent } from './user-authorise/user-authorise.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CarFavorisResolve } from './car-details/car-favoris-resolve';
import { FavoritesComponent } from './favorites/favorites.component';
import { CarFavoritesData } from './favorites/car-favorites-resolve';
import { AccountConfirmComponent } from './account-confirm/account-confirm.component';
import { AccountActivateComponent } from './account-activate/account-activate.component';



const routes: Routes = [
  {
    path: '', component: BaseComponent,

    children: [
      { path: '', component: HomeComponent, resolve: { topcars: HomeResolve } },
      {
        path: 'liste', component: CarListComponent,
        // resolve: {
        //   dataCarsList: CarListResolve
        // }
        // ,
        // children: [
        //   {
        //     path: 'cars', component: CarListViewComponent,
        //     // resolve: {
        //     //   dataCars: CarListViewResolve
        //     // },
        //   },
        //   { path: 'updating', component: ReloadListComponent }
        // ]
      },
      { path: 'details', component: CarDetailsComponent, resolve: { cardata: CarDetailsResolve, favorisData: CarFavorisResolve } },
      { path: 'engagements', component: EngagementsComponent, resolve: { cmsdata: EngagementsResolve } },
      // { path: 'prime-conversion', component: NosOffresComponent, resolve: { cmsOffreData: NosOffresResolve } },
      { path: 'error', component: ErrorComponent },
      { path: 'bad-request', component: BadRequestComponent },
      { path: 'mentionslegales', component: InfoLegalComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'changepassword/:id', component: ChangePasswordComponent },
      { path: 'user-authorise', component: UserAuthoriseComponent },
      { path: 'user-profile', component: UserProfileComponent },
      { path: 'account-confirm', component: AccountConfirmComponent },
      { path: 'account-activate/:id', component: AccountActivateComponent },
      { path: 'favorites', component: FavoritesComponent, resolve: { favoritesData: CarFavoritesData } },
      { path: '**', component: NotFoundComponent }
    ]
  }
];

@NgModule({

  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
