<div class="container">
    <div class="auto-container-error" >
        <div class="error-number">
              <p > 400</p>
         </div> 
          <div class="image-error">
                    <img src="assets/images/background/Renault_clio_error.png"  alt="Renault_clio_error">                
                    <div ><strong>OUPS ! bad-request.</strong>
                    </div>
                <br/>
                <br/>
            <button (click)="revenir()" class="btn-number btn-warning-number">REVENIR</button> 
           </div>                                     
    </div>
</div>
