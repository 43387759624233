import { Injectable, NgZone } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Car } from '../models/car';
import { CarList } from '../models/car-list';
import { ENV } from "../core/env.config";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'apikey': ENV.API_KEY
  })
}

const httpOptionsAuth = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  })
}
const httpOptions1 = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ToprenaultService {

  public detailsUrl: string = ENV.BASE_API_URL;
  public searchUrl: string = ENV.BASE_API_URL + "?locale=fr-FR&channel=main";
  public homeUrl: string = ENV.AGGREGATION_HOME;
  public url_api2 = ENV.API2_URL;
  private carsCount: BehaviorSubject<any> = new BehaviorSubject(0);
  public carsCountNext$ = this.carsCount.asObservable();
  private favorisCount: BehaviorSubject<any> = new BehaviorSubject(0);
  public favorisCountNext$ = this.favorisCount.asObservable();
  public pageSize = 9;
  public isUserConnected: BehaviorSubject<any> = new BehaviorSubject(false);
  public isUserConnectedNext$ = this.isUserConnected.asObservable();
  public userObj = new BehaviorSubject({});
  public userObjNext$ = this.userObj.asObservable();
  public selectedFilters = new BehaviorSubject([]);
  public selectedFilters$ = this.selectedFilters.asObservable();
  public filterTerm = new BehaviorSubject('');
  public filterTerm$ = this.filterTerm.asObservable();
  public colorFilters = new BehaviorSubject([]);
  public colorFilters$ = this.colorFilters.asObservable();

  public isLoading = false;
  public isLoadingFilters = new BehaviorSubject(this.isLoading);
  public currentIsLoading$ = this.isLoadingFilters.asObservable();

  public carFilters = {} as any;
  public paramCarFilters = new BehaviorSubject(this.carFilters);
  public currentParamCarFilters = this.paramCarFilters.asObservable();


  public carViewFilters = {} as any;
  public paramCarViewFilters = new BehaviorSubject(this.carViewFilters);
  public currentParamCarViewFilters = this.paramCarViewFilters.asObservable();


  // result:any;

  constructor(private http: HttpClient, private ngZone: NgZone, public router: Router) { }
  setPageSize(num) {
    this.pageSize = num;
  }

  getpageSize() {
    return this.pageSize;
  }

  setFavorisCount(num) {
    this.favorisCount.next(num);
  }

  setCarsCount(num) {
    this.carsCount.next(num);
  }

  setSelectedFilters(filter) {
    this.selectedFilters.next(filter);
  }

  setFilterTerm(term) {
    this.filterTerm.next(term);
  }

  setColorFilters(colors) {
    this.colorFilters.next(colors);
  }

  changeParamCarViewFilters(param) {
    this.isLoadingFilters.next(param);
  }


  changeIsLoading(param) {
    this.isLoadingFilters.next(param);
  }
  changeCurrentParamCarFilters(param) {
    this.paramCarFilters.next(param);
  }
  changeUserConnection(val) {
    this.isUserConnected.next(val);
  }

  setUser(user) {
    this.userObj.next(user);
  }


  getcarsList(): Observable<CarList[]> {

    let url;
    let sort = "&sort=-topRenaultAd.topRenaultLabelFlag,%2DtopRenaultAd.adPublicationDate";
    return this.http.get<any>(url + `&pageSize=${this.pageSize}`, httpOptions);
  };

  getCar(id): Observable<any> {
    console.log('http Options', this.detailsUrl + "/" + id);
    return this.http.get<any>(this.detailsUrl + "/" + id, httpOptions);
  };


  getfilteredList(param): Observable<Car[]> {

    let sort = sessionStorage.getItem('searchParamSort');
    if (sort == undefined || sort == null) {
      sort = "&sort=-topRenaultAd.topRenaultLabelFlag,%2DtopRenaultAd.adPublicationDate";
    }
    if (param == undefined || param == "undefined" || param == "null" || param == null) {
      param = "";
    } if (param == 'q=') {
      param = "&" + param;      
    }else{
      param = "&" + param.replaceAll(/[+]/g,'%2B');
    }
      
    return this.http.get<any>(this.searchUrl + param + ENV.AGGREGATION + `&pageSize=${this.pageSize}` + sort, httpOptions);
  };
  getListHome(): Observable<CarList[]> {

    return this.http.get<any>(this.searchUrl + this.homeUrl, httpOptions);
  };

  getCodeDep(): Observable<any> {
    return this.http.get<any>(ENV.URL_CODE_DEP);
  };

  sendNotificationCenter(param): Observable<any> {

    const httpOptionsAuthNotificationCenter = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(this.url_api2, param, httpOptionsAuthNotificationCenter);
  };



  //create user

  createUser(userObj) {
    let url = `${ENV.BASE_API_URI}/user`;
    return this.http.post(url, userObj, httpOptions1);
  }

  userLogin(userObj: Object) {
    let url = `${ENV.BASE_API_URI}/user_login`;
    return this.http.post<Object>(url, userObj, httpOptions1);
  }

  doesEmailExist(email: string): Observable<boolean> {
    let url = `${ENV.BASE_API_URI}/check_email_exist`;
    let content: any = {};
    content.email = email;
    return this.http.post<boolean>(url, content, httpOptions1);

  }

  updatePersonalInfo(userObj): Observable<object> {
    let url = `${ENV.BASE_API_URI}/update_personal_info`;
    return this.http.post(url, userObj, httpOptions1);
  };

  updateCommunicationInfo(userObj): Observable<object> {
    let url = `${ENV.BASE_API_URI}/update_communication_info`;
    return this.http.post(url, userObj, httpOptions1);
  }

  updateMarketingInfo(userObj): Observable<object> {
    let url = `${ENV.BASE_API_URI}/update_marketingoptions_info`;
    return this.http.post(url, userObj, httpOptions1);
  }

  updatePassword(passObj): Observable<object> {
    let url = `${ENV.BASE_API_URI}/update_password`;
    return this.http.post(url, passObj, httpOptions1);
  }

  deleteUser(userObj): Observable<object> {
    let url = `${ENV.BASE_API_URI}/delete_user`;
    return this.http.post(url, userObj, httpOptions1);
  }

  passwordSendEmail(emailObj): Observable<object> {
    let url = `${ENV.BASE_API_URI}/reset_password_send_email`;
    return this.http.post(url, emailObj, httpOptions1);
  }
  getUserEmail(id) {
    let url = `${ENV.BASE_API_URI}/get_user_email/${id}`;
    return this.http.get(url, httpOptions1);

  }
  changePassword(userObj) {
    let url = `${ENV.BASE_API_URI}/change_password`;
    return this.http.post(url, userObj, httpOptions1);
  }


  addToFavorites(favoriteObj) {
    let url = `${ENV.BASE_API_URI}/add_to_favorites`;
    return this.http.post(url, favoriteObj, httpOptions1);
  }

  removeFromFavorites(favoriteObj) {
    let url = `${ENV.BASE_API_URI}/remove_from_favorites`;
    return this.http.post(url, favoriteObj, httpOptions1);
  }

  checkIsFavorite(obj) {
    let url = `${ENV.BASE_API_URI}/check_is_favorite`;
    return this.http.post(url, obj, httpOptions1);
  }
  getAllFavorites(obj) {
    let url = `${ENV.BASE_API_URI}/get_all_favorites/${JSON.stringify(obj.favortitesList)}`;
    // return this.http.post(url, obj, httpOptions1);
    // let headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/json');
    // let params = new HttpParams();
    // params.append("obj", obj)
    return this.http.get(url, httpOptions1)
  }

  activateAccount(id) {
    let url = `${ENV.BASE_API_URI}/get_user_after_activation/${id}`;
    return this.http.post(url, httpOptions1);
  }

  resendChangePasswordEmail(email) {
    let url = `${ENV.BASE_API_URI}/resend_changepassword_email/${email}`;
    return this.http.post(url, httpOptions1);

  }
  resendAccountActivationEmail(obj) {
    let url = `${ENV.BASE_API_URI}/resend_account_activation_email`;
    return this.http.post(url, obj, httpOptions1);
  }


  checkFavoritesCarsList(carIds) {
    let url = `${ENV.BASE_API_URI}/check_favorites_cars_list`;
    return this.http.post(url, carIds, httpOptions1);

  }


  handleError(route: ActivatedRouteSnapshot, errorResponse: HttpErrorResponse) {
    switch (errorResponse.status) {
      case 400: {
        this.router.navigate(['/bad-request']);
        return of(null);
      }
      case 404: {
        this.router.navigate(['/not-found']);
        return of(null);
      }
      default: {
        this.router.navigate(['/error']);
        return of(null);
      }
    }
  }
}
