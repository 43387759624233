import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CockpitCmsService } from '../services/cockpitCms.service';
import { ToprenaultService } from '../services/toprenault.service';

@Injectable({
    providedIn: 'root'
})
export class EngagementsResolve implements Resolve<any> {
    constructor(private cockpit:CockpitCmsService,private toprenaultService: ToprenaultService) { }
    resolve(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> {
        return this.cockpit.getEngagementTabs().catch(errorResponse =>  this.toprenaultService.handleError(next, errorResponse));;
     }
}