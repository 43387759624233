import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ENV } from "../core/env.config";
import { of } from 'rxjs';
const API_URL = ENV.COCKPIT_URL;
const API_TOKEN = ENV.COCKPIT_TOKEN;
@Injectable({
  providedIn: 'root'
})
export class CockpitCmsService {
  constructor(private http: HttpClient) { }

  gethome() {
    return this.get('/api/collections/get/Home?token=' + API_TOKEN);
  }
  getImageLink(link) {
    return this.http.get(link);
  }

  public async getEngagementTabs() {

    let tab1 = await this.get('/api/collections/get/tab1?token=' + API_TOKEN).toPromise() as any[];
    let tab2 = await this.get('/api/collections/get/tab2?token=' + API_TOKEN).toPromise() as any[];
    let tab3 = await this.get('/api/collections/get/tab3?token=' + API_TOKEN).toPromise() as any[];
    let tabs = [];
    tabs['tab_1'] = tab1['entries'];
    tabs['tab_2'] = tab2['entries'];
    tabs['tab_3'] = tab3['entries'];
    return of(tabs);
  }

  public getOffresTabs() {
    return this.get('/api/collections/get/offres?token=' + API_TOKEN)
  }
  public getVignetteHomePage() {
    return this.get('/api/collections/get/homePage?token=' + API_TOKEN);
  }

  // public getVignetteListVehicules() {
  //   return this.get('/api/collections/get/listeVehicules?token=' + API_TOKEN);
  // }
  private get<T>(
    url: string
  ) { return this.http.get<T>(`${API_URL}${url}`); }
}


