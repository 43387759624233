const _isLocalhost = window.location.port.indexOf("4200") > -1;
const _isDev = window.location.origin.indexOf("DEV_URL") > -1;
const _isQa = window.location.origin.indexOf("QA_URL") > -1;

const _isOpe = () => {
  if (
    window.location.origin.indexOf("toprenault.com") > -1 ||
    window.location.origin.indexOf("toprenault.fr") > -1
  ) {
    return true;
  } else {
    return false;
  }
};


const cms_url_suffixe = "/api";

const getHost = () => {
  if (_isLocalhost) {
    return "https://trt-api.gke.ope.gcp.renault.com";
  } else if (_isDev) {
    return cms_url_suffixe;
  } else if (_isQa) {
    return cms_url_suffixe;
  } else if (_isOpe) {
    return cms_url_suffixe;
  }
};

const getHostApi2 = () => {
  if (_isLocalhost) {
    return "http://localhost:8080/api/notifcenter";
  } else {
    return "/notif/";
  }
};

const getHostApi = () => {
  if (_isLocalhost) {
    return "http://localhost:8080/api";
  } else {
    return "/api2";
  }
};

const getUrlApi = () => {
  return "https://api-wired-prod-1-euw1.wrd-aws.com/commerce/v1/products/top-renault-vehicles"
};


const getCockpitApikey = () => {
    return "27acd3918e9876557ddc3f7ffef395";
};

const getApiKey = () => {
    return "4ov905fUDTq60AxdYDdWQN5YmXAL4v5I";
};

export const ENV = {
  COCKPIT_URL: getHost(),
  COCKPIT_TOKEN: getCockpitApikey(),
  AGGREGATION:
    "&aggregations=colorMarketing.hexaCode,prices.discountedPriceWithTaxes%5Bmin%5D,prices.discountedPriceWithTaxes%5Bmax%5D,topRenaultAd.mileage%5Bmin%5D,topRenaultAd.mileage%5Bmax%5D,model.label.raw,brand.label.raw,energy.label.raw,transmission.label.raw,version.label.raw,topRenaultAd.department&aggregationSize=10000",
  AGGREGATION_HOME:
    "&aggregations=colorMarketing.hexaCode,prices.discountedPriceWithTaxes%5Bmin%5D,prices.discountedPriceWithTaxes%5Bmax%5D,topRenaultAd.mileage%5Bmin%5D,topRenaultAd.mileage%5Bmax%5D,topRenaultAd.department&aggregationsize=100&pageSize=8&sort=-topRenaultAd.topRenaultLabelFlag,%2DtopRenaultAd.adPublicationDate",
  BASE_API_URL: getUrlApi(),
  BASE_API_URI: getHostApi(),
  API_KEY: getApiKey(),
  API2_URL: getHostApi2(),
  URL_CODE_DEP: "https://geo.api.gouv.fr/departements",
};
