
<div class="auto-container" style="max-width:1198px !important;">
    <div class="bg-showcase">
      
        <div class="row justify-content-center align-self-center">
            <div id="main-wrapper" class="col-md-5 col-sm-5 col-xs-5 col-lg-5 card mt-5 rounded-0">
            <div class="container mt-5" *ngIf='!isFormSubmitted'>
                <h3 class="heading text-center">Mot de passe oublié ?</h3>
                <form class="text-center" [formGroup]="emailForm" >
                    <div class="form-group m-3">
                        <input type="email" name="email" formControlName="email" required
                            [ngClass]="{ 'error':(fields_login_form.email.touched && fields_login_form.email.invalid) || (notValidEmail && !fields_login_form.email.dirty) ,'success':fields_login_form.email.touched && fields_login_form.email.valid }"
                            required autocomplete="nope" />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label for="email">Email <sup>*</sup></label>
                        <div class="text-left error-container">
                            <div *ngIf="fields_login_form.email.errors">
                                <p class="text-left mb-0">
                                    <span *ngIf="fields_login_form.email.touched && fields_login_form.email.errors?.required"
                                        class="error-msg">Email
                                        obligatoire</span>
                                    <span
                                        *ngIf="(fields_login_form.email.touched && fields_login_form.email.invalid) || (fields_login_form.email.touched && fields_login_form.email.errors?.pattern) "
                                        class="error-msg">
                                        Entrer un email valide
                                    </span>
                                    <span
                                        *ngIf="notValidEmail && !fields_login_form.email.dirty"
                                        class="error-msg">
                                        {{errorMsg}}
                                    </span>
                                </p>
            
                            </div>
                        </div>
                    </div>
                    <div class="text-center captcha-container" style="margin-left: 5em;margin-top: 3em;">
                        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()"
                            (load)="handleLoad()" (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="size"
                            [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha">
                        </ngx-recaptcha2>
                    </div>
            
                    <div class="mt-5 mb-3">
                        <button id="password-reset-btn" (click)="resetPassword()" [disabled]="emailForm.invalid"> <span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span> Récupérer votre mot de passe</button>
                    </div>
                    <div class="pass-forgot-container">
                        <a (click)="gotToForgotPass()" class="login-page-link">Non c'est bon, je m'en souviens ?</a>
                    </div>
                </form>
            </div>
            <div *ngIf='isFormSubmitted' class="m-2 p-3 text-center" style="margin:auto 0;">
                <div class="w-100 text-center mt-3 mb-3">
                    <img src="../../assets/images/sent.png" alt="email sent" width="100" height="100" srcset="">
                </div>
                <div class="reinit-text pt-3 pb-3 border-bottom">
                    Réinitialisez votre mot de passe depuis le lien qui vous a été envoyé par email à l'adresse <strong>{{emailAddress}}</strong>
                </div>
                <div class="email-sent-text pt-4 pb-3">
                    Vous n'avez pas reçu d'email ? <u class="resend-link" (click)="resendEmail(emailAddress)">Renvoyez le moi <span *ngIf="isLoading"> <i class="fas fa-circle-notch fa-spin mr-1"></i></span></u> 
                </div>
               
            </div>
        </div>
    </div>
</div>