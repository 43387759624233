<section class="auto-container" id="legals-wrapper" >
  <h3> <strong> D’ACCES ET D’UTILISATION DU SITE<a href="http://www.toprenault.com"> « TOPRENAULT.COM » </a> </strong></h3>
  <div id="premblue">
      <h4> <strong>Préambule :</strong> </h4>
      <p>Ce site <a href="http://www.toprenault.com">www.toprenault.com</a> (ci-après dénommé le « SITE ») édité par Renault s.a.s. (ci-après « RENAULT »), est accessible à
        <a href="http://www.toprenault.com">https://www.toprenault.com</a></p>
    <p>L’accès et l’utilisation du SITE sont soumis au respect des conditions d'accès et d'utilisation détaillées ci-dessous ("CAU ") et de l'ensemble des lois et règlements applicables.
         L’accès et l’utilisation du SITE implique pour l’UTILISATEUR l’acceptation sans réserve des CAU, qui prévalent sur tout autre accord que l’UTILISATEUR pourrait avoir par ailleurs avec RENAULT, ses filiales ou les membres de son réseau.</p>
  </div>
      <!-- article-1-->
  <div class="article">
      <h4><strong>Article 1 : Publication et hébergement</strong></h4>
      <h5><strong>Ce SITE est édité par :</strong> </h5>
      <address>
            RENAULT s.a.s<br>
            Société par Actions Simplifiée au capital de 533.941.113 euros<br>
            122-122 bis avenue du Général Leclerc – 92100 Boulogne-Billancourt<br>
            RCS Nanterre 780 129 987<br>
            Tél: 0810 40 50 60<br>
            <h5>Directeur de la publication :<span>Alexandre Moulin</span></h5>
      </address>
     <h5><strong>Ce SITE est hébergé par : </strong> </h5>
     <p>Google Cloud Platform (GCP)</p>

  </div>
      <!-- article-2-->
  <div class="article">
      <h4><strong>Article 2 : Objet des CAU et notion d’UTILISATEUR</strong></h4>
      <p>Les présentes CAU ont pour objet de définir les conditions générales, juridiques et techniques régissant l’accès et l’utilisation par tout UTILISATEUR du SITE </p>
      <p>On entend par « UTILISATEUR » toute personne physique située en France  amenée à consulter LE SITE.</p>
      <p>L’UTILISATEUR s’engage à respecter les dispositions des CAU, l’ensemble des lois et réglementations en vigueur applicables.</p>
  </div>
    <!-- article-3-->
  <div class="article">
        <h4><strong>Article 3 : Modalités d’Accès au SITE</strong></h4>
        <p>Le site est en libre accès.</p>

  </div>
    <!-- article-4-->
  <div class="article">
        <h4><strong>Article 4 : Propriété Intellectuelle </strong></h4>
        <p>Le présent Site constitue une œuvre dont Renault est l'auteur au sens des articles L. 111.1 et suivants du Code de la Propriété Intellectuelle.</p>
        <p>Les photographies, textes, slogans, dessins, images, séquences animées sonores ou non ainsi que toutes œuvres intégrées dans le Site sont la propriété de RENAULT ou de tiers ayant cédé leurs droits à RENAULT ou de tiers ayant autorisés RENAULT à les utiliser</p>
        <p>Les modèles de véhicules représentés sur le SITE sont protégés au titre des droits d'auteur et des articles L.511.1 et suivants du Code de la Propriété Intellectuelle relatifs à la protection des modèles déposés</p>
        <p>Les reproductions, sur un support papier ou informatique, dudit SITE et des œuvres et modèles de véhicules qui y sont reproduits sont autorisées sous réserve qu'elles soient strictement réservées à un usage personnel excluant tout usage à des fins publicitaires et/ou commerciales et/ou d'information et qu'elles soient conformes aux dispositions de l'article L. 122-5 du Code de la Propriété Intellectuelle</p>
        <p>A l'exception des dispositions ci-dessus, toute reproduction, représentation, utilisation ou modification, par quelque procédé que ce soit et sur quelque support que ce soit, de tout ou partie du SITE, de tout ou partie des différentes œuvres et modèles de véhicules qui le composent, sans avoir obtenu l'autorisation préalable de RENAULT est strictement interdite et constitue un délit de contrefaçon puni de trois ans d'emprisonnement et de 300.000 € d'amende.</p>
        <p>En particulier, sans que toutefois cette liste ne soit exhaustive, sauf à avoir obtenu l’autorisation préalable et expresse de RENAULT, l’UTILISATEUR n’est pas autorisé, à des fins commerciales et/ou promotionnelles, à :</p>
        <ul>
            <li>photocopier la reproduction du SITE et/ou du contenu et ainsi obtenir plus d’un exemplaire ;</li>
            <li>stocker le SITE et/ou le contenu sur un disque ou clé USB (à moins que votre ordinateur ou votre serveur garde des copies pour un temps limité ou stocke le SITE et/ou le contenu uniquement aux fins de représentation sur écran et recherche) ;</li>
            <li>distribuer le SITE et/ou le contenu ;</li>
            <li>retirer, effacer ou cacher les notices légales concernant vos droits de reproduction présentes sur le SITE et/ou le contenu ;</li>
            <li>reproduire le SITE et/ou le contenu sur un autre site ;</li>
            <li>créer des liens vers une autre page que la page d’accueil ou la première page apparaissant à l’adresse <a href="https://www.renaultsport.com/">renaultsport.com </a> créer un cadre autour des pages pour cacher leur identité et provenance.</li>
        </ul>
        <p>Si l’UTILISATEUR souhaite reproduire, modifier et/ou représenter, par quelque moyen que ce soit, sur quelque support que ce soit et sous quelque forme que ce soit, le SITE et/ou le contenu à titre commercial et/ou promotionnel, il doit adresser sa demande d’autorisation à l’adresse suivante :</p>
        <address>
                RENAULT s.a.s<br>
                Direction Marketing France<br>
                Service DIGITAL CRM PRM<br>
                API : EQV ARC 2 68<br>
                92359 Le Plessis Robinson cedex<br>
         </address>
         <p>En cas d’autorisation de reproduction, modification et/ou représentation du SITE et/ou du contenu à titre commercial et/ou promotionnel, l’UTILISATEUR doit obligatoirement faire figurer sur la(les) modification(s) ou reproduction(s) la(les) source(s), le(s) nom(s) des auteurs et/ou titulaires de droits.</p>
         <h5><strong>Droits des marques </strong> </h5>
         <p>L'appellation RENAULT, le logo (losange) RENAULT, le nom des véhicules de la gamme RENAULT et des produits et services qui y sont associés, les slogans et les logos accompagnant ces marques sont, sauf indication particulière, des marques déposées par RENAULT ou d’autres sociétés du groupe.</p>
         <p>D'autres marques sont également citées ; elles sont en principe identifiées par une majuscule. Elles sont utilisées par RENAULT soit avec l'autorisation de leur titulaire, soit comme simple indication de produits ou services proposés par RENAULT.</p>
         <p>La reproduction, l'imitation, l'utilisation ou l'apposition de ces marques sans avoir obtenu l'autorisation préalable de RENAULT ou de leurs titulaires respectifs constitue un délit de contrefaçon puni de quatre ans d'emprisonnement et de 400.000 € d'amende.</p>
         <p>Sous réserve des dispositions des CAU, l'UTILISATEUR ne doit ni copier, ni modifier, ni reproduire, partiellement ou en totalité, sous quelque forme que ce soit les informations et les éléments apparaissant sur le SITE, ni intervenir sur le SITE, ni agir d'une manière contrevenant aux droits de propriété intellectuelle de RENAULT et des tiers ayant autorisé RENAULT à les utiliser. L'utilisation d'un droit de propriété intellectuelle de RENAULT ou d'un tiers ayant autorisé RENAULT à l'utiliser, non expressément autorisée par les CAU est strictement interdite.</p>
  </div>
  <!-- article-5 -->
  <div class="article">
        <h4><strong>Article 5 : Liens hypertextes </strong></h4>
        <p>Les liens hypertextes établis en direction d'autres sites ne sauraient engager la responsabilité de RENAULT, notamment s'agissant du contenu de ces sites.</p>
        <p>RENAULT n'est pas responsable des liens hypertextes pointant vers le présent SITE et interdit à toute personne de mettre en place un tel lien sans son autorisation expresse et préalable.</p>
  </div>
  <!-- article-6-->
  <div class="article">
        <h4><strong>Article 6 : Sécurité</strong></h4>
        <h5><strong>L’UTILISATEUR s’engage : </strong> </h5>
        <ul>
            <li>à ne procéder à aucune manipulation susceptible d’entraîner des pannes informatiques, fonctionnelles ou de tout ordre affectant le fonctionnement du SITE ou de tout serveur ou service accessible par l’intermédiaire du SITE ;</li>
            <li>à ne pas introduire de virus, code malveillant ou toute autre technologie nuisible au SITE ou aux prestations qui y sont proposées ;</li>
            <li>à ne pas utiliser de dispositifs ou de logiciels de toutes sortes qui auraient pour conséquence de perturber le bon fonctionnement du SITE.</li>
        </ul>
        <p>L’UTILISATEUR s’engage, notamment, à ne mettre à disposition, dans l’espace constitué par le SITE, que des informations, données, documents,
             fichiers fiables, inoffensifs et non susceptibles d’affecter le fonctionnement du SITE ou de tout serveur ou service accessible par l’intermédiaire
             du SITE. Il est interdit à l’utilisateur de supprimer ou de modifier des données contenues sur le SITE qui n’auraient pas été publiées par lui-même,
             ou d’y introduire frauduleusement des données ou même d’opérer une altération du fonctionnement du SITE.
         </p>
         <p>Il est interdit à l’utilisateur d’accéder ou de se maintenir, frauduleusement, dans tout ou partie du SITE. L’UTILISATEUR s’interdit d’utiliser une méthode d’accès autre que l’interface mise à disposition RENAULT. En cas de découverte d’une telle méthode ou si l’utilisateur entre dans un espace réservé, sans droit, par inadvertance, celui-ci s’engage à en informer sans délai Renault par courrier à l’adresse suivante afin que celle-ci puisse prendre les mesures nécessaires :</p>
         <address>
                RENAULT s.a.s<br>
                Direction Marketing France<br>
                Service DIGITAL CRM PRM<br>
                API : EQV ARC 2 68<br>
                92359 Le Plessis Robinson cedex<br>
         </address>
         <p>Tout accès à un espace interdit sera considéré comme un accès frauduleux au sens des dispositions du Code pénal. L’utilisateur s’engage à considérer que toutes les données dont il aura eu connaissance à l’occasion d’un tel accès à un espace non autorisé sont des données confidentielles et s’engage, en conséquence, à ne pas les divulguer.</p>
         <p>L’utilisateur s’interdit notamment de réaliser toute opération visant à saturer une page, les opérations de rebond ou toute opération ayant pour conséquence d’entraver ou de fausser le fonctionnement du SITE.</p>
         <p>RENAULT s’engage à mettre en œuvre tous les moyens nécessaires pour assurer la sécurité des informations que l’UTILISATEUR met à disposition dans l’espace constitué par le SITE. Néanmoins, RENAULT ne peut garantir l’absence d’interception de messages transmis par voie électronique.  Ainsi, l’utilisateur accepte les caractéristiques et limites de l’internet. Il a conscience que les données circulant sur l’internet ne sont pas nécessairement protégées, notamment contre les détournements éventuels. En cas d’utilisation de ses propres moyens, l’utilisateur prend les mesures appropriées pour assurer la sécurité de ses propres données et/ou logiciels de la contamination par des éventuels virus sur le réseau internet.</p>
  </div>
  <!-- article-7-->
  <div class="article">
        <h4><strong>Article 7 : Durée</strong></h4>
      <p>Le SITE est mis à disposition de l’UTILISATEUR pour une durée indéterminée et sera fermé à un moment librement décidé par RENAULT.</p>
      <p>A tout moment, RENAULT se réserve le droit de limiter ou suspendre temporairement, durablement ou définitivement le SITE. RENAULT ne peut en aucun cas assumer une quelconque responsabilité à cet égard vis-à-vis des UTILISATEURS*.* En cas de suspension définitive, l’Utilisateur s’engage à détruire sans délai toute copie du Site et/ou de son contenu</p>
      <p>La résiliation des CAU est sans préjudice de toute disposition devant rester en vigueur malgré cette résiliation (ex. : Confidentialité et propriété intellectuelle).</p>
  </div>
   <!-- article-8-->
   <div class="article">
        <h4><strong>Article 8 : Responsabilité</strong></h4>
       <p>Les informations et services accessibles via le SITE sont fournis en l'état. Des erreurs ou omissions peuvent survenir. RENAULT ne donne aucune garantie, explicite ou implicite, et n'assume aucune responsabilité relative à l'utilisation de ces informations, des services ou du SITE.</p>
       <p>L'UTILISATEUR reconnaît qu'il est seul responsable de l'utilisation du SITE, des informations et des services accessibles via le SITE et il reconnaît que ni RENAULT, ni aucune de ses filiales, ni leurs fournisseurs, ne pourront être tenues responsables des dommages directs ou indirects, et notamment préjudice matériel, préjudice immatériel, perte de données ou de programme, préjudice financier, résultant de l'accès ou de l'utilisation du SITE ou de tous sites qui lui sont liés  ou du fait, notamment, de l'interruption, de la suspension, de la modification ou de l'abandon du SITE ou d'un de ses éléments. Le contenu du SITE est présenté sans aucune garantie de quelque nature que ce soit, ce que l’Utilisateur reconnait expressément.</p>
       <p>Renault, ses filiales et ses fournisseurs ne pourront être tenus responsables  de toute perte ou dommage survenant en cas de manquement à ces obligations.</p>

   </div>
    <!-- article-9-->
    <div class="article">
        <h4><strong>Article 9 : Dispositions diverses</strong></h4>
        <p>Absence de renonciation - Le fait, pour l'une des parties, de ne pas se prévaloir d'une clause des présentes CAU ne saurait être interprété comme une renonciation temporaire ou définitive au bénéfice de la disposition en cause qui restera en vigueur.</p>
        <p>Nullité d’une clause - Si une ou plusieurs stipulations des présentes CAU est déclarée non valide, nulle ou non écrite en application de la réglementation en vigueur, d’une évolution ou à la suite d'une décision définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et portée, les parties s’engageant dans les plus brefs délais à mettre en place une disposition de  remplacement valide et d’une portée équivalente la plus proche possible de l’esprit des présentes</p>
        <p>Force Majeure - De façon expresse sont considérés comme cas de force majeure, outre ceux habituellement retenus par la jurisprudence des tribunaux français, les intempéries, les restrictions et modifications gouvernementales et légales, les pannes d’ordinateur et blocage des télécommunications, la défaillance des serveurs RENAULT et tout autre cas indépendant de la volonté expresse de RENAULT/</p>
    </div>
      <!-- article-10-->
      <div class="article">
            <h4><strong> Article 10 : Langue </strong></h4>
             <p>Les CAU sont disponibles en français et pourront être traduites dans d’autres langues. En cas de contradictions, ambiguïtés ou incompatibilités entre la version française et la version
                 traduite des CAU, seule la version française fera foi.</p>
      </div>

       <!-- article-11-->
     <div class="article">
        <h4><strong> Article 11 : Loi applicable et règlement des différends </strong></h4>
        <p>La relation entre RENAULT et l’UTILISATEUR est soumise au droit français, sauf dispositions d’ordre public contraires.</p>
        <p>Les parties s'efforceront de régler à l'amiable tous les différends qui naîtraient entre elles concernant la validité, l'interprétation et/ou l'exécution des présentes CAU. En cas d’échec, seuls les Tribunaux de Paris (France) seront compétents pour connaître du litige, sauf dispositions d’ordre public contraires prévoyant impérativement la compétence d’une autre juridiction.</p>
    </div>
     <!-- article-12-->
   <div class="article">
        <h4><strong> Article 12 : Modification et mise à jour des CAU </strong></h4>
        <p>RENAULT se réserve le droit de modifier et mettre à jour, à tout moment et sans préavis, l’accès au SITE ainsi que les CAU. Ces modifications et mises à jour s’imposent à l’UTILISATEUR dès leur publication sur le SITE. L’UTILISATEUR doit par conséquent consulter les CAU en vigueur lors de chaque connexion au SITE.</p>
   </div>

</section>
