<div class="main-wrapper">
    <section class="page-title" style="background-image:url(assets/images/background/home.jpg);padding: 50px 0px 250px 0px;">
        <div class="auto-container">
            <h1 class="text-uppercase">Top Renault</h1>
            <div class="page-subtitle">toutes les Occasions du personnel Renault</div>
        </div>
    </section>

    <section class="car-search-form">
        <div class="auto-container">
            <div class="inner-section">
                <!--Product Info Tabs-->
                <div class="car-search-tab">
                    <!--Tabs Box-->
                    <div class="prod-tabs tabs-box">
                        <!--Tabs Container-->
                        <div class="tabs-content">
                            <div class="content">
                                <!-- Car Search Form -->
                                <div>
                                    <form method="post">
                                        <div style="display: flex; flex-direction: row;">
                                            <!--Form Group-->
                                            <div style="border-left: 1px solid #ebebeb;" class="input-widget form-group"
                                                style="margin-bottom:0 !important;flex: 2 !important;padding:0 !important;">
                                                <input type="search" id="fulltext-input"
                                                    placeholder="Ex: Megane, Scenic, Clio...." name="search"
                                                    (input)="code=$event.target.value" autocomplete="off">
                                            </div>
                                            <!--Form Group-->
                                            <div id="widget_prix" class="input-widget filter_pop_widget form-group"
                                                style="margin-bottom:0 !important;flex: 1 !important;padding:0 !important;padding:0 !important;">
                                                <a routerLink="" [popover]="prixTemplate" [outsideClick]="true"
                                                    placement="bottom" (onShown)="onshownPop('widget_prix')"
                                                    (onHidden)="onHiddenPop('widget_prix')"
                                                    containerClass="customPoprange">
                                                    {{prix}}
                                                    <span style="padding-top: 2px !important;">
                                                        <i id="widget_prix_chev" class="icofont-thin-down"
                                                            style="font-weight: bold !important;font-size: 1.3em !important;  opacity: 0.58 !important;"></i>
                                                    </span>
                                                </a>
                                            </div>
                                            <!--Form Group-->
                                            <div id="widget_km" class="input-widget form-group"
                                                style="margin-bottom:0 !important;flex: 1 !important;padding:0 !important;">
                                                <a routerLink="" [popover]="kmTemplate"
                                                    containerClass="customPopKMrange" [outsideClick]="true"
                                                    placement="bottom" (onShown)="onshownPop('widget_km')"
                                                    (onHidden)="onHiddenPop('widget_km')">
                                                    {{km}}
                                                    <span style="padding-top: 2px !important;">
                                                        <i id="widget_km_chev" class="icofont-thin-down"
                                                            style="font-weight: bold !important;font-size: 1.3em !important;opacity: 0.58 !important;"></i>
                                                    </span>
                                                </a>
                                            </div>
                                            <!--Form Group-->
                                            <div id="widget_loc" class="input-widget form-group"
                                                style="margin-bottom:0 !important; flex: 1 !important; padding:0 !important;">
                                                <a routerLink="" [popover]="locationTemplate"
                                                    containerClass="customPopGeorange" [outsideClick]="true"
                                                    placement="bottom" (onShown)="onshownPop('widget_loc')"
                                                    (onHidden)="onHiddenPop('widget_loc')">
                                                    {{localisation}}
                                                    <span style="padding-top: 2px !important;">
                                                        <i id="widget_loc_chev" class="icofont-thin-down"
                                                            style="font-weight: bold !important;font-size: 1.3em !important;opacity: 0.58 !important;"></i>
                                                    </span>
                                                </a>
                                            </div>
                                            <div style="padding: 0px;" class="form-group"
                                                style="margin-bottom:0 !important; padding:0!important;padding:0 !important;">
                                                <button name="searchbutton" type="submit" id="searchbutton"
                                                    (click)="reseach()">
                                                    <i style="font-size: 23px;" class="icofont-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--End Product Info Tabs-->
            </div>
        </div>
        <footer class="main-footer mt-5">
            <div class="auto-container">
                <!--Widgets Section-->
                <div class="widgets-section"
                    style="text-align: center;background-color: #ffff !important;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;">
                    <div class="row clearfix">

                        <!--Services Block-->
                        <div class="footer-column col-md-6 col-sm-6 col-xs-12">
                            <div class="footer-widget" style="color: #000;">
                                <div style="margin-bottom: 10px !important;" class="icon-box">
                                    <img src="../../assets/images/background/business-saving.svg" alt="business-saving">
                                </div>
                                <h4>
                                    <a style="color: #000 !important;font-size: 1.3rem;font-family:Read-Regular;font-size:15px;"
                                        routerLink="/engagements">Votre Véhicule à Prix d'amis</a>
                                </h4>
                                <div class="text"
                                    style="color: #000;font-weight: normal; margin-top: 10px !important; margin-right: 20px; font-size: 13px;font-family:RenaultLife-Regular;">
                                    La plus belle occasion de s'offrir un
                                    <br>véhicule Renault ou Dacia
                                </div>
                            </div>
                        </div>

                        <!--Services Block-->
                        <!-- <div class="footer-column col-md-4 col-sm-6 col-xs-12">
                            <div class="footer-widget" style="color: #000;">
                                <div style="margin-bottom: 10px !important;" class="icon-box">
                                <img src="../../assets/images/background/business-ic-special.svg" alt="business-saving">
                                        
                                </div>
                                <h4>
                                    <a (click)="goToPage1('ltr')"
                                        style="color: #000 !important;font-size: 1.3rem;font-family:Read-Regular;font-size:15px;cursor:pointer;">Label
                                        Top Renault</a>
                                </h4>
                                <div class="widget-content"
                                    style="color: #000;font-weight: normal;margin-top: 10px !important; margin-right: 20px; font-size: 13px;font-family:RenaultLife-Regular;">
                                    Un nouveau label conçu pour vous
                                </div>
                            </div>
                        </div> -->
                        <!--Services Block-->
                        <div class="footer-column col-md-6 col-sm-6 col-xs-12">
                            <div class="footer-widget" style="color: #000;">
                                <div style="margin-bottom: 10px !important;" class="icon-box">
                                    <img src="../../assets/images/background/edit-sheet-warrantly.svg" alt="business-saving">
                                </div>
                                <h4>
                                    <a (click)="goToPage1('contrats')"
                                        style="color: #000!important;font-size: 1.3rem;font-family:Read-Regular; font-size:15px;cursor:pointer;">Votre
                                        Contrat de Service
                                    </a>
                                </h4>
                                <div class="widget-content"
                                    style="color: #000;font-weight: normal; margin-top: 10px !important; margin-right: 20px; font-size:13px;font-family:RenaultLife-Regular;">
                                    Votre contrat d'extension de garantie
                                    <br> Une solution simple et pratique pour rouler tranquille
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="square" routerLink='engagements'>
            </div>
            <div class="plus-container"  routerLink='engagements'>
                <i class="fas fa-plus plus-icon"></i>
            </div>
          
            <!--Footer Bottom-->
            <!-- <div id="diamond">

           </div> -->
        </footer>
    </section>
    <!-- <section class="page-title-lower">
        <div class="auto-container">
            <h2>Découvrez les véhicules de collaborateurs</h2>
        </div>
    </section> -->

    <!--Popular Cars Section-->
    <section class="popular-cars-section">
        <div class="auto-container" id="desktop-list-view">

            <div class="row">
                <!--Car Block-->
                <div *ngFor="let car of carList ;let i = index;" class="car-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div *ngIf="car" class="inner-box" (click)="selectCar(car.productId)">
                        <div class="img-container layerd" *ngIf="car.assets!==undefined && car.assets.length > 0 
                            && car.assets[0].renditions!==undefined">
                            <img class="img-reponsive" src="{{car.assets[0].renditions[0]?.url}}" alt="voiture" />
                            <div class="tgas-container">
                            <div class="tags">
                                <div class="tag">
                                    <div *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined
                                                                    && car.prices[0].discounts.length > 0">
                                        - {{car.prices[0].discounts[0]?.discountAmount | numberPipe | number:'':'fr'}} €

                                    </div>
                                </div>
                                <div class="tag">
                                    <!-- <div [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                                        <span>Label top Renault </span>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        </div>

                        <!-- <div class="image layerd">
                            <a>
                                <img src="{{car.assets[0].renditions[0].url}}" alt="car-image" /> </a>
                        </div> -->
                        <div class="wrapper">
                            <div class="brand-wrapper">
                                <p class="model">
                                    {{car.model?.label | titlecase }}
                                </p>
                            </div>


                            <div class="year-kms">
                                <!-- location -->
                                <span class="icon flaticon-location-pin"></span>
                                <span class="department"> {{car.topRenaultAd?.department}}</span>
                                <!-- Kilométrage -->
                                <span> <span class="unePipe"> | </span> {{car.topRenaultAd?.mileage}} km
                                    <span class="unePipe"> | </span> </span>
                                <!-- Date disponibilite -->
                                <span class="date-dispo-future" *ngIf="today < car.topRenaultAd?.availabilityDate">
                                    <span class="dispo-text">Dispo</span>{{car.topRenaultAd?.availabilityDate |
                                    date:'dd-MM-yyyy' }}
                                </span>
                                <span class="date-dispo-passed" *ngIf="today > car.topRenaultAd?.availabilityDate">
                                    Disponible
                                </span>
                                <!-- <span>{{car.topRenaultAd?.availabilityDate | date:'dd-MM-yyyy'}}</span> -->
                            </div>
                            <!-- price -->
                            <div class="price" *ngIf="car?.prices!==undefined && car?.prices?.length > 0">
                                <p>
                                    {{car?.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} €
                                </p>
                            </div>
                            <!-- price negotiable text -->
                            <div class="price-negotialble" [hidden]="!car.topRenaultAd?.negotiablePrice">
                                <span>( prix à débattre )</span>
                            </div>
                                <div class="desktop-favoris">
                                    <div *ngIf='car.isNotInFavorites' class="favoris mr-1" (click)='addToFavorite(car.productId,$event)'> <span
                                            class="bg-white p-2 favoris-inner pl-3 pr-3">ajouter aux favoris <i class="far fa-star"></i></span> </div>
                                    <div *ngIf='car.isInFavorites' class="retirer-favoris mr-1" (click)='removeFromFavorite(car.productId,$event)'>
                                        <span class="bg-white p-2 retirer-favoris-inner pl-3 pr-3">
                                            retirer des favoris <i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                        </div>
                        <!-- <div class="remise" *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined
                        && car.prices[0].discounts.length > 0">
                            - {{car.prices[0].discounts[0]?.discountAmount  | numberPipe | number:'':'fr'}} €
                            
                        </div> -->

                        <!-- <div class="remise">
                            - {{  car.prices[0].discountPriceWT | numberPipe }}€
                        </div> -->
                    </div>
                    <!-- <div *ngIf="car && i==vignette.position && vignette.description!=null"
                        class="inner-box offer-special">
                        <div class="wrapper">
                            <h3 class="offer-title" [class.large-font]="car.description?.length>100"
                                [class.medium-font]="car.description?.length>50 && car.description?.length<100">
                                {{car.titre}}</h3>
                            <h5 class="offer-description" [class.large-font]="car.description?.length>100"
                                [class.medium-font]="car.description?.length>50 && car.description?.length<100">
                                {{car?.description}}</h5>
                            <button class="offer-btn" (click)="goToPage()">{{car?.bouton}}</button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div id="mobile-list-view">
            <section class="offer-section">
                <div class="auto-container">
                    <div class="three-item-carousel owl-carousel owl-theme">
                        <!--Offer Block-->
                        <div *ngFor="let car of carList;let i = index; "
                            class="car-block col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <div *ngIf="car" class="inner-box"
                                style="text-align:center !important">

                                <div class="img-container layerd" *ngIf="car.assets!==undefined && car.assets!==null
                                && car.assets[0].renditions!==undefined && car.assets[0].renditions.length > 0"
                                    (click)="selectCar(car.productId)">
                                    <img src="{{car.assets[0].renditions[0]?.url}}" class="img-reponsive"
                                        alt="car-image" />
                                <div class="tgas-container">
                                    <div class="tags">
                                        <div class="tag">
                                            <div *ngIf="car.prices!==undefined && car.prices.length > 0 && car.prices[0].discounts!==undefined
                                                                                                    && car.prices[0].discounts.length > 0">
                                                - {{car.prices[0].discounts[0]?.discountAmount | number:'':'fr'}} €
                                
                                            </div>
                                        </div>
                                        <div class="tag">
                                            <!-- <div [hidden]="!car.topRenaultAd?.topRenaultLabelFlag">
                                                <span>Label top Renault </span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div class="wrapper">
                                    <div class="brand-wrapper">
                                        <p class="model">
                                            {{car.model?.label | titlecase }}
                                        </p>
                                    </div>
                                    <div class="price" *ngIf="car.prices!==undefined && car.prices.length > 0">
                                        <p>
                                            {{car.prices[0]?.discountedPriceWithTaxes | numberPipe | number:'':'fr'}} €
                                        </p>
                                    </div>
                                    <div class="price-negotialble" [hidden]="!car.topRenaultAd?.negotiablePrice">
                                        <span>( prix à débattre )</span>
                                    </div>
                                    <div class="year-kms">
                                        <!-- location -->
                                        <span class="icon flaticon-location-pin"></span>
                                        <span class="department"> {{car.topRenaultAd?.department}}</span>
                                        <!-- Kilométrage -->
                                        <span> <span class="unePipe"> | </span> {{car.topRenaultAd?.mileage}} km
                                            <span class="unePipe"> | </span> </span>
                                        <!-- Date disponibilite -->
                                        <span class="date-dispo-future"
                                            *ngIf="today < car.topRenaultAd?.availabilityDate">
                                            <span class="dispo-text">Dispo</span>{{car.topRenaultAd?.availabilityDate |
                                            date:'dd-MM-yyyy' }}
                                        </span>
                                        <span class="date-dispo-passed"
                                            *ngIf="today > car.topRenaultAd?.availabilityDate">
                                            Disponible
                                        </span>
                                    </div>

                                </div>

                                <!-- <div class="remise" *ngIf="car.prices !==undefined && car.prices.length > 0 && car.prices[0].discounts !== undefined
                                && car.prices[0].discounts.length > 0">
                                    - {{car.prices[0].discounts[0]?.discountAmount | numberPipe| number:'':'fr'}} €
                                </div>                                 -->
                            </div>
                            <!-- <div *ngIf="car && i==vignette.position && vignette.description!=null"
                                class="inner-box offer-special">
                                <div class="wrapper">
                                    <h3 class="offer-title">{{car.titre}}</h3>
                                    <h5 class="offer-description">{{car.description}}</h5>
                                    <button class="offer-btn" (click)="goToPage()">{{car.bouton}}</button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div style="text-align: center;text-transform:uppercase;margin:20px 0;">
            <a class="voir-btn" data-title="Voir tous les Véhicules" routerLink="/liste">Voir tous les
                Véhicules</a>
        </div>
    </section>

    <section class="services-section-two mb-5">
        <div class="auto-container">
            <div class="flex-container">
                <!--widget Column-->
                <div class="top-widget" style="border-left: transparent;border-right: transparent;">
                    <div>
                        <div id="home-top-video" class="home-top-video-class">
                            <video id="video-element">
                                <source src="" type="video/mp4" /> Votre navigateur n'est pas compatible avec ce
                                navigateur.
                            </video>
                        </div>
                        <div class="mobile-view-container">
                            <!-- (click)="playOrPauseVideo()" -->
                            <!-- <i id="play-Pause-btn"  class="fa fa-caret-right">
                                            </i> -->
                            <div id="text" class="text-lowercase">
                                <h4>{{dataEditos?.comment4?.valeur}}</h4>
                            </div>
                        </div>
                    </div>
                    <!-- <span id="video-icon" class="icofont-thin-right"></span> -->
                </div>
                <div class="top-widget" style="border-left: transparent;">
                    <div class="content">
                        <p class="count">{{dataEditos?.comment1?.label}}</p>
                        <p class="info">{{dataEditos?.comment1?.valeur}}

                        </p>
                    </div>
                    <span class="icofont-thin-right" style="color: #efdf00;"></span>
                </div>
                <!--widget Column-->
                <div class="top-widget" style="border-left: transparent;border-right: transparent;">
                    <div class="content">
                        <p class="count">{{dataEditos?.comment2?.label}}</p>
                        <p class="info">{{dataEditos?.comment2?.valeur}}</p>
                    </div>
                    <span class="icofont-thin-right" style="color: #efdf00;"></span>
                </div>
                <!--widget Column-->
                <div class="top-widget">
                    <div class="content">
                        <p class="count">{{dataEditos?.comment3?.label}}</p>
                        <p class="info">{{dataEditos?.comment3?.valeur}}</p>
                    </div>

                </div>

            </div>
        </div>
    </section>

    <!-- <section class="services-section-two">
        <div class="auto-container">
            <div class="row clearfix">
            
                <div class="image-column col-md-6 col-sm-12 col-xs-12">
                    <div class="image">
                        <img src="{{getimageLink(nosOffreData?.image1)}}" alt="" />
                    </div>
                </div>
            
                <div class="content-column col-md-6 col-sm-12 col-xs-12">
                    <div class="inner-column">
                        <div class="sec-title">
                            <h2>{{nosOffreData?.Subtitle1}}</h2>
                        </div>
                        <div class="text">
                            {{nosOffreData?.Description1?.label}}
                        </div>
                        <div class="text">
                            {{nosOffreData?.Description1?.valeur}}
                        </div>

                        <div  class="know-more-btn-container">
                            <a class="voir-btn know-more-btn" routerLink="/prime-conversion">En savoir plus</a>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </section> -->

    <ng-template #prixTemplate>
        <div
            style="margin-left:10px;min-height: 50px;font-weight: 300; color: black;font-family: 'Read-Regular';padding: 20px !important;">
            Prix</div>
        <div style="margin:0px 30px 40px 30px;">
            <ng5-slider [(value)]="minPrix" [(highValue)]="maxPrix" [options]="optionsPrix"
                (userChangeEnd)="onUserChangeEnd($event,'prix')"></ng5-slider>
        </div>
        <div class="row row-fit ">
            <div class="col-sm-12 pop-controls">
                <button class="annuler" (click)="annuler('prix','customPoprange','widget_prix')">Annuler</button>
                <span class="filter_results"></span>
                <button class="appliquer" (click)="hidePop('customPoprange','widget_prix')">Appliquer</button>
            </div>
        </div>
    </ng-template>

    <ng-template #kmTemplate>
        <div
            style=" margin-left:10px; min-height: 50px;font-weight: 300; color: black;font-family: 'Read-Medium';padding: 20px !important;">
            Kilométrage</div>
        <div style="margin:0px 30px 40px 30px;">
            <ng5-slider [(value)]="minKm" [(highValue)]="maxKm" [options]="optionsKm"
                (userChangeEnd)="onUserChangeEnd($event,'km')"></ng5-slider>
        </div>
        <div class="row row-fit">
            <div class="col-sm-12 pop-controls">
                <button class="annuler" (click)="annuler('km','customPopKMrange','widget_km')">Annuler</button>
                <span class="filter_results"></span>
                <button class="appliquer" (click)="hidePop('customPopKMrange','widget_km')">Appliquer</button>
            </div>
        </div>
    </ng-template>

    <ng-template #locationTemplate>
        <div class="row row-fit" style="padding: 20px !important;">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div style="min-height: 40px;font-weight: 300; color: black">Où recherchez-vous votre véhicule ?</div>
                <div style="margin-bottom: 40px;">
                    <div>
                        <form class="location" style="margin:auto;">
                            <!-- <input google-place id="autocomplete_search" name="autocomplete_search" type="text"
                                class="form-control" placeholder="Recherche ..." /> -->

                            <div class="form-group" style="display: flex !important;margin-bottom: 0 !important;">
                                <!-- <input type="text" style="font-size: 14px !important;" (keydown.enter)="$event.preventDefault()" placeholder="Code postal.." autocorrect="off" autocapitalize="off" spellcheck="off" type="text" id="search">-->
                                <input class="form-control" [maxlength]="3" spellcheck="false" type="text" id="search"
                                    style="width:226px !important;font-size:14px !important; color: #1f2532;"
                                    autocorrect="off" autocomplete="off" spellcheck="off" name="code-department"
                                    [(ngModel)]="codeDep" placeholder="code département" typeaheadOptionField="code"
                                    [typeaheadItemTemplate]="customItemTemplate" [typeahead]="codeDepLists"
                                    (typeaheadNoResults)="typeaheadNoResults($event,'code')"
                                    [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="4">
                                <ng-template #customItemTemplate let-model="item">
                                    <span style="font-size: 12px;font-family: 'Read-Regular';">
                                        <span class="icon flaticon-location-pin"
                                            style="font-size: 12px !important;font-weight: 700 !important;color: #777777c4 !important;padding-right:10px"
                                            aria-hidden="true"></span>
                                        <strong>{{model.code}}</strong> - {{model.nom}}</span>
                                </ng-template>
                                <button type="submit">
                                    <i style="font-size: 18px;" class="icofont-search"
                                        (click)="hidePop('customPopGeorange','widget_loc')"></i>
                                </button>
                            </div>
                            <small
                                style="margin-left: 8px;width:226px !important;color: #E91630;font-size: 14px;font-family:Read-Regular"
                                *ngIf="noDepartment"> *
                                Code département non disponible</small>
                        </form>
                    </div>
                </div>
            </div>

            <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div style="min-height: 40px;font-weight: bold; color: black">Dans un périmètre de</div>
                <div style="margin-bottom: 40px;">
                    <ng5-slider [(value)]="valPerimetre" [options]="optionsPerimetre"
                        (userChangeEnd)="onUserChangeEnd($event,'localisation')"></ng5-slider>
                </div>    
            </div> -->
        </div>
        <div class="row row-fit" style="margin-top:10px;">
            <div class="col-sm-12 pop-controls">
                <button class="annuler"
                    (click)="annuler('localisation','customPopGeorange','widget_loc')">Annuler</button>
                <span class="filter_results"></span>
                <button class="appliquer" (click)="hidePop('customPopGeorange','widget_loc')">Appliquer</button>
            </div>
        </div>

    </ng-template>
</div>